import { useEffect, useState  } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setPageName, setBack2Page } from "../../../../store/pagination/paginationSlice";
import { Button, DatePicker, Input, message, Modal, notification, Select, Spin, Table, Tabs, Upload } from "antd";
import { CheckCircleTwoTone, CloudUploadOutlined, DownloadOutlined, InboxOutlined, PlusOutlined, RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import axios from "axios";
import styles from './ScholarshipUploadForm.module.css';
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import type { UploadFile } from 'antd/es/upload/interface';
import ApiConfig from "../../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { getScholarship90ById } from "../../../../services/ScholarShip90Service";
import { Scholarship90Data, Scholarship90StatusLog } from "../../../../models/Scholarship90Model";
import { convertDBDate2Show, convertDBTime2Show } from "../../../../helper/DateTimeHelper";
import { SessionUserState } from "../../../../store/authen/authenSlice";
import { PermissionName } from "../../../../models/Login";
import { ApiResponse } from "../../../../models/MasterModel";
import { getAuthPermission } from "../../../../services/AuthenService";
import { changeScholar90Status } from "../../../../services/ScholarShip90Service";
import { LanguageState } from "../../../../store/language/languageSlice";
import { labelLang } from "../../../../helper/HTMLHelper";

const ScholarshipUploadForm = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams<string>();
    const { Option } = Select;
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [markerList, setMarkerList] = useState<Array<any>>([]);
    const [photoDate, setPhotoDate] = useState<any>(moment());
    const [refName, setRefName] = useState<string>("");
    const [dataList, setDataList] = useState<any>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [scholarData, setScholarData] = useState<Scholarship90Data>();
    const [confirmDialog, setConfirmDialog] = useState<any>(null);
    // const [loadedImageList, setLoadedImageList] = useState<TransactionMainImage[]>([]);
    
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [currentPermission, setCurrentPermission] = useState<any>(null);
    const [canApprove, setCanApprove] = useState<boolean>(false);
    const [canReject, setCanReject] = useState<boolean>(false);
    const [downloadFileList, setDownloadFileList] = useState<any[]>([]);
    const [isDraftStatus, setIsDraftStatus] = useState<boolean>(false);
    const [isUploadDisable, setUploadDisable] = useState<boolean>(false);
    const [hasChangeStatusPerm, setHasChangeStatusPerm] = useState<boolean>();
    // const [loadedImageList, setLoadedImageList] = useState<TransactionMainImage[]>([]);
    const [showTable, setShowTable] = useState<boolean>(true);

    const [selectedStatus, setSelectedStatus] = useState<string | null>();
    const [attachMessage, setAttachMessage] = useState<string>("");
    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);

    const mockData = [
        {
            name: "คณนาถ เจริญไชย",
            role: "นิสิต",
            process_date: "06/06/2022 13:10:27",
            old_status: "",
            new_status: "DRAFT",
            message: "",
        },
        {
            name: "คณนาถ เจริญไชย",
            role: "นิสิต",
            process_date: "06/06/2022 13:10:27",
            old_status: "DRAFT",
            new_status: "PENDING",
            message: "",
        },
        // {
        //     name: "ภูดินันท์ สิงห์คำฟู",
        //     role: "เจ้าหน้าที่",
        //     process_date: "07/06/2022 09:10:57",
        //     old_status: "PENDING",
        //     new_status: "APPROVE",
        // },
    ]
    const columns = [
        {
            title: labelLang("ผู้ทำรายการ", "Operator", currentLang),
            key: 'name',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: Scholarship90StatusLog) => {return `${data.process_user_firstname} ${data.process_user_lastname}`},
        },
        {
            title: labelLang("บทบาท", "Role", currentLang),
            dataIndex: 'role_name',
            key: 'role_name',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang("ข้อความแนบ", "Message", currentLang),
            dataIndex: 'attach_message',
            key: 'attach_message',
            // sorter: (a: ProcessTransaction, b: ProcessTransaction) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang("วันที่ดำเนินการ", "Log Date", currentLang),
            // dataIndex: 'process_date',
            key: 'log_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: Scholarship90StatusLog) => {return `${convertDBDate2Show(data.log_date)} ${convertDBTime2Show(data.log_time)} `},
        },
        {
            title: labelLang("สถานะเดิม", "Previous Status", currentLang),
            dataIndex: 'old_status',
            key: 'old_status',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang("สถานะปัจจุบัน", "Current Status", currentLang),
            dataIndex: 'current_status',
            key: 'current_status',
            // sorter: (a: ProcessTransaction, b: ProcessTransaction) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
    ]
    const fileColumns = [
        {
            title: labelLang("ชื่อเอกสาร", "Doc Name", currentLang),
            dataIndex: 'filename',
            key: 'filename',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang("ชนิดเอกสาร", "Doc Type", currentLang),
            dataIndex: 'file_type',
            key: 'file_type',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang("วันที่อัปโหลด", "Send Date", currentLang),
            // dataIndex: 'upload_date',
            key: 'upload_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: any) => {return `${convertDBDate2Show(data.upload_date)} ${convertDBTime2Show(data.upload_time)}`},
        },
        {
            title: 'Download',
            key: 'download',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: any) => {return (
                <Button icon={<DownloadOutlined />} onClick={() => {adminDownload(data)}}>Download</Button>
            )},
        }
    ]
    const adminDownload = (data: any) => {
        const callback = () => {
            window.open(`${ApiConfig.backendUrl}/downloadFile.php?path=${data.url}`, "_blank");
            setConfirmDialog(null);
        }
        let newConfirmDialog = (
            <Modal
                visible={true}
                title={"ดาวน์โหลดเอกสาร"}
                onCancel={() => {setConfirmDialog(null);}}
                onOk={callback}
                okText={"Download"}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                        ต้องการดาวน์โหลดไฟล์ "{data.filename}" หรือไม่?
                    </div>
                </div>
            </Modal>
        )
        setConfirmDialog(newConfirmDialog);
    }    
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])
    useEffect(() => {
        dispatch(setPageName({th: "แบบฟอร์มอัปโหลดทุน 90 ปี", en: "Scholarship Form"}));
        dispatch(setBack2Page("/management/scholarship/list"));
        // setDataList(mockData)
        // setFileList(mockFileList)
        loadScholar();
    }, [])

    // useEffect(() => {
    //     if(fileList){
    //         setUploadDisable(fileList.length > 0)
    //     }
    // }, [fileList])

    useEffect(() => {
        if(scholarData){
            if(scholarData.status_logs){
                setDataList(scholarData.status_logs);
            }
            if(scholarData.attach_files){
                let files: any[] = scholarData.attach_files.map((value) => {
                    return {
                        uid: value.id,
                        name: value.ori_file_name,
                        status: 'done',
                        url: `${ApiConfig.uploadedAssets}${value.file_path}`,
                        shortUrl: value.file_path,
                    }
                });
                console.log("setFileList: ", files);
                setFileList(files);
                let attachFileList: any[] = scholarData.attach_files.map((value) => {
                    let filename = value.file_path.replace(/^.*[\\\/]/, '')
                    return {
                        filename: filename, 
                        upload_date: value.upload_date, 
                        upload_time: value.upload_time, 
                        url: value.file_path,
                        file_type: 'เอกสารส่งตีพิมพ์',
                    }
                })
                setDownloadFileList(attachFileList);
            }
            setIsDraftStatus(scholarData.status == "DRAFT");
            if(authen && authen.sessionUser){
                setIsOwner(scholarData.create_user_id == authen.sessionUser.currentUser.id);
                loadUserPermission();
            }
            // setDownloadFileList(fileTableList);
        }
    }, [scholarData])

    useEffect(() => {
        setShowTable(!isOwner || !isDraftStatus);
    }, [isOwner, isDraftStatus])

    const loadScholar = async () => {
        let resData = await getScholarship90ById(id);
        if(resData.status){
            setScholarData(resData.data);
        }
    }
    const loadUserPermission = async () => {
        // console.log("loadUserPermission");
        if(authen && authen.sessionUser){
            // console.log("loadUserPermission auth");
            let permNameList = [PermissionName.SCHOLAR90_MANAGE_STATUS];
            let userId = authen.sessionUser.currentUser.id;
            let resData: ApiResponse = await getAuthPermission(userId, permNameList);
            if(resData.status){
                let data: {[key: string]: string} = resData.data;
                // console.log("Permission Data: ", data);
                setCurrentPermission(data);
                let hasApprovePerm = data[PermissionName.SCHOLAR90_MANAGE_STATUS] != undefined && data[PermissionName.SCHOLAR90_MANAGE_STATUS] != null;
                setHasChangeStatusPerm(hasApprovePerm);
            }
        }

    }
    
    const uploadButton = (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const inputTextChange = (e: any, setState: any) => {
        setState(e.target.value);
    }
    
    const onRemoveClicked = (e: any, myFileList: any, setMyFileList: any, isMultiple: any) => {
        const callback = async () => {
            let fmData = new FormData
            const config = {
              headers: { "content-type": "multipart/form-data" },
            };
            fmData.append("uid", e.uid);
            fmData.append("scholarship_id", id ? id : "0");
            try {
                const res = await axios.post(
                    `${ApiConfig.backendUrl}/scholar90Controller/removeScholarshipFile.php`,
                    fmData,
                    config
                );
                console.log("server res: ", res);
                if(res.status == 200){
                    // let data = res.data.data;
                    if(isMultiple){
                        const index = myFileList.indexOf(e);
                        const newFileList = myFileList.slice();
                        newFileList.splice(index, 1);
                        setMyFileList(newFileList);
                    }else{
                        setMyFileList([]);
                    }
                }
            } catch (err) {
                console.log("Eroor: ", err);
                const error = new Error("Some error");
            }
            setConfirmDialog(null);
        }
        let newConfirmDialog = (
            <Modal
                visible={true}
                title={labelLang("ยืนยันการลบเอกสาร", "Confirm Delete?", currentLang)}
                onCancel={() => {setConfirmDialog(null)}}
                onOk={callback}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                        
                        {labelLang(`ยืนยันการลบไฟล์ "${e.name}" หรือไม่?`, `Are you delete "${e.name}"?`, currentLang)}
                    </div>
                </div>
            </Modal>
        )
        setConfirmDialog(newConfirmDialog);
        // setRemovedCallback(callback);
    }
    let multipleTmp: any[] = [];
    const props: UploadProps = {
        // name: 'file',
        fileList: fileList,
        multiple: true,
        disabled: !isDraftStatus || !isOwner,
        beforeUpload: async (file: File) => {
            console.log("beforeUpload: ", file);
            let isPdf = file.type == "application/pdf";
            let isImage = (file.type == "image/png" || file.type == "image/jpeg");
            if(!isPdf && !isImage){
                notification.error({message: labelLang("การอัพโหลดไฟล์ไม่สำเร็จ", "Upload Fail", currentLang), description: "การอัพโหลดจำกัดเฉพาะไฟล์ PDF หรือรูปภาพ (.jpeg, .jpg, .png) เท่านั้น"})
            }
            console.log("can upload: ", isPdf || isImage, ", fileType: ", file.type);
            return isPdf || isImage;
        },
        customRequest: async (options: any) => {
            const { onSuccess, onError, file, onProgress } = options;
            const fmData = new FormData();
            console.log("custom upload: ", file);
            const config = {
              headers: { "content-type": "multipart/form-data" },
              onUploadProgress: (event: any) => {
                const percent = Math.floor((event.loaded / event.total) * 100);
                // setProgress(percent);
                if (percent === 100) {
                //   setTimeout(() => setProgress(0), 1000);
                }
                onProgress({ percent: (event.loaded / event.total) * 100 });
              }
            };
            fmData.append("file", file);
            fmData.append("scholarship_id", id ? id : "0");
            try {
                const res = await axios.post(
                    `${ApiConfig.backendUrl}/scholar90Controller/uploadScholarFile.php`,
                    fmData,
                    config
                );
                console.log("server res: ", res);
                if(res.status == 200){
                    let data = res.data.data;
                    let newFile = createDefaultFile("Upload", data.filename, data.uploadFilePath);
                    onSuccess(file);
                    multipleTmp.push(newFile);
                    setFileList([...fileList, ...multipleTmp]);
                    // if(isMultiple){
                    //     multipleTmp.push(newFile);
                    //     setMyFileList([...myFileList, ...multipleTmp]);
                    // }else{
                    //     setMyFileList([newFile])
                    // }
                }
            } catch (err) {
                console.log("Eroor: ", err);
                const error = new Error("Some error");
                onError({ err });
            }
        },
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            let newFileList = [info.file];
            setFileList(newFileList);
            notification.success({message: labelLang("อัพโหลดไฟล์สำเร็จ", "Upload Success", currentLang), description: `${info.file.name}.`});
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onRemove(e) {
            console.log("remove: ", e);
            // return false;
            onRemoveClicked(e, fileList, setFileList, true);
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        //   if(!(myFileList.length == 0 || isMultiple)){
        //     notification.error({message: 'อัพโหลดไม่สำเร็จ', description: "มีไฟล์อัพโหลดอยู่แล้ว"});
        //   }
        },
        onDownload(e) {
            let file: any = e;
            console.log("download: ", e);
            const callback = () => {
                window.open(`${ApiConfig.backendUrl}/thesisController/downloadThesis.php?path=${file.shortUrl}`, "_blank");
                setConfirmDialog(null);
            }
            let newConfirmDialog = (
                <Modal
                    visible={true}
                    title={"ดาวน์โหลดเอกสาร"}
                    onCancel={() => {setConfirmDialog(null)}}
                    onOk={callback}
                    okText={"Download"}
                >
                    <div className={styles['modal-main-container']}>
                        <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                            {labelLang(`ต้องการดาวน์โหลดไฟล์ "${e.name}" หรือไม่?`, `Are you want to download "${e.name}"?`, currentLang)}
                        </div>
                    </div>
                </Modal>
            )
            setConfirmDialog(newConfirmDialog);
        },
        // disabled: isUploadDisable,
        showUploadList: {
            showDownloadIcon: true,
            showRemoveIcon: true,
        },
        style: {minWidth: 500, marginLeft: 2, marginRight: 2, opacity: ((scholarData?.status != "DRAFT") ? 0.8 : 1), borderColor: fileList.length > 0 ? "#71DE04" : ""},
    };
    const getBorderColor = () => {

    }
    const createDefaultFile = (uid: string, filename: string, url: string) => {
        return {
          uid: uid,
          name: filename,
          status: 'done',
          url: `${ApiConfig.uploadedAssets}${url}`,
          shortUrl: `${url}`
        }
    }
    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const sendToApprove = () => {
        const callback = async () => {
            if(authen && authen.sessionUser){
                let approveData = {
                    scholar_id: id,
                    new_status: "PENDING",
                    process_user_id: authen.sessionUser.currentUser.id,
                    attach_message: "ส่งเอกสารเพื่อตรวจสอบ"
                }
                let resData = await changeScholar90Status(approveData);
                if(resData.status){
                    notification.success({message: labelLang("แก้ไขสถานะเอกสารสำเร็จ", "Change status success.", currentLang)});
                    setDataList([])
                    setDownloadFileList([])
                    loadScholar();
                }
            }
            setConfirmDialog(null);
        }
        let newConfirmDialog = (
            <Modal
                visible={true}
                title={labelLang("ส่งเอกสาร", "Send to approve", currentLang)}
                onCancel={() => {setConfirmDialog(null)}}
                onOk={callback}
                okText={"Send to Approve"}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                        {labelLang("ต้องการส่งเอกสารให้เจ้าหน้าที่ตรวจสอบหรือไม่?", "Are you confirm to send to approve?", currentLang)}
                    </div>
                </div>
            </Modal>
        )
        setConfirmDialog(newConfirmDialog);
    }
    const changeStatus = () => {
        setPreviewVisible(true);
    }
    const renderSendDocBtn = () => {
        if(isDraftStatus && isOwner){
            return (
                <div className={styles['input-container']} style={{justifyContent: 'flex-end', margin: 5}}>
                    <Button type={'primary'} onClick={sendToApprove}>{labelLang("ส่งมอบให้เจ้าหน้าที่", "Send to approve", currentLang)}</Button>
                </div>
            )
        }else if(hasChangeStatusPerm && !isDraftStatus){
            return (
                <div className={styles['input-container']} style={{justifyContent: 'flex-end', margin: 5}}>
                    <Button type={'primary'} onClick={changeStatus}>{labelLang("เปลี่ยนสถานะ", "Change Status", currentLang)}</Button>
                </div>
            )
        }
    }

    const renderFileTable = () => {
        if(showTable){
            return (
                <div className={styles['list-container']}>
                    <Table columns={fileColumns} dataSource={downloadFileList} onChange={onChange} pagination={{pageSize: 5}} style={{width: '100%'}}/>
                </div>
            );
        }else{
            return (
                <div className={styles['upload-container']}>
                    <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                        {fileList.length > 0 ? <CheckCircleTwoTone twoToneColor={"#71DE04"} /> : <InboxOutlined />}
                        </p>
                        <p className="ant-upload-text">คลิก หรือลากไฟล์มาวางบริเวณนี้เพื่ออัปโหลดไฟล์</p>
                        <p className="ant-upload-hint">
                            อัพโหลดได้เพียง 1 ไฟล์
                        </p>
                    </Dragger>
                </div>
            )
        }
    }
    const renderStatusOptions = () => {
        let statusOptionList: any[] = [];
        if(scholarData && scholarData.status != "DRAFT"){
            statusOptionList.push(<Option value="DRAFT">DRAFT</Option>)
        }
        if(scholarData && scholarData.status != "APPROVE"){
            statusOptionList.push(<Option value="APPROVE">APPROVE</Option>)
        }
        if(scholarData && scholarData.status != "REJECT"){
            statusOptionList.push(<Option value="REJECT">REJECT</Option>)
        }
        return statusOptionList;
    }
    return (
        <div className={styles['main-container']}>
            <div className={styles['tabs-container']}>
                <div className={styles['input-container']} style={{justifyContent: 'flex-end', paddingRight: 0, marginTop: 5}}>
                    <b>{labelLang("สถานะเอกสาร", "Status", currentLang)}:</b>&nbsp;{scholarData?.status}
                    {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                </div>
                <Tabs className={styles['tabs-component']} tabBarExtraContent={renderSendDocBtn()} type={"card"}>
                    <Tabs.TabPane tab={labelLang("การอัพโหลด", "Upload", currentLang)} key="tb1">
                        {renderFileTable()}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={labelLang("บันทึกทำการ", "Process Log", currentLang)} key="tb2">
                        <div className={styles['list-container']}>
                            <Table columns={columns} dataSource={dataList} onChange={onChange}/>
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </div>
            {/* <div className={styles['list-container']}>
                <div className={styles['input-container']} style={{justifyContent: 'flex-end', margin: 5}}>
                    {renderSendDocBtn()}
                </div>
                <Table columns={columns} dataSource={dataList} onChange={onChange}/>
            </div> */}
            <Modal
                visible={previewVisible}
                title={labelLang("ยืนยันการเปลี่ยนสถานะเอกสาร", "Confirm to change status", currentLang)}
                onCancel={() => {setPreviewVisible(false)}}
                onOk={async () => {
                    if(!selectedStatus || selectedStatus.trim() == ""){
                        notification.error({message: labelLang("กรุณาเลือกสถานะที่ต้องการเปลี่ยน", "Require choose status to change", currentLang)})
                        return;
                    }
                    if(!attachMessage || attachMessage.trim() == ""){
                        notification.error({message: labelLang("กรุณากรอกข้อความแนบ", "Attach message is require", currentLang)})
                        return;
                    }
                    if(authen && authen.sessionUser){
                        let approveData = {
                            scholar_id: id,
                            new_status: selectedStatus,
                            process_user_id: authen.sessionUser.currentUser.id,
                            attach_message: attachMessage
                        }
                        let resData = await changeScholar90Status(approveData);
                        if(resData.status){
                            notification.success({message: labelLang("ส่งเอกสารสำเร็จ", "Send success", currentLang), description: labelLang("ส่งเอกสารถึงเจ้าหน้าที่เพื่อตรวจสอบแล้ว กรุณารอการตอบกลับ", "Send to approve success. Staff will response later.", currentLang)});
                            setDataList([])
                            setDownloadFileList([])
                            loadScholar();
                        }
                    }
                    setPreviewVisible(false)
                }}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']}>
                        <Select
                            showSearch
                            placeholder={labelLang("เลือกสถานะเอกสารที่ต้องการเปลี่ยน", "Select new status", currentLang)}
                            optionFilterProp="children"
                            onChange={(value) => {setSelectedStatus(value)}}
                            value={selectedStatus}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            className={styles['select-half']}
                            style={{marginRight: 4, width: "100%"}}
                        >
                            {renderStatusOptions()}
                        </Select>
                        {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                    </div>
                    <div className={styles['input-container']}>
                        <Input style={{width: 550, marginRight: 5}} className={styles['input-text']} placeholder={labelLang("ข้อความแนบ", "Attach message", currentLang)} value={attachMessage} onChange={(e) => {inputTextChange(e, setAttachMessage)}}></Input>
                        {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                    </div>
                </div>
            </Modal>
            {confirmDialog}
            {/* <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAbSvmJTpz2jImwwovY2AN7xDWZE_teSX0" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                    {renderMarker()}
                </GoogleMapReact>
            </div> */}
        </div>
    )
}

export default ScholarshipUploadForm;