import axios from "axios";
import ApiConfig from "../constants/ApiConfig";
import { DocumentModel, Faculty, FacultyBranch, MasterModuleRef } from "../models/MasterModel";


export const getFaculty = async () => {
    let result: Faculty[] = []
    try{
        let url = `${ApiConfig.backendUrl}/masterDataController/getFaculty.php`
        let res = await axios.get(url);
        if(res.status === 200){
            result = res.data.data;
            return result
        }
        return result;
    }catch(ex){
        // console.log("getFaculty: ", ex);
        return result;
    }
}

export const getFacultyBranch = async () => {
    let result: FacultyBranch[] = []
    try{
        let url = `${ApiConfig.backendUrl}/masterDataController/getFacultyBranch.php`
        let res = await axios.get(url);
        if(res.status === 200){
            result = res.data.data;
            return result
        }
        return result;
    }catch(ex){
        // console.log("getFacultyBranch: ", ex);
        return result;
    }
}

export const getDepartment = async () => {
    let result: FacultyBranch[] = []
    try{
        let url = `${ApiConfig.backendUrl}/masterDataController/getDepartment.php`
        let res = await axios.get(url);
        if(res.status === 200){
            result = res.data.data;
            return result
        }
        return result;
    }catch(ex){
        // console.log("getFacultyBranch: ", ex);
        return result;
    }
}

export const getMajor = async () => {
    let result: FacultyBranch[] = []
    try{
        let url = `${ApiConfig.backendUrl}/masterDataController/getMajor.php`
        let res = await axios.get(url);
        if(res.status === 200){
            result = res.data.data;
            return result
        }
        return result;
    }catch(ex){
        // console.log("getFacultyBranch: ", ex);
        return result;
    }
}


export const getDocumentList = async () => {
    let result: DocumentModel[] = []
    try{
        let url = `${ApiConfig.backendUrl}/masterDataController/getDocumentList.php`
        let res = await axios.get(url);
        if(res.status === 200){
            result = res.data.data;
            return result
        }
        return result;
    }catch(ex){
        // console.log("getFacultyBranch: ", ex);
        return result;
    }
}

export const getModuleList = async () => {
    let result: MasterModuleRef[] = []
    try{
        let url = `${ApiConfig.backendUrl}/masterDataController/getModuleList.php`
        let res = await axios.get(url);
        if(res.status === 200){
            result = res.data.data;
            return result
        }
        return result;
    }catch(ex){
        // console.log("getFacultyBranch: ", ex);
        return result;
    }
}
