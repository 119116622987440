import { Button, Form, Input, notification, Select } from 'antd';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import { GoogleCircleFilled } from '@ant-design/icons';
import styles from './SignUp.module.css'
import { useAppDispatch } from '../../store';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiResponse, Faculty, FacultyBranch } from '../../models/MasterModel';
import { getDepartment, getFaculty, getFacultyBranch, getMajor } from '../../services/MasterService';
import { register } from '../../services/AuthenService';

interface ISignUp{
    student_code: string;
    email: string;
    password: string;
    confirm_password: string;
    firstname: string;
    lastname: string;
    faculty_id: string;
    branch_id: string;
    major_id: string;
    phone_no: string;

}
const SignUp = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [navigatePage, setNavigatePage] = useState<string>();
    const [facultyComboList, setFacultyComboList] = useState<Faculty[]>([]);
    const [oriFacultyBranchComboList, setOriFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [filterFacultyBranchComboList, setFilterFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [oriMajorComboList, setOriMajorComboList] = useState<FacultyBranch[]>([]);
    const [filterMajorComboList, setFilterMajorComboList] = useState<FacultyBranch[]>([]);
    const [selectedMajor, setSelectedMajor] = useState<string>("ALL");

    const { Option } = Select;
    useEffect(() => {
        loadComboData();
        // setNavigatePage("/");
    }, []);

    useEffect(() => {
      if(navigatePage != null && navigatePage.trim() != ""){
        navigate(navigatePage, {replace: true});
      }
    },[navigatePage]);

    const loadComboData = async () => {
        let facultyList = await getFaculty();
        // let facultyBranchList = await getFacultyBranch();
        let departmentList = await getDepartment();
        let majorList = await getMajor();
        setFacultyComboList(facultyList);
        setOriFacultyBranchComboList(departmentList);
        setFilterFacultyBranchComboList([...departmentList]);
        setOriMajorComboList(majorList);
        setFilterMajorComboList([...majorList]);
    }

    const onFinish = async (values: ISignUp) => {
        console.log('Success:', values);
        if(values.confirm_password != values.password){
            alert("การยืนยันรหัสผ่านไม่ตรงกัน");
            return;
        }
        let resData: ApiResponse = await register(values);
        if(resData.status){
            notification.success({message: "สมัครสมาชิกสำเร็จ", description: resData.message})
            setNavigatePage("/frontend/login");
        }else{
            notification.error({message: "สมัครสมาชิกไม่สำเร็จ", description: resData.message})
        }
    };
  
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const onFacultyChange = (value: string) => {
        const facultyId: number = Number(value);
        let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
            return branch.faculty_id == facultyId || branch.faculty_id == -1;
        });
        form.setFieldsValue({branch_id: "" + filterBranch[0].id});
        setFilterFacultyBranchComboList(filterBranch);
        let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
            return branch.department_id == filterBranch[0].id && (facultyId == -1 || facultyId == branch.faculty_id);
        });
        setFilterMajorComboList(filterMajor);
        if(filterMajor.length > 0){
            form.setFieldsValue({major_id: "" + filterMajor[0].id});
        }
    }

    const onFacultyBranchChange = (value: string) => {
        const facultyBranchId: number = Number(value);
        let facultyBranchItem: FacultyBranch | undefined = oriFacultyBranchComboList.find((value) => {return value.id == facultyBranchId});
        let currentFaculty = -1;
        if(form.getFieldValue("faculty_id") != null){
            currentFaculty = Number(form.getFieldValue("faculty_id"));
        }
        if(facultyBranchItem){
            const facultyBranchItemValue = facultyBranchItem;
            if(facultyBranchItem.faculty_id > 0){
                form.setFieldsValue({faculty_id: "" + facultyBranchItem.faculty_id});
            }
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == facultyBranchItemValue.faculty_id || branch.faculty_id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == facultyBranchItemValue.id && (currentFaculty == -1 || currentFaculty == branch.faculty_id);
            });
            setFilterMajorComboList(filterMajor);
            if(filterMajor.length > 0){
                form.setFieldsValue({major_id: "" + filterMajor[0].id});
            }
        }
        console.log("onFacultyBranchChange field value: ", form.getFieldsValue());
        // setFilterFacultyBranchComboList(filterBranch);
    }

    const onMajorChange = (value: string) => {
        // setSelectedMajor(value);
        const majorId: number = Number(value);
        let majorItem: FacultyBranch | undefined = oriMajorComboList.find((value) => {return value.id == majorId});
        if(majorItem){
            const majorItemValue = majorItem;
            form.setFieldsValue({faculty_id: "" + majorItemValue.faculty_id, branch_id: "" + majorItemValue.department_id});
            // setSelectedFaculty("" + majorItemValue.faculty_id);
            // setSelectedFacultyBranch("" + majorItemValue.department_id);
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == majorItemValue.faculty_id || branch.id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == majorItemValue.department_id && branch.faculty_id == majorItemValue.faculty_id;
            });
            setFilterMajorComboList(filterMajor);
        }
    }
    const renderFacultyOption = () => {
        let optionList = [];
        for(let i = 0; i < facultyComboList.length; i++){
            let fac = facultyComboList[i];
            optionList.push(<Option value={`${fac.id}`}>{fac.faculty_name}</Option>);
        }
        return optionList;
    }

    const renderFacultyBranchOption = () => {
        let optionList = [];
        for(let i = 0; i < filterFacultyBranchComboList.length; i++){
            let branch = filterFacultyBranchComboList[i];
            optionList.push(<Option value={`${branch.id}`}>{branch.branch_name}</Option>);
        }
        return optionList;
    }
    const renderMajorOption = () => {
        let optionList = [];
        // optionList.push(<Option value={`ALL`}>ทุกสาขา</Option>);
        for(let i = 0; i < filterMajorComboList.length; i++){
            let maj = filterMajorComboList[i];
            optionList.push(<Option value={`${maj.id}`}>{maj.branch_name}</Option>);
        }
        return optionList;
    }
    const formItemStyle = {marginBottom: 10};
    return (
        <div>
            <div className={styles['login-container']}>
                <div className={styles['component-box']}>
                    <div className={styles['title-bar']}><span>สมัครสมาชิก</span></div>
                    
                    <Form
                        form={form}
                        name="register"
                        // labelCol={{ span: 8 }}
                        // wrapperCol={{ span: 16 }}
                        style={{width: '80%'}}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="student_code"
                            rules={[{ required: true, message: 'กรุณากรอกรหัสนักศึกษา!' }]}
                            style={formItemStyle}
                        >
                            <Input placeholder="รหัสนักศึกษา*" type={"text"} />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'กรุณากรอกอีเมล์!' }, {type: "email", message: 'รูปแบบ Email ไม่ถูกต้อง'}]}
                            style={formItemStyle}
                        >
                            <Input placeholder="Email*" type={"text"}/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน!' }]}
                            style={formItemStyle}
                        >
                            <Input placeholder="Passowrd*" type={"password"} />
                        </Form.Item>
                        <Form.Item
                            name="confirm_password"
                            rules={[{ required: true, message: 'กรุณายืนยันรหัสผ่าน!' }]}
                            style={formItemStyle}
                        >
                            <Input placeholder="Confirm Passowrd*" type={"password"} />
                        </Form.Item>
                        <div className={styles['input-line']}>
                            <Form.Item
                                name="firstname"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อ!' }]}
                                // className={styles['half']} 
                                style={{...formItemStyle, marginRight: 4, width: '100%'}}
                            >
                                <Input placeholder="ชื่อ*" type={"text"} />
                            </Form.Item>
                            <Form.Item
                                name="lastname"
                                rules={[{ required: true, message: 'กรุณากรอกนามสกุล!' }]}
                                style={{...formItemStyle, width: '100%'}}
                            >
                                <Input placeholder="นามสกุล*" type={"text"} />
                            </Form.Item>
                        </div>
                        <div className={styles['input-line']} style={{marginBottom: 0}}>
                            <Form.Item
                                name="faculty_id"
                                rules={[{ required: true, message: 'กรุณาเลือกคณะที่สังกัด!' }]}
                                style={{...formItemStyle, width: '100%'}}
                            >
                                <Select
                                    showSearch
                                    placeholder="เลือกคณะ*"
                                    optionFilterProp="children"
                                    onChange={onFacultyChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                    className={styles['select-full']}
                                >
                                    {renderFacultyOption()}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className={styles['input-line']} style={{marginBottom: 0}}>
                            <Form.Item
                                name="branch_id"
                                rules={[{ required: true, message: 'กรุณาเลือกสาขาวิชาที่สังกัด!' }]}
                                style={{...formItemStyle, width: '50%', marginRight: 4}}
                            >
                                <Select
                                    showSearch
                                    placeholder="เลือกภาควิชา*"
                                    optionFilterProp="children"
                                    className={styles['select-half']}
                                    onChange={onFacultyBranchChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {renderFacultyBranchOption()}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="major_id"
                                rules={[{ required: true, message: 'กรุณาเลือกสาขาวิชาที่สังกัด!' }]}
                                style={{...formItemStyle, width: '50%', marginRight: 4}}
                            >
                                <Select
                                    showSearch
                                    placeholder="เลือกสาขา*"
                                    optionFilterProp="children"
                                    className={styles['select-half']}
                                    onChange={onMajorChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {renderMajorOption()}
                                </Select>
                            </Form.Item>
                        </div>
                        
                        <Form.Item
                            name="phone_no"
                            rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
                            className={styles['half']}
                            style={{...formItemStyle}}
                        >
                            <Input placeholder="เบอร์โทรศัพท์" type={"text"} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                        </Form.Item>
                        <div className={styles['button-container']}>
                            <Form.Item
                                name="submitBtn"
                                // rules={[{ required: true, message: 'Please input your username!' }]}
                                // className={styles['half']}
                                style={{...formItemStyle, }}
                            >
                                <Button type={"primary"} style={{marginRight: 5}} htmlType={"submit"}>สมัครสมาชิก</Button>
                            </Form.Item>
                            <Button type={"default"} style={{marginRight: 5}} onClick={() => {setNavigatePage("/frontend/login")}}>ยกเลิก</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default SignUp;