
import moment, {Moment} from 'moment';
interface Login {
    id: number;
    email: string;
    main_role: number;
    main_role_name: string;
    last_active_time?: Moment;
}

export default Login;

export interface Personal{
    login_id: number;
    student_code: string;
    firstname: string;
    lastname: string;
    phone_no: string;
}

export interface Permission{
    id: number;
    major_code: string;
    minor_code: string;
    permission_name: string;
    permission_description: string;
}

export const PermissionName = {
    VISIT_BACKEND: "VISIT_BACKEND",

    VIEW_USER: "VIEW_USER",
    ADD_USER: "ADD_USER",
    EDIT_USER: "EDIT_USER",
    DELETE_USER: "DELETE_USER",
    GRANT_USER: "GRANT_USER",

    VIEW_ROLE: "VIEW_ROLE",
    ADD_ROLE: "ADD_ROLE",
    EDIT_ROLE: "EDIT_ROLE",
    DELETE_ROLE: "DELETE_ROLE",
    GRANT_PERMISSION_ROLE: "GRANT_PERMISSION_ROLE",

    THESIS_APPROVE: "THESIS_APPROVE",
    THESIS_REJECT: "THESIS_REJECT",
    THESIS_MANAGE_STATUS: "THESIS_MANAGE_STATUS",
    DOWNLOAD_THESIS: "DOWNLOAD_THESIS",
    THESIS_DOWNLOAD: "DOWNLOAD_THESIS",
    THESIS_SEARCH: "THESIS_SEARCH",
    THESIS_SETTING: "THESIS_SETTING",

    SCHOLAR90_MANAGE_STATUS: "SCHOLAR90_MANAGE_STATUS",
    SCHOLAR90_SEARCH: "SCHOLAR90_SEARCH",
    SCHOLAR90_DOWNLOAD: "DOWNLOAD_SCHOLAR90",
    SCHOLAR90_SETTING: "SCHOLAR90_SETTING",

    REQUIRE_DOC_APPROVE: "REQUIRE_DOC_APPROVE",
    REQUIRE_DOC_REJECT: "REQUIRE_DOC_REJECT",
    REQUIRE_DOC_MANAGE_STATUS: "REQUIRE_DOC_MANAGE_STATUS",
    REQUIRE_DOC_DOWNLOAD: "REQUIRE_DOC_DOWNLOAD",
    REQUIRE_DOC_SEARCH: "REQUIRE_DOC_SEARCH",

    ACTIVITY_LOG_VIEW: "ACTIVITY_LOG_VIEW",
    REPORT_VIEW: "REPORT_VIEW"
}

export const UserPermissionNameList: string[] = [
    PermissionName.VIEW_USER, 
    PermissionName.ADD_USER, 
    PermissionName.EDIT_USER, 
    PermissionName.DELETE_USER, 
    PermissionName.GRANT_USER
]
export const RolePermissionNameList: string[] = [
    PermissionName.VIEW_ROLE, 
    PermissionName.ADD_ROLE, 
    PermissionName.EDIT_ROLE, 
    PermissionName.DELETE_ROLE, 
    PermissionName.GRANT_PERMISSION_ROLE
]