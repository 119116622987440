import { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setBack2Page, setPageName } from "../../../store/pagination/paginationSlice";
import { DeleteTwoTone, EditTwoTone, EnvironmentTwoTone, InboxOutlined, PlusSquareTwoTone } from "@ant-design/icons";
import { Button, DatePicker, Input, message, Modal, notification, Select, Space, Table } from "antd";
import styles from './CreateReport.module.css';
import moment, {Moment} from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import type { UploadFile } from 'antd/es/upload/interface';
import axios from "axios";
import ApiConfig from "../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { Line } from '@ant-design/charts';
import { DocumentModel, Faculty, FacultyBranch } from "../../../models/MasterModel";
import { getDepartment, getDocumentList, getFaculty, getFacultyBranch, getMajor } from "../../../services/MasterService";
import { SessionUserState } from "../../../store/authen/authenSlice";

const CreateReport = () => {
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const dispatch = useAppDispatch();
    const { RangePicker } = DatePicker;
    const dateFormat = 'DD/MM/YYYY';
    let navigate = useNavigate();
    let { Option } = Select;
    const reportForm = useRef<HTMLFormElement>(null);

    const [dataList, setDataList] = useState<any>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [previewTitle, setPreviewTitle] = useState<string>('');
    const [photoDate, setPhotoDate] = useState<any>(moment());
    const [refName, setRefName] = useState<string>("");
    const [userId, setUserId] = useState<number>(1);
    const [facultyComboList, setFacultyComboList] = useState<Faculty[]>([]);
    const [oriFacultyBranchComboList, setOriFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [filterFacultyBranchComboList, setFilterFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [searchFromDate, setSearchFromDate] = useState<Moment | null>(null);
    const [searchToDate, setSearchToDate] = useState<Moment | null>(null);
    const [docTypeSelected, setDocTypeSelected] = useState<string>("THESIS");
    const [selectedFaculty, setSelectedFaculty] = useState<string>("ALL");
    const [selectedFacultyBranch, setSelectedFacultyBranch] = useState<string>("ALL");
    const [oriMajorComboList, setOriMajorComboList] = useState<FacultyBranch[]>([]);
    const [filterMajorComboList, setFilterMajorComboList] = useState<FacultyBranch[]>([]);
    const [selectedMajor, setSelectedMajor] = useState<string>("ALL");

    const [selectedDocument, setSelectedDocument] = useState<string>("ALL");
    const [documentComboList, setDocumentComboList] = useState<DocumentModel[]>([]);
    const [schoolYear, setSchoolYear] = useState<string>("");
    
    const columns = [
        {
            title: 'ชื่อห้วข้อ',
            dataIndex: 'process_date',
            key: 'process_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: 'ผู้อัปโหลด',
            dataIndex: 'process_date',
            key: 'process_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: 'คณะ',
            dataIndex: 'process_date',
            key: 'process_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: 'สาขา',
            dataIndex: 'process_date',
            key: 'process_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: 'วันที่สร้าง',
            dataIndex: 'process_date',
            key: 'process_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: 'สถานะปัจจุบัน',
            dataIndex: 'main_image_count',
            key: 'main_image_count',
            // sorter: (a: ProcessTransaction, b: ProcessTransaction) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
          title: 'วันที่แก้ไขล่าสุด',
          dataIndex: 'location_name',
          key: 'location_name',
        },
        {
          title: 'Action',
          key: 'action',
          render: (text: string, record: any) => (
            <Space size="middle">
                <Link to={`/app/map/${record.id}`}><EnvironmentTwoTone /></Link>
                <Link to={`/app/upload/${record.id}`}><EditTwoTone /></Link>
                <a><DeleteTwoTone /></a>
            </Space>
          ),
        },
    ]
    useEffect(() => {
        dispatch(setPageName({th: "ออกรายงาน", en: "Report"}));
        dispatch(setBack2Page(null));
        loadComboData();
    }, [])
    
    useEffect(() => {
        if(authen && authen.sessionUser){
            setUserId(authen.sessionUser.currentUser.id);
        }
    }, [authen])

    const loadComboData = async () => {
        let facultyList = await getFaculty();
        // let facultyBranchList = await getFacultyBranch();
        let departmentList = await getDepartment();
        let majorList = await getMajor();
        let documentList = await getDocumentList();
        setFacultyComboList(facultyList);
        setOriFacultyBranchComboList(departmentList);
        setFilterFacultyBranchComboList([...departmentList]);
        setOriMajorComboList(majorList);
        setFilterMajorComboList([...majorList]);
        setDocumentComboList(documentList);
    }

    const onDocumentChange = (value: string) => {
        setSelectedDocument(value);
    }
    
    const renderRequestDocumentOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกประเภทเอกสาร</Option>);
        for(let i = 0; i < documentComboList.length; i++){
            let fac = documentComboList[i];
            optionList.push(<Option value={`${fac.id}`}>{fac.name}</Option>);
        }
        return optionList;
    }

    const onFacultyChange = (value: string) => {
        console.log("onFacultyChange: ", value);
        setSelectedFaculty(value);
        setSelectedFacultyBranch("ALL");
        setSelectedMajor("ALL");
        if(value == "ALL"){
            setFilterFacultyBranchComboList([...oriFacultyBranchComboList]);
            setFilterMajorComboList([...oriMajorComboList]);
            return;
        }
        const facultyId: number = Number(value);
        let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
            return branch.faculty_id == facultyId || branch.id == -1;
        });
        setFilterFacultyBranchComboList(filterBranch);

        let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
            return branch.faculty_id == facultyId;
        });
        setFilterMajorComboList(filterMajor);
    }

    const onFacultyBranchChange = (value: string) => {
        console.log("onFacultyBranchChange: ", value);
        setSelectedFacultyBranch(value);
        setSelectedMajor("ALL");
        const facultyBranchId: number = Number(value);
        let currentFaculty = -1;
        if(selectedFaculty != "ALL"){
            currentFaculty = Number(selectedFaculty);
        }
        let facultyBranchItem: FacultyBranch | undefined = oriFacultyBranchComboList.find((value) => {return value.id == facultyBranchId});
        if(facultyBranchItem){
            const facultyBranchItemValue = facultyBranchItem;
            if(facultyBranchItemValue.faculty_id > 0){
                setSelectedFaculty("" + facultyBranchItemValue.faculty_id);
            }
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == facultyBranchItemValue.faculty_id || currentFaculty == -1|| branch.id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == facultyBranchItemValue.id && (currentFaculty == -1 || currentFaculty == branch.faculty_id);
            });
            setFilterMajorComboList(filterMajor);
        }
        // setFilterFacultyBranchComboList(filterBranch);
    }

    const onMajorChange = (value: string) => {
        console.log("onMajorChange: ", value);
        setSelectedMajor(value);
        const majorId: number = Number(value);
        let majorItem: FacultyBranch | undefined = oriMajorComboList.find((value) => {return value.id == majorId});
        if(majorItem){
            const majorItemValue = majorItem;
            setSelectedFaculty("" + majorItemValue.faculty_id);
            setSelectedFacultyBranch("" + majorItemValue.department_id);
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == majorItemValue.faculty_id || branch.id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == majorItemValue.department_id && branch.faculty_id == majorItemValue.faculty_id;
            });
            setFilterMajorComboList(filterMajor);
        }
    }

    const data = [
        { year: '1991', value: 3 },
        { year: '1992', value: 4 },
        { year: '1993', value: 3.5 },
        { year: '1994', value: 5 },
        { year: '1995', value: 4.9 },
        { year: '1996', value: 6 },
        { year: '1997', value: 7 },
        { year: '1998', value: 9 },
        { year: '1999', value: 13 },
      ];
    
      const config = {
        data,
        xField: 'year',
        yField: 'value',
        point: {
          size: 5,
          shape: 'diamond',
        },
      };
    
    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const handleCancel = () => {setPreviewVisible(false)};
    const refNameChange = (e: any) => {
        setRefName(e.target.value);
    }
    const openAddModal = () => {
        setPreviewVisible(true);
    }
    
    const renderFacultyOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกคณะ</Option>);
        for(let i = 0; i < facultyComboList.length; i++){
            let fac = facultyComboList[i];
            optionList.push(<Option value={`${fac.id}`}>{fac.faculty_name}</Option>);
        }
        return optionList;
    }

    const renderFacultyBranchOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกภาควิชา</Option>);
        for(let i = 0; i < filterFacultyBranchComboList.length; i++){
            let branch = filterFacultyBranchComboList[i];
            optionList.push(<Option value={`${branch.id}`}>{branch.branch_name}</Option>);
        }
        return optionList;
    }
    const renderMajorOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกสาขา</Option>);
        for(let i = 0; i < filterMajorComboList.length; i++){
            let maj = filterMajorComboList[i];
            optionList.push(<Option value={`${maj.id}`}>{maj.branch_name}</Option>);
        }
        return optionList;
    }

    
    const onReset = () => {
        setSchoolYear('');
        setSelectedFaculty('ALL');
        setSelectedFacultyBranch('ALL');
        setSelectedMajor('ALL');
        setSelectedDocument('ALL');
    }

    const createReport = () => {
        if(selectedDocument === "" || selectedDocument === "ALL") {
            notification.error({message: "กรุณาเลือกเลือกประเภทเอกสาร"})
            return;
        }
        if(reportForm.current){
            reportForm.current.action = `${ApiConfig.backendUrl}/reportController/generateReport.php`;
            const formData = new FormData(reportForm.current);
            if(searchFromDate){
                formData.append('searchFromDate', searchFromDate.format('YYYYMMDD'));
            }
            if(searchToDate){
                formData.append('searchToDate', searchToDate.format('YYYYMMDD'));
            }
            if(selectedFaculty != "ALL"){
                formData.append('facultyId', selectedFaculty);

            }
            if(selectedFacultyBranch != "ALL"){
                formData.append('branchId', selectedFacultyBranch);
            }
            // if(selectedDocument != "ALL"){
            //     formData.append('reportId', selectedDocument);
            // }
            reportForm.current.submit();
        }
        
    }

    return (
        <div className={styles['main-container']}>
            <form ref={reportForm} target="_blank" method="post">
            <input type={"hidden"} name="reportTypeId" value={selectedDocument != "ALL" ? selectedDocument : ""}/>
            <input type={"hidden"} name="generateUserId" value={userId} />
            <input type={"hidden"} name="searchFromDate" value={searchFromDate ? searchFromDate.format("YYYYMMDD") : ""}/>
            <input type={"hidden"} name="searchToDate" value={searchToDate ? searchToDate.format("YYYYMMDD") : ""}/>
            <input type={"hidden"} name="facultyId" value={selectedFaculty != "ALL" ? selectedFaculty : ""}/>
            <input type={"hidden"} name="branchId" value={selectedFacultyBranch != "ALL" ? selectedFacultyBranch : ""}/>
            <input type={"hidden"} name="majorId" value={selectedMajor != "ALL" ? selectedMajor : ""}/>
            <div className={styles['search-container']}>
                {/* <div className={styles['search-component']}>
                    <Input type={"text"} placeholder="ชื่อผู้อัปโหลด" />
                </div> */}
                <div className={styles['search-component']}>
                    <div className={styles['input-line']}>
                        <Select
                            showSearch
                            placeholder="เลือกประเภทเอกสาร"
                            optionFilterProp="children"
                            onChange={onDocumentChange}
                            // onSearch={onSearch}
                            value={selectedDocument}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            className={styles['select-full']}
                        >
                            {renderRequestDocumentOption()}
                        </Select>
                    </div>
                </div>
                <div className={styles['search-component']}>
                    <Input type={"text"} placeholder="ปีการศึกษา" value={schoolYear} onChange={(e) => {setSchoolYear(e.target.value)}} />
                </div>
                <div className={styles['search-component']}>
                    <div className={styles['input-line']}>
                        <Select
                            showSearch
                            placeholder="เลือกคณะ"
                            optionFilterProp="children"
                            value={selectedFaculty}
                            onChange={onFacultyChange}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            className={styles['select-full']}
                        >
                            {renderFacultyOption()}
                        </Select>
                    </div>
                </div>
                <div className={styles['search-component']}>
                    <div className={styles['input-line']}>
                        <Select
                            showSearch
                            placeholder="เลือกภาควิชา"
                            optionFilterProp="children"
                            className={styles['select-half']}
                            value={selectedFacultyBranch}
                            onChange={onFacultyBranchChange}
                            // onSearch={onSearch}
                            style={{marginRight: 4}}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {renderFacultyBranchOption()}
                        </Select>
                        <Select
                            showSearch
                            placeholder="เลือกสาขาวิชา"
                            optionFilterProp="children"
                            className={styles['select-half']}
                            value={selectedMajor}
                            onChange={onMajorChange}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {renderMajorOption()}
                        </Select>
                    </div>
                </div>
                <div className={styles['input-line']}>
                    <DatePicker 
                        value={searchFromDate} 
                        placeholder="วันที่เริ่มต้น" 
                        className={styles['select-half']} 
                        style={{marginRight: 4}} 
                        onChange={(value) => {
                            if(searchToDate && searchToDate.diff(value) < 0){
                                setSearchFromDate(searchToDate)
                            }else{
                                setSearchFromDate(value)
                            }
                        }} 
                        format={"DD/MM/YYYY"} 
                    />
                    <DatePicker 
                        value={searchToDate} 
                        placeholder="วันที่สิ้นสุด" 
                        className={styles['select-half']} 
                        onChange={(value) => {
                            if(searchFromDate && searchFromDate.diff(value) > 0){
                                setSearchToDate(searchFromDate)
                            }else{
                                setSearchToDate(value)
                            }
                        }} format={"DD/MM/YYYY"} 
                    />
                </div>
                {/* <div className={styles['search-component']}>
                    <span style={{marginRight: 5}}>วันที่อัปโหลด: </span>
                    <RangePicker
                        defaultValue={[moment(), moment()]}
                        format={dateFormat}
                    />
                </div> */}
                {/* <div className={styles['search-component']}>
                    <Input type={"text"} placeholder={"ชื่ออ้างอิง"} />
                </div> */}
                <div className={styles['search-component-submit']}>
                    <Button type="primary" style={{marginRight: 5}} onClick={createReport}>สร้างรายงาน</Button>
                    <Button type="default" onClick={onReset}>Reset</Button>
                </div>
            </div>
            {/* <div className={styles['list-container']}>
                <div className={styles['report-section-container']} style={{marginRight: 10}}>
                    <div className={styles['report-header-container']}>
                        จำนวนอัพโหลดไฟล์ต่อเดือน
                    </div>
                    <div>
                        <Line {...config}></Line>
                    </div>
                </div>
                <div className={styles['report-section-container']}>
                    <div className={styles['report-header-container']}>
                        จำนวนอัพโหลดไฟล์ต่อเดือน
                    </div>
                    <div>
                        <Line {...config}></Line>
                    </div>
                </div>
            </div>
            <div className={styles['list-container']}>
                <div className={styles['report-section-container']}></div>
            </div> */}
            </form>
        </div>
    )
}

export default CreateReport;