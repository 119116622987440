import { HomeOutlined, KeyOutlined, LogoutOutlined, ProfileOutlined } from '@ant-design/icons';
import { Input, Menu, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ApiConfig from '../../../constants/ApiConfig';
import { Personal } from '../../../models/Login';
import Person from '../../../models/Person';
import { logout } from '../../../services/AuthenService';
import { useAppDispatch, useAppSelector } from '../../../store';
import { SessionUserState } from '../../../store/authen/authenSlice';
import chulaLogo from '../../../assets/panotour/Mascot-f.png'
import styles from './Header.module.css';
import { LanguageState, setLang } from '../../../store/language/languageSlice';
const Header = () => {
    const newUser: Personal = {
        firstname: "Khananat",
        lastname: "Jaroenchai",
        // role: "ผู้ดูแลระบบ"
        login_id: -2,
        phone_no: "",
        student_code: "",
    }
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const lang: LanguageState = useAppSelector(state => state.language);

    const dispatch = useAppDispatch();
    const [currentUser, setCurrentUser] = useState<Personal>(newUser);
    const [navigatePage, setNavigatePage] = useState<string>();
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);

    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang])

    const navigate = useNavigate();

    useEffect(() => {
      if(navigatePage != null && navigatePage.trim() != ""){
        navigate(navigatePage, {replace: true});
      }
    },[navigatePage]);

    useEffect(() => {
        if(authen && authen.sessionUser){
            setCurrentUser(authen.sessionUser.personData);
        }
    }, [authen])

    const logoutHandler = () => {
        if(authen && authen.sessionUser){
            logout(authen.sessionUser.currentUser.id, dispatch);
            setNavigatePage("/home/manage")
        }
    }
    return (
        <header className={"topbar"} data-navbarbg="skin5">
            <nav className={"navbar top-navbar navbar-expand-md navbar-dark"}>
                <div className={"navbar-header"} data-logobg="skin5">
                    <Link className={"navbar-brand"} to="/home/management">
                        <b className={"logo-icon"}>
                            <img src={chulaLogo} alt="homepage" className={styles["logo"]} />
                            {/* <img src={`${ApiConfig.publicUrl}/assets/mock_up_logo.png`} alt="homepage" className={"mock-logo"} /> */}
                            {/* <img src="assets/images/logo-light-icon.png" alt="homepage" className={"light-logo"} /> */}
                        </b>
                        {/* <span className={"logo-text"}>
                            <img src="assets/images/logo-text.png" alt="homepage" className={"dark-logo"} />
                            <img src="assets/images/logo-light-text.png" className={"light-logo"} alt="homepage" />
                        </span> */}
                    </Link>
                    <a className={"nav-toggler waves-effect waves-light d-block d-md-none"}>
                        <i className={"ti-menu ti-close"}></i></a>
                </div>
                <div className={"navbar-collapse collapse"} id="navbarSupportedContent" data-navbarbg="skin5">
                    <ul className={"navbar-nav float-start me-auto"}>
                        <li className={"nav-item search-box"}> 
                            {/* <a className={"nav-link waves-effect waves-dark"} href="#">
                                <i className={"ti-search"}></i>
                            </a>
                            <form className={"app-search position-absolute"}>
                                <Input type="text" className={"form-control"} placeholder="Search &amp; enter" /> <a
                                    className={"srh-btn"}><i className={"ti-close"}></i></a>
                            </form> */}
                        </li>
                    </ul>
                    <ul className={"navbar-nav float-end"}>
                        <li className={"nav-item dropdown"}>
                            <Select
                                style={{ width: 120 }}
                                onChange={(value: "th" | "en") => {
                                    dispatch(setLang({lang: value}));
                                }}
                                options={[
                                    {
                                        value: 'th',
                                        label: 'ภาษาไทย',
                                    },
                                    {
                                        value: 'en',
                                        label: 'English',
                                    },
                                ]}
                                value={currentLang}
                            />
                        </li>
                    </ul>
                    <ul className={"navbar-nav float-end"}>
                        <li className={"nav-item dropdown"}>
                            <a className={"nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={`${ApiConfig.publicUrl}/assets/icons/profile_image.png`} alt="user" className={"rounded-circle"} width="31" />
                                <span className={styles['user-detail']}>{currentUser.firstname} {currentUser.lastname}</span>
                            </a>
                            <ul className={"dropdown-menu dropdown-menu-end user-dd animated"} aria-labelledby="navbarDropdown">
                                <Link className={"dropdown-item"} to="/">
                                    <HomeOutlined />
                                    Homepage
                                </Link>
                                <Link className={"dropdown-item"} to="/management/profile">
                                    <ProfileOutlined />
                                    My Profile
                                </Link>
                                <Link className={"dropdown-item"} to="/management/profile/changePassword">
                                    <KeyOutlined />
                                    Change Password
                                </Link>
                                <a className={"dropdown-item"} onClick={logoutHandler}>
                                    <LogoutOutlined />
                                    Logout
                                </a>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Header;