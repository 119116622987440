import axios from "axios";
import ApiConfig from "../constants/ApiConfig";
import { ApiResponse } from "../models/MasterModel";
import { SessionUserState } from "../store/authen/authenSlice";

export interface PermissionHelperResult{
    hasPermissionName: string[],
    notHasPermissionName: string[],
    resultFlg: boolean,
}
class PermissionHelper {
    relavantPermissionList: string[] | null;
    currentPermission: {[key: string]: string};
    userId: number | null;
    constructor(){
        this.relavantPermissionList = null;
        this.currentPermission = {};
        this.userId = null
        // this.loadUserPermission(initRelavantPermissionList, authen)
    }
    
    getAuthPermission = async (userId: number, permissionNameList: string[]) => {
        let url = `${ApiConfig.backendUrl}/userController/getAuthPermission.php`;
        let res = await axios.post(url, {userId, permissionNameList}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
        });
        // console.log("response status: ", res.status);
        if(res.status === 200){
            // console.log("response data: ", res.data);
            let resData: ApiResponse = res.data;
            // let currentUser: SessionUser = createSessionUser(user, res.data.loginData, res.data.personData);
        
            return resData;
            // return currentUser;
        }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
            return resData;
        }
        
    }
    loadUserPermission = async (permNameList: string[], authen: SessionUserState) => {
        // console.log("loadUserPermission");
        if(permNameList === null || permNameList === undefined || permNameList.length < 1){
            return;
        }
        if(authen && authen.sessionUser){
            this.relavantPermissionList = permNameList;
            this.userId = authen.sessionUser.currentUser.id;
            let resData: ApiResponse = await this.getAuthPermission(this.userId, permNameList);
            if(resData.status){
                let data: {[key: string]: string} = resData.data;
                this.currentPermission = data;
            }
        }
    }

    hasPermission = (permissionName: string): boolean => {
        return this.currentPermission[permissionName] != undefined && this.currentPermission[permissionName] != null;
    }

    hasAllPermission = (permissionNameList: string[]): PermissionHelperResult => {
        let resultObj: PermissionHelperResult = {
            hasPermissionName: [],
            notHasPermissionName: [],
            resultFlg: true,
        };
        let resultFlg = true;
        for(let permissionName of permissionNameList){
            if(this.hasPermission(permissionName)){
                resultObj.hasPermissionName.push(permissionName)
                resultFlg = resultFlg && true;
            }else{
                resultObj.notHasPermissionName.push(permissionName)
                resultFlg = resultFlg && false;
            }
        }
        resultObj.resultFlg = resultFlg;
        return resultObj
    }

    hasSomePermission = (permissionNameList: string[]): PermissionHelperResult => {
        let resultObj: PermissionHelperResult = {
            hasPermissionName: [],
            notHasPermissionName: [],
            resultFlg: false,
        };
        let resultFlg = false;
        for(let permissionName of permissionNameList){
            if(this.hasPermission(permissionName)){
                resultObj.hasPermissionName.push(permissionName)
                resultFlg = resultFlg || true;
            }else{
                resultObj.notHasPermissionName.push(permissionName)
                resultFlg = resultFlg || false;
            }
        }
        resultObj.resultFlg = resultFlg;
        return resultObj
    }
}

export default PermissionHelper;