import { useEffect, useState  } from "react";
import { useAppDispatch } from "../../../../../store";
import { setPageName, setBack2Page } from "../../../../../store/pagination/paginationSlice";
import { Button, DatePicker, Input, message, Modal, notification, Popconfirm, Select, Space, Spin, Table, Upload } from "antd";
import { CloudUploadOutlined, DeleteTwoTone, DownloadOutlined, EditTwoTone, InboxOutlined, PlusOutlined, RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import axios from "axios";
import styles from './RoleForm.module.css';
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import type { UploadFile } from 'antd/es/upload/interface';
import ApiConfig from "../../../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { deleteUserFromRole, editRole, getPermission, getRoleById, getUserInRole, getUserNotInRole } from "../../../../../services/UserService";
import Role from "../../../../../models/Role";
import { Permission } from "../../../../../models/Login";
import { ApiResponse } from "../../../../../models/MasterModel";

const RoleForm = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams<string>();
    const {Option} = Select;
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [markerList, setMarkerList] = useState<Array<any>>([]);
    const [photoDate, setPhotoDate] = useState<any>(moment());
    const [refName, setRefName] = useState<string>("");
    const [dataList, setDataList] = useState<any>([]);
    const [isUploadDisable, setUploadDisable] = useState<boolean>(false);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [downloadFileList, setDownloadFileList] = useState<any>([]);
    const [roleData, setRoleData] = useState<Role>();
    const [permissionComboList, setPermissionComboList] = useState<Permission[]>();
    const [userNotInRoleList, setUserNotInRoleList] = useState<any[]>([]);
    const [notInRoleSelected, setNotInRoleSelected] = useState<string[]>();

    const [roleName, setRoleName] = useState<string>();
    const [roleDescription, setRoleDescription] = useState<string>();
    const [selectedPermission, setSelectedPermission] = useState<string[]>();
    // const [loadedImageList, setLoadedImageList] = useState<TransactionMainImage[]>([]);

    const mockData = [
        {
            name: "ภูดินันท์ สิงห์คำฟู",
            email: "Phudinun@gmail.com",
            phone_no: "0xxxxxxxxx",
        },
        {
            name: "คณนาถ เจริญไชย",
            email: "Khananat@gmail.com",
            phone_no: "0xxxxxxxxx",
        },
        // {
        //     name: "ภูดินันท์ สิงห์คำฟู",
        //     role: "เจ้าหน้าที่",
        //     process_date: "07/06/2022 09:10:57",
        //     old_status: "PENDING",
        //     new_status: "APPROVE",
        // },
    ]
    const columns = [
        {
            title: 'ชื่อ - นามสกุล',
            // dataIndex: 'name',
            key: 'name',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: any) => {return data.firstname + " " + data.lastname},
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: 'รหัสนักศึกษา',
            dataIndex: 'student_code',
            key: 'student_code',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: 'เบอร์โทรศัพท์',
            dataIndex: 'phone_no',
            key: 'phone_no',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        
        {
          title: 'Action',
          key: 'action',
          render: (text: string, record: any) => (
            <Space size="middle">
                {/* <Link to={`/management/user/form/${record.id}`}><EnvironmentTwoTone /></Link> */}
                <Link to={`/management/user/form/${record.id}`}><EditTwoTone /></Link>
                        <Popconfirm 
                            title="ต้องการลบผู้ใช้จากบทบาทหรือไม่"
                            onCancel={() => {}}
                            onConfirm={() => {removeLoginFromRole(record.id, id)}}
                            okText={"ลบ"}
                        >
                            <DeleteTwoTone />
                        </Popconfirm>
            </Space>
          ),
        },
    ]
    useEffect(() => {
        dispatch(setPageName({th: "บทบาทผู้ใช้", en: "Role"}));
        dispatch(setBack2Page("/management/role"));
        // setDataList(mockData)
        loadRoleById();
        loadPermissionCombo();
        loadPersonInRole();
        // setFileList(mockFileList)
    }, [])

    useEffect(() => {
        if(fileList){
            setUploadDisable(fileList.length > 0)
        }
    }, [fileList])
    const inputTextChange = (e: any, setState: any) => {
        setState(e.target.value);
    }
    const loadRoleById = async () => {
        let resData: ApiResponse = await getRoleById(id);
        if(resData.status){
            let role: Role = resData.data;
            setRoleName(role.role_name);
            setRoleDescription(role.role_description);
            setSelectedPermission(role.permission ? role.permission.map((value) => {return "" + value.id}) : []);
        }
    }
    const loadPermissionCombo = async () => {
        let resData: ApiResponse = await getPermission();
        if(resData.status){
            setPermissionComboList(resData.data);
        }
    }
    const loadPersonInRole = async () => {
        let resData: ApiResponse = await getUserInRole(id);
        if(resData.status){
            setDataList(resData.data);
            // setPermissionComboList(resData.data);
        }
    }
    const loadPersonNotInRole = async () => {
        let resData: ApiResponse = await getUserNotInRole(id);
        if(resData.status){
            setUserNotInRoleList(resData.data);
            // setPermissionComboList(resData.data);
        }
    }
    
    const renderPermission = () => {
        let optionList = [];
        if(permissionComboList){
            for(let i = 0; i < permissionComboList.length; i++){
                let perm = permissionComboList[i];
                optionList.push(<Option value={`${perm.id}`}><div title={perm.permission_description}>{perm.permission_name}</div></Option>);
            }
            return optionList;
        }
    }

    const saveEditRole = async () => {

        // let selectedPermissionModelList = [];
        // if(permissionComboList){
        //     selectedPermissionModelList = permissionComboList.filter((value) => {
        //         return selectedPermission && selectedPermission.findIndex((selectedVal) => {return Number(selectedVal) == value.id}) > -1
        //     })
        // }
        let resData = await editRole({
            id: id,
            role_name: roleName,
            role_description: roleDescription,
            permission: selectedPermission ? selectedPermission : []
        });
        if(resData.status){
            notification.success({message: "บันทึกข้อมูลสำเร็จ", description: resData.message});
        }else{
            notification.error({message: "บันทึกข้อมูลไม่สำเร็จ", description: resData.message});
        }
    }

    const renderUserNotInRoleOption = () => {
        let optionList = userNotInRoleList.map((value, index) => {
            return (<Select.Option value={value.id}>{`${value.firstname} ${value.lastname}`}</Select.Option>)
        });
        return optionList;
    }

    const addNewUserInSubRole = () => {
        // console.log();
        setPreviewVisible(false)
    }

    const removeLoginFromRole = async (userId: number, roleId: any) => {
        console.log("Delete userId: ", userId, " from roleId: ", roleId);
        deleteUserFromRole(roleId, userId).then(() => {
            loadPersonInRole();
        });

    }


    return (
        <div className={styles['main-container']}>
            <div className={styles['data-container']}>
                <div className={styles['input-container']}>
                    <Input 
                        style={{width: 550, marginRight: 5}} 
                        className={styles['input-text']} 
                        placeholder={"ชื่อบทบาท"} 
                        value={roleName} 
                        onChange={(e) => {inputTextChange(e, setRoleName)}} 
                        readOnly={(id != null && Number.parseInt(id) <= 0)}></Input>
                </div>
                <div className={styles['input-container']}>
                    <Input.TextArea 
                        style={{width: 550, marginRight: 5}} 
                        className={styles['input-text']} 
                        placeholder={"คำอธิบาย"} 
                        value={roleDescription} 
                        onChange={(e) => {inputTextChange(e, setRoleDescription)}} 
                        readOnly={(id != null && Number.parseInt(id) <= 0)}></Input.TextArea>
                </div>
                <div className={styles['input-container']}>
                    <Select
                        showSearch
                        mode="multiple"
                        placeholder="เลือกสิทธิ์สำหรับบทบาท"
                        optionFilterProp="children"
                        onChange={(value) => {setSelectedPermission(value)}}
                        // onSearch={onSearch}
                        value={selectedPermission}
                        filterOption={(input, option) =>
                            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                        className={styles['select-half']}
                        style={{marginRight: 4, width: 550}}
                    >
                        {renderPermission()}
                    </Select>
                </div>
                <div className={styles['input-container']}>
                    <Button style={{marginRight: 5}} type={"primary"} icon={<SaveOutlined />} onClick={saveEditRole}>บันทึกข้อมูล</Button>
                </div>
            </div>
            <div className={styles['list-container']}>
                <div className={styles['input-container']} style={{justifyContent: 'flex-end', margin: 5}}>
                    <Button type={'primary'} onClick={() => {
                        loadPersonNotInRole();
                        setPreviewVisible(true);
                    }}>เพิ่มผู้ใช้งาน</Button>
                </div>
                <Table columns={columns} dataSource={dataList}/>
            </div>
            <Modal
                visible={previewVisible}
                title={"เพิ่มผู้ใช้งาน"}
                onCancel={() => {setPreviewVisible(false); setNotInRoleSelected([])}}
                onOk={() => {addNewUserInSubRole()}}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']}>
                        <Select
                            showSearch
                            mode="multiple"
                            placeholder="เลือกผู้ใช้งานใหม่"
                            optionFilterProp="children"
                            value={notInRoleSelected}
                            onChange={(values) => {setNotInRoleSelected(values)}}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            className={styles['select-half']}
                            style={{marginRight: 4, width: "100%"}}
                        >
                            {renderUserNotInRoleOption()}
                        </Select>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default RoleForm;