import { useEffect, useState } from "react";
import styles from "../RequestDocumentUploadForm.module.css";
import { Button, Col, Modal, Row, Table, message, notification } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { CheckCircleTwoTone, DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import { convertDBDate2Show, convertDBTime2Show } from "../../../../../helper/DateTimeHelper";
import ApiConfig from "../../../../../constants/ApiConfig";

import type { UploadProps } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import axios from "axios";
import { RequestDocumentAttachFile, RequestDocumentData, RequestDocumentUploadFile } from "../../../../../models/RequestDocumentModel";
import { useParams } from "react-router-dom";
import { getRequestDocumentById } from "../../../../../services/RequestDocumentService";
import { LanguageState } from "../../../../../store/language/languageSlice";
import { useAppSelector } from "../../../../../store";
import { labelLang } from "../../../../../helper/HTMLHelper";

interface UploadTabProps{
    showTable: boolean;
    isDraftStatus: boolean;
    isOwner: boolean;
    refId: any;
    requestDocumentData: RequestDocumentData | undefined;
    requestDocumentUploadFileList: RequestDocumentUploadFile[];
    adminDownload: any;
}
const UploadTab = (props: UploadTabProps) => {
    const { id } = useParams<string>();
    const [showTable, setShowTable] = useState<boolean>(props.showTable);
    const [downloadFileList, setDownloadFileList] = useState<any[]>([]);
    const [confirmDialog, setConfirmDialog] = useState<any>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    const [isDraftStatus, setIsDraftStatus] = useState<boolean>(false);
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [refId, setRefId] = useState<any>(props.refId);
    const [requestDocumentData, setRequestDocumentData] = useState<RequestDocumentData | undefined>(props.requestDocumentData);
    const [requestDocumentUploadFileList, setRequestDocumentUploadFileList] = useState<RequestDocumentUploadFile[]>(props.requestDocumentUploadFileList);
    const [confirm2RemoveVisible, setConfirm2RemoveVisible] = useState<boolean>(false);
    const [removeFileTmp, setRemoveFileTmp] = useState<UploadFile | null>();
    const [removeFileName, setRemoveFileName] = useState<string>("");

    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])

    const fileColumns = [
        {
            title: labelLang('ชื่อเอกสาร', "Filename", currentLang),
            dataIndex: 'filename',
            key: 'filename',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (value: string, data: any) => { return labelLang(data.filename, data.filename_en ? data.filename_en : data.filename, currentLang) },
        },
        {
            title: labelLang('วันที่อัปโหลด', "Upload Date", currentLang),
            // dataIndex: 'upload_date',
            key: 'upload_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: any) => {return `${convertDBDate2Show(data.upload_date)} ${convertDBTime2Show(data.upload_time)}`},
        },
        {
            title: labelLang('ตรวจสอบเอกสาร', "Check Document", currentLang),
            key: 'download',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: any) => {return (
                // <Button icon={<DownloadOutlined />} onClick={() => {props.adminDownload(data)}}>เปิดเอกสาร</Button>
                <Button onClick={() => {props.adminDownload(data)}}>{labelLang('เปิดเอกสาร', "Open Document", currentLang)}</Button>
            )},
        }
    ]
    
    useEffect(() => {
        console.log("confirmDialog: ", confirmDialog);
    }, [confirmDialog])
    const loadRequestDocumentData = async () => {
        let resData = await getRequestDocumentById(id);
        if(resData.status){
            setRequestDocumentData(resData.data);
        }
    }

    const createDefaultFile = (uid: string, filename: string, url: string, id: number) => {
        return {
          uid: uid,
          name: filename,
          status: 'done',
          url: `${ApiConfig.uploadedAssets}${url}`,
          shortUrl: `${url}`,
          id: id
        }
    }
    const onRemoveFileCancel = () => {
        setConfirm2RemoveVisible(false);
        setConfirmDialog(null);
    }
    let multipleTmp: any[] = [];
    // const onRemoveClicked = (e: any, myFileList: any, setMyFileList: any, removeUrl: string, isMultiple: any) => {
        const onRemoveClicked = (e: any, myFileList: any, removeUrl: string, isMultiple: any) => {
        const callback = async () => {
            let fmData = new FormData
            const config = {
              headers: { "content-type": "multipart/form-data" },
            };
            fmData.append("attachId", e.id);
            fmData.append("requestDocudmentId", id ? id : "0");
            try {
                const res = await axios.post(
                    `${ApiConfig.backendUrl}/requestDocumentController/${removeUrl}.php`,
                    fmData,
                    config
                );
                console.log("server res: ", res);
                if(res.status == 200){
                    // let data = res.data.data;
                    if(isMultiple){
                        const index = myFileList.indexOf(e);
                        const newFileList = myFileList.slice();
                        newFileList.splice(index, 1);
                        // setMyFileList(newFileList);
                    }else{
                        // setMyFileList([]);
                    }
                    loadRequestDocumentData();
                }
            } catch (err) {
                console.log("Eroor: ", err);
                const error = new Error("Some error");
            }
            setConfirmDialog(null);
        }
        let newConfirmDialog = (
            <Modal
                visible={true}
                title={labelLang("ยืนยันการลบเอกสาร", "Confirm delete document", currentLang)}
                onCancel={onRemoveFileCancel}
                onOk={callback}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                        {labelLang(`ยืนยันการลบไฟล์ "${e.name}" หรือไม่?`, `Are you sure to delete "${e.name}"`, currentLang)}
                    </div>
                </div>
            </Modal>
        )
        setConfirmDialog(newConfirmDialog);
        setRemoveFileName(e.name)
        setRemoveFileTmp(e);
        setConfirm2RemoveVisible(true);
        // setRemovedCallback(callback);
    }
    const createProps = (myFileList: any[], uploadName: string, removeName: string, isMultiple: boolean = false, uploadFileId: number, acceptPdf: boolean, acceptImage: boolean) => {
        const props: UploadProps = {
            // name: 'file',
            fileList: myFileList,
            multiple: isMultiple,
            openFileDialogOnClick: myFileList.length == 0 || isMultiple,
            disabled: !isDraftStatus || !isOwner,
            beforeUpload: async (file: File) => {
                console.log("beforeUpload: ", file);
                let isPdf = file.type == "application/pdf";
                let isImage = file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg";
                let isPassCondition = true;
                if(acceptPdf && acceptImage && !isPdf && !isImage) {
                    notification.error({message: "การอัพโหลดไฟล์ไม่สำเร็จ", description: "การอัพโหลดจำกัดเฉพาะไฟล์ PDF หรือ ไฟล์รูป เท่านั้น"})
                    isPassCondition = false;
                }
                else if(acceptPdf && !acceptImage && !isPdf){
                    notification.error({message: "การอัพโหลดไฟล์ไม่สำเร็จ", description: "การอัพโหลดจำกัดเฉพาะไฟล์ PDF เท่านั้น"})
                    isPassCondition = false;
                }
                else if(acceptImage && !acceptPdf && !isImage){
                    notification.error({message: "การอัพโหลดไฟล์ไม่สำเร็จ", description: "การอัพโหลดจำกัดเฉพาะไฟล์ รูป เท่านั้น"})
                    isPassCondition = false;
                }
                return isPassCondition && (myFileList.length == 0 || isMultiple);
            },
            customRequest: async (options: any) => {
                const { onSuccess, onError, file, onProgress } = options;
                const fmData = new FormData();
                console.log("custom upload: ", file);
                const config = {
                  headers: { "content-type": "multipart/form-data" },
                  onUploadProgress: (event: any) => {
                    const percent = Math.floor((event.loaded / event.total) * 100);
                    // setProgress(percent);
                    if (percent === 100) {
                    //   setTimeout(() => setProgress(0), 1000);
                    }
                    onProgress({ percent: (event.loaded / event.total) * 100 });
                  }
                };
                fmData.append("file", file);
                fmData.append("requestDocumentId", id ? id : "0");
                fmData.append("requestDocumentUploadFileId", ""+uploadFileId);
                try {
                    const res = await axios.post(
                        `${ApiConfig.backendUrl}/requestDocumentController/${uploadName}.php`,
                        fmData,
                        config
                    );
                    console.log("server res: ", res);
                    if(res.status == 200){
                        let data = res.data.data;
                        let newFile = createDefaultFile(uploadName, data.filename, data.uploadFilePath, data.id);
                        onSuccess(file);
                        loadRequestDocumentData();
                        // if(isMultiple){
                        //     multipleTmp.push(newFile);
                        //     loadRequestDocumentData();
                        //     // myFileList.push(newFile);
                        //     // setMyFileList([...myFileList, ...multipleTmp]);
                        // }else{
                        //     myFileList = [newFile];
                        //     // setMyFileList([newFile])
                        // }
                    }
                } catch (err) {
                    console.log("Eroor: ", err);
                    const error = new Error("Some error");
                    onError({ err });
                }
            },
            onChange(info) {
              const { status } = info.file;
              if (status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (status === 'done') {
                let newFileList = [info.file];
                // setMyFileList(newFileList);
                notification.success({message: "อัพโหลดไฟล์สำเร็จ", description: `${info.file.name}.`});
              } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            },
            onRemove(e) {
                console.log("remove: ", e);
                // return false;
                // onRemoveClicked(e, myFileList, setMyFileList, removeName, isMultiple);
                onRemoveClicked(e, myFileList, removeName, isMultiple);
            },
            onDrop(e) {
              console.log('Dropped files', e.dataTransfer.files);
              if(!(myFileList.length == 0 || isMultiple)){
                notification.error({message: 'อัพโหลดไม่สำเร็จ', description: "มีไฟล์อัพโหลดอยู่แล้ว"});
              }
            },
            onDownload(e) {
                let file: any = e;
                const callback = () => {
                    window.open(`${ApiConfig.backendUrl}/requestDocumentController/readAttachFile.php?attachId=${file.id}`, "_blank");
                    setConfirmDialog(null);
                }
                let newConfirmDialog = (
                    <Modal
                        visible={true}
                        title={labelLang(`ดาวน์โหลดเอกสาร`, `Download`, currentLang)}
                        onCancel={onRemoveFileCancel}
                        onOk={callback}
                        okText={"Download"}
                    >
                        <div className={styles['modal-main-container']}>
                            <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                            {labelLang(`ยืนยันการลบไฟล์ "${e.name}" หรือไม่?`, `Are you sure to delete "${e.name}"`, currentLang)}
                                {/* ต้องการดาวน์โหลดไฟล์ "{e.name}" หรือไม่? */}
                            </div>
                        </div>
                    </Modal>
                )
                setConfirmDialog(newConfirmDialog);
            },
            // disabled: isUploadDisable,
            showUploadList: {
                showDownloadIcon: true,
                showRemoveIcon: true,
            },
            style: {minWidth: 500, marginLeft: 2, marginRight: 2, opacity: ((requestDocumentData?.status != "DRAFT") ? 0.8 : 1)},
        };
        return props;
    }
    useEffect(() => {
        setShowTable(props.showTable)
    }, [props.showTable])
    useEffect(() => {
        setIsDraftStatus(props.isDraftStatus)
    }, [props.isDraftStatus])
    useEffect(() => {
        setIsOwner(props.isOwner)
    }, [props.isOwner])
    useEffect(() => {
        setRefId(props.refId)
    }, [props.refId])
    useEffect(() => {
        setRequestDocumentData(props.requestDocumentData)
    }, [props.requestDocumentData])
    useEffect(() => {
        console.log('useeffect', props.requestDocumentUploadFileList);
        setRequestDocumentUploadFileList(props.requestDocumentUploadFileList);
    }, [props.requestDocumentUploadFileList])
    
    // const adminDownload = (data: any) => {
    //     console.log("adminDownload: ", data);
    //     const callback = () => {
    //         window.open(`${ApiConfig.backendUrl}/requestDocumentController/readAttachFile.php?attachId=${data.id}`, "_blank");
    //         setConfirmDialog(null);
    //     }
    //     let newConfirmDialog = (
    //         <Modal
    //             visible={true}
    //             title={"ดาวน์โหลดเอกสาร"}
    //             onCancel={() => {setConfirmDialog(null);}}
    //             onOk={callback}
    //             okText={"Download"}
    //         >
    //             <div className={styles['modal-main-container']}>
    //                 <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
    //                     ต้องการดาวน์โหลดไฟล์ "{data.filename}" หรือไม่?
    //                 </div>
    //             </div>
    //         </Modal>
    //     )
    //     setConfirmDialog(newConfirmDialog);
    // }
    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const getFileList = (item: RequestDocumentUploadFile): any[] => {
        let lst = requestDocumentData?.attach_files.filter(x => x.upload_config_id  === item.id);
        console.log(item.filename, lst);
        return lst?.map((x) => {
            return createDefaultFile(""+x.id, x.ori_file_name, x.file_path, x.id);
        }) ?? [];
    }
    const renderFileTable = () => {
        if(showTable){
            return (
                <div className={styles['list-container']}>
                    <Table columns={fileColumns} dataSource={requestDocumentData && requestDocumentData.attach_files ? requestDocumentData.attach_files : []} onChange={onChange} pagination={{pageSize: 5}} style={{width: '100%'}}/>
                </div>
            );
        }else{
            return (
                <Row>
                    {
                    requestDocumentUploadFileList.map((item, index: number) => {
                        let fileList = getFileList(item);
                        return (
                            <Col span={12} style={{padding: 5}}>
                                <div style={{display: 'block'}}>
                                    <Dragger key={item.id} {...createProps(fileList, "uploadFile", "removeFile", item.multiple === 1, item.id, item.pdf === 1, item.image === 1)} style={fileList.length > 0 ? {borderColor: "#71DE04"} : {}}>
                                    <p className="ant-upload-text-label">{labelLang(item.filename, item.filename_en ? item.filename_en : item.filename, currentLang)}</p>
                                    <p className="ant-upload-drag-icon">
                                        {fileList.length > 0 ? <CheckCircleTwoTone twoToneColor={"#71DE04"} /> : <InboxOutlined />}
                                    </p>
                                    <p className="ant-upload-text">คลิก หรือลากไฟล์มาวางบริเวณนี้เพื่ออัปโหลดไฟล์</p>
                                    <p className="ant-upload-hint">
                                     {item.multiple === 0 ? 'อัพโหลดได้เพียง 1 ไฟล์' : 'อัพโหลดได้หลายไฟล์'} ({item.pdf === 1 ? 'PDF,': ''}{item.image === 1 ? 'รูป': ''})
                                    </p>
                                    </Dragger>
                                </div>
                            </Col>
                        )
                    })
                }
                {confirmDialog}
                </Row>
            )
        }
    }
    return renderFileTable();
}
export default UploadTab;