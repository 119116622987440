import * as PANOLENS from "panolens";
import * as THREE from "three";
import React, {Component, useEffect, useRef, useState} from 'react'
import video from '../../../assets/panotour/Video/open_video_2000.mp4';

import { Button, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import styles from './HomeVideo.module.css'
import { videoFrames } from "./HomeVideoFrames";

const HomeVideo = (props) => {
  const containerRef = useRef(null);
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const [viewer, setViewer] = useState(null);
  const [infoBox, setInfoBox] = useState(null);
  const [fadeClass, setFadeClass] = useState("fade-container");
  const [currentFrame, setCurrentFrame] = useState(null);
  const [currentFrameIndex, setCurrentFrameIndex] = useState(-1);
  const [nowInterval, setNowInterval] = useState(null);
  const [countImageLoaded, setCountImageLoaded] = useState(0);
  const [windowDimenion, setWindowDimenion] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })
  const [allImageLoaded, setAllImageLoaded] = useState(false)

  useEffect(() => {
    console.log("useEffect");
    const resizeHandle = () => {
      console.log("resize: ", {
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      });
      setWindowDimenion({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight,
      });
    }
    const loadedmetadataHandler = () => {
      console.log("metadata loaded");
      containerRef.current.play();
    }
    console.log("useEffect1");
    window.addEventListener("resize", resizeHandle);
    // containerRef.current.addEventListener('loadedmetadata', loadedmetadataHandler)
    return () => {
      window.removeEventListener("resize", resizeHandle);
      if(containerRef && containerRef.current){
        containerRef.current.removeEventListener('loadedmetadata', loadedmetadataHandler)
      }
      if(nowInterval){
        clearInterval(nowInterval);
        nowInterval = null;
      }
    }
  }, [])

  useEffect(() => {
    if(allImageLoaded){
      animateVideo();
    }
  }, [allImageLoaded])

  let navigate = useNavigate();

  const animateVideo = () => {
    if(allImageLoaded){
      let currentIndex = 0;
      let localNowInterval = null;
      if(!isFirefox){
        let frameRate = 1000 / 24;
        // let frameRate = 3000 / videoFrames.length;
        // console.log("frameRate: ", frameRate)
        localNowInterval = setInterval(() => {
          // console.log("interval: ", currentIndex)
          setCurrentFrame(videoFrames[currentIndex]);
          setCurrentFrameIndex(currentIndex);
          currentIndex++;
          if(currentIndex >= videoFrames.length){
            clearInterval(localNowInterval)
            localNowInterval = null;
            setNowInterval(localNowInterval);
            endState();
          }
        }, frameRate)
        setNowInterval(localNowInterval);
      }
    }
  }

  useEffect(() => {
    // console.log("update countImageLoaded: ", countImageLoaded);
    if(countImageLoaded > 0 && countImageLoaded >= videoFrames.length){
      setAllImageLoaded(true);
    }
  }, [countImageLoaded])

  let unStateCount = 0;
  const imageLoaded = () => {
    unStateCount++;
    // console.log("imageLoaded: ", countImageLoaded, " : ", unStateCount);
    setCountImageLoaded(countImageLoaded + unStateCount);
  }
  const endState = () => {
    if(props.onEndState){
      setFadeClass("fade-container-active");
      setTimeout(() => {
        props.onEndState();
      }, 1200)
    }
  }
  const renterVideo = () => {
    if(isFirefox){
      // if(currentFrame){
      //   return (<img ref={containerRef} width={windowDimenion.winWidth} height={windowDimenion.winHeight} src={currentFrame}></img>);
      // }
      return (
        <video width={windowDimenion.winWidth} height={windowDimenion.winHeight} ref={containerRef} autoplay="true" onEnded={endState}>
          <source src={video} type="video/mp4" />
        </video>
      )
    }else{
      let imageCompList = videoFrames.map((value, index) => {
        return (<img ref={containerRef} width={windowDimenion.winWidth} height={windowDimenion.winHeight} src={value} style={{display: (index == currentFrameIndex ? "flex" : "none")}} onLoad={imageLoaded}></img>);
      })
      return imageCompList;
    }
  }
  return (
    <div style={{width: windowDimenion.winWidth, height: windowDimenion.winHeight}}>
      <div className={styles[fadeClass]}>

      </div>
      {/* <video width={windowDimenion.winWidth} height={windowDimenion.winHeight} ref={containerRef} autoplay="true" controls onEnded={endState}>
        <source src={video} type="video/mp4" />
      </video> */}
      {renterVideo()}
    </div>
  );
};

export default HomeVideo;
