import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setBack2Page, setPageName } from "../../../../store/pagination/paginationSlice";
import { DeleteTwoTone, EditTwoTone, EnvironmentTwoTone, InboxOutlined, PlusSquareTwoTone } from "@ant-design/icons";
import { Button, DatePicker, Input, message, Modal, notification, Select, Space, Table } from "antd";
import styles from './RequestDocumentUploadList.module.css';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import type { UploadFile } from 'antd/es/upload/interface';
import axios from "axios";
import ApiConfig from "../../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { RequestDocumentData, RequestDocumentModel } from "../../../../models/RequestDocumentModel";
import { convertDBDate2Show } from "../../../../helper/DateTimeHelper";
import { SessionUserState } from "../../../../store/authen/authenSlice";
import { ApiResponse, Faculty, FacultyBranch } from "../../../../models/MasterModel";
import { addRequestDocument, deleteRequestDocumentList, downloadTemplateById, getRequestDocumentListByParam } from "../../../../services/RequestDocumentService";
import { RcFile } from "antd/lib/upload";
import { PermissionName } from "../../../../models/Login";
import { getAuthPermission } from "../../../../services/AuthenService";
import { getDepartment, getFaculty, getFacultyBranch, getMajor } from "../../../../services/MasterService";
import { IBackendPageProps } from "../../models";
import RequestDocumentUploadListSearchSection from "./SearchSection/RequestDocumentUploadListSearchSection";
import AddNewRequestDocumentModal from "./modal/AddNewRequestDocumentModal";
import RequestDocumentUploadListTableSection from "./TableSection/RequestDocumentUploadListTableSection";
import PermissionHelper from "../../../../helper/PermissionHelper";
import { LanguageState } from "../../../../store/language/languageSlice";


const RequestDocumentUploadList = () => {
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const dispatch = useAppDispatch();
    const { RangePicker } = DatePicker;
    let navigate = useNavigate();

    const [dataList, setDataList] = useState<RequestDocumentModel[]>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [refName, setRefName] = useState<string>("");
    const [userId, setUserId] = useState<number>(-1);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [currentUploadFile, setCurrentUploadFile] = useState<UploadFile[] | undefined>();
    const [hasSearchPermission, setHasSearchPermission] = useState<boolean>(false);
    const [selectedMajor, setSelectedMajor] = useState<string>("ALL");
    const [uploadName, setUploadName] = useState<string>("");
    const [schoolYear, setSchoolYear] = useState<string>("");
    const [selectedFaculty, setSelectedFaculty] = useState<string>("ALL");
    const [selectedFacultyBranch, setSelectedFacultyBranch] = useState<string>("ALL");
    const [selectedStatus, setSelectedStatus] = useState<string>("ALL");

    const [currentPermission, setCurrentPermission] = useState<any>(null);
    const [isShowDrawer, setIsShowDrawer] = useState<boolean>(false);

    const permissionHelper = new PermissionHelper();
    const relavantPermissionList = [PermissionName.REQUIRE_DOC_SEARCH];

    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])

    useEffect(() => {
        dispatch(setPageName({th: "รายการคำร้อง", en: "Request Document List"}));
        dispatch(setBack2Page(null));
        loadUserPermission();
        search();
    }, [])

    useEffect(() => {
        if(authen && authen.sessionUser){
            loadUserPermission();
            setUserId(authen.sessionUser.currentUser.id);
            search();
        }
    }, [authen])

    const loadUserPermission = async () => {
        if(authen && authen.sessionUser){
            setUserId(authen.sessionUser.currentUser.id);
            await permissionHelper.loadUserPermission(relavantPermissionList, authen);
            setHasSearchPermission(permissionHelper.hasPermission(PermissionName.REQUIRE_DOC_SEARCH));
        }
    }
    
    const removeData = async (id: any) => {
        console.log("removeData: ", id);
        if(authen && authen.sessionUser){
            let resData: ApiResponse = await deleteRequestDocumentList(id, authen.sessionUser.currentUser.id);
            if(resData.status){
                // onSearch();
                // navigate(`/management/scholarship/form/${scholar.id}`);
                notification.success({message: "ลบข้อมูลสำเร็จ", description: resData.message})
                search();
            }
        }
    }
    
    const handleCancel = () => {setPreviewVisible(false)};

    const refNameChange = (e: any) => {
        setRefName(e.target.value);
    }

    const openAddModal = () => {
        setPreviewVisible(true);
    }

    const addNewReqDoc = async (params: any) => {
        if(authen && authen.sessionUser){
            // let formdata = new FormData();
            // let files: RcFile[] = fileList.map((value) => { return value as RcFile});
            // formdata.append("create_user_id", "" + authen.sessionUser.currentUser.id);
            // for(let i = 0; i < files.length; i++){
            //     formdata.append(`file[${i}]`, files[i]);
            // }
            
            let resData: ApiResponse = await addRequestDocument(params);
            if(resData.status){
                let reqDoc: RequestDocumentData = resData.data.requestDocument;
                notification.success({message: "บันทึกข้อมูลสำเร็จ", description: resData.message})
                navigate(`/management/requestdocument/form/${reqDoc.id}`);
            }else{
                notification.success({message: "เกิดข้อผิดพลาด", description: resData.message})
            }
            setPreviewVisible(false);
        }
        // navigate('/management/scholarship/form/1')
    }

    const handleDownloadTemplate = async (templateId: any) => {
        await downloadTemplateById(templateId);
    }

    const search = () => {
        if(authen && authen.sessionUser){
            let criteria = {
                    user_id: authen.sessionUser.currentUser.id,
                    doc_type_id: null,
                    upload_name: null,
                    school_year: null,
                    faculty_id: null,
                    branch_id: null,
                    major_id: null,
                    status: null
            }
            onSearch(criteria);
        }
    }

    const onSearch = async (searchParams: any) => {
        if(authen && authen.sessionUser){
            let resData = await getRequestDocumentListByParam(searchParams);
            if(resData.status){
                let requestDocumentList: RequestDocumentModel[] = resData.data;
                setDataList(requestDocumentList);
            }
        }
    }

    const openSearchDrawer = () => {
        setIsShowDrawer(true);
    }
    const onCloseDrawer = () => {
        setIsShowDrawer(false);
    }

    // const renderSearchContainer = () => {
    //     let searchContainer = (
    //         <RequestDocumentUploadListSearchSection
    //             onSearch={onSearch}
    //             userId={userId}
    //             hasSearchPermission={hasSearchPermission}
    //         />
    //     );
    //     // console.log('hasSearchPermission', hasSearchPermission)
    //     // if(true){
    //     return hasSearchPermission ? searchContainer : null;
    //     // }
    // }

    return (
        <div className={styles['main-container']}>            
            {/* {renderSearchContainer()} */}
            <RequestDocumentUploadListSearchSection
                onSearch={onSearch}
                userId={userId}
                hasSearchPermission={hasSearchPermission}
                isOpenDrawer={isShowDrawer}
                onCloseDrawer={onCloseDrawer}
            />
            <RequestDocumentUploadListTableSection 
                userId={userId}
                dataList={dataList}
                openAddModal={openAddModal}
                removeData={removeData}
                openSearchDrawer={openSearchDrawer}
                tablePageSize={10}
            />
            <AddNewRequestDocumentModal
                userId={userId}
                previewVisible={previewVisible}
                onOk={addNewReqDoc}
                onCancel={handleCancel}
                onDownload={handleDownloadTemplate}
            />
        </div>
    )
}

export default RequestDocumentUploadList;