const ApiConfig = {
    backendUrl: `/voss-web/php/controller`,
    publicUrl: `/voss-web`,
    uploadedAssets: `/voss-web/assets`

    // backendUrl: `http://localhost:8081/voss/php/controller`,
    // publicUrl: `http://localhost:8081/voss`,
    // uploadedAssets: `http://localhost:8081/voss/assets`

    // backendUrl: `https://fitguide360.com/voss-web/php/controller`,
    // publicUrl: `https://fitguide360.com/voss-web`,
    // uploadedAssets: `https://fitguide360.com/voss-web/php/assets`
    
    // backendUrl: `http://localhost/voss-web/php/controller`,
    // publicUrl: `http://localhost/voss-web`,
    // uploadedAssets: `http://localhost/voss-web/php/assets`

    // backendUrl: `http://34.142.204.72/voss-web/php/controller`,
    // publicUrl: `http://34.142.204.72/voss-web`,
    // uploadedAssets: `http://34.142.204.72/voss-web/php/assets`

}

export default ApiConfig;