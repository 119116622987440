import { CSSProperties, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { SessionUserState } from "../../../store/authen/authenSlice";
import Header from "./Header";
import PageHeader from "./PageHeader";
import SideNav from "./SideNav";
import './XtremeLayout.module.css';
import * as AuthenService from '../../../services/AuthenService';

const XtremeLayout = () => {
    const dispatch = useAppDispatch();
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const [preloadStyle, setPreloadStyle] = useState<CSSProperties>();
    const navigate = useNavigate();
    const [navigatePage, setNavigatePage] = useState<string>();

    useEffect(() => {
      if(navigatePage != null && navigatePage.trim() != ""){
        navigate(navigatePage, {replace: true});
      }
    },[navigatePage]);

    useEffect(() => {
        // (async () => {
        //     await AuthenService.authenFromLocalStorage(dispatch);
        // })();
        setTimeout(() => {
            setPreloadStyle({display: 'none'});
        }, 500);
    }, []);

    useEffect(() => {
       authenUser();
    }, [authen])

    const authenUser = async() => {
        console.log("authenUser");
        if(!authen.sessionUser){
            console.log("user unauthen");
            let userFromStorage = localStorage.getItem("voss-web-user");
            if(!userFromStorage){
                setNavigatePage("/frontend/login");
            }
        }else{
            console.log("user authened");
        }
    }

    const renderOutlet = () => {
        if(authen && authen.sessionUser){
            return (<Outlet/>)
        }
    }
    return (
        <>
            <div style={preloadStyle} className={"preloader"}>
                <div className={"lds-ripple"}>
                    <div className={"lds-pos"}></div>
                    <div className={"lds-pos"}></div>
                </div>
            </div>
            <div id="main-wrapper" data-layout="vertical" data-sidebartype="full"
                data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
                <Header />
                <aside className={"left-sidebar"} data-sidebarbg="skin6">
                    <div className={"scroll-sidebar"}>
                        <SideNav />
                    </div>
                </aside>
                <div className={"page-wrapper"}>
                    <PageHeader />
                    <div className={"container-fluid"}>
                        {renderOutlet()}
                    </div>
                    {/* <footer className={"footer text-center"}>
                        Footer */}
                        {/* <a href="https://go.camt.cmu.ac.th/index.php/th/" target="_blank"><img src="/assets/logo.jpg" style={{width: 24, height: 24, marginRight: 5}}/></a>
                        College of Arts Media and Technology Chiang Mai University
                        <a href="https://www.cmu.ac.th/" target="_blank"><img src="/assets/cmu_logo.png" style={{width: 24, height: 24, marginLeft: 5}}/></a> */}
                    {/* </footer> */}
                </div>
            </div>
        </>
    );
}

export default XtremeLayout;