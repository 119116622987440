import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { setBack2Page, setPageName } from "../../../../../store/pagination/paginationSlice";
import { DeleteTwoTone, EditTwoTone, EnvironmentTwoTone, InboxOutlined, PlusSquareTwoTone, QuestionCircleFilled } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, message, Modal, notification, Popconfirm, Select, Space, Table } from "antd";
import styles from './UserList.module.css';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import type { UploadFile } from 'antd/es/upload/interface';
import axios from "axios";
import ApiConfig from "../../../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { deleteUser, getRoleList, getUserByParam } from "../../../../../services/UserService";
import { SearchUser } from "../../../../../models/UserModel";
import * as DateTimeHelper from "../../../../../helper/DateTimeHelper"
import Role from "../../../../../models/Role";
import { ApiResponse, Faculty, FacultyBranch } from "../../../../../models/MasterModel";
import { getDepartment, getFaculty, getFacultyBranch, getMajor } from "../../../../../services/MasterService";
import { current } from "@reduxjs/toolkit";
import { addNewUser, authen, register } from "../../../../../services/AuthenService";
import { SessionUser, SessionUserState } from "../../../../../store/authen/authenSlice";

interface ISearchCriteria {
    firstname: string | null;
    lastname: string | null;
    major_id: number | null;
    branch_id: number | null;
    faculty_id: number | null;
    main_branch_id: number | null;
    role_id: number | null;
}
interface INewMember{
    student_code: string;
    email: string;
    password: string;
    confirm_password: string;
    firstname: string;
    lastname: string;
    faculty_id: string;
    branch_id: string;
    major_id: string;
    phone_no: string;
    operatorId: number;
}
const UserList = () => {
    const dispatch = useAppDispatch();
    const { RangePicker } = DatePicker;
    const {Option} = Select;
    const dateFormat = 'DD/MM/YYYY';
    let navigate = useNavigate();
    const [form] = Form.useForm();

    const [searchFromDate, setSearchFromDate] = useState();
    const [searchRefName, setSearchRefName] = useState();
    const [dataList, setDataList] = useState<SearchUser[]>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [previewTitle, setPreviewTitle] = useState<string>('');
    const [photoDate, setPhotoDate] = useState<any>(moment());
    const [refName, setRefName] = useState<string>("");
    const [userId, setUserId] = useState<number>(1);
    const [roleList, setRoleList] = useState<Role[]>([]);
    const [facultyComboList, setFacultyComboList] = useState<Faculty[]>([]);
    const [oriFacultyBranchComboList, setOriFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [filterFacultyBranchComboList, setFilterFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [oriMajorComboList, setOriMajorComboList] = useState<FacultyBranch[]>([]);
    const [filterMajorComboList, setFilterMajorComboList] = useState<FacultyBranch[]>([]);
    const [selectedMajor, setSelectedMajor] = useState<string>("ALL");

    const [searchName, setSearchName] = useState<string | undefined>();
    const [selectedFaculty, setSelectedFaculty] = useState<string>("ALL");
    const [selectedFacultyBranch, setSelectedFacultyBranch] = useState<string>("ALL");
    const [selectedRole, setSelectedRole] = useState<string>("ALL");

    const authen: SessionUserState = useAppSelector(state => state.authen);
    const [currentUser, setCurrentUser] = useState<SessionUserState>(authen);
    
    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'Email',
            sorter: (a: SearchUser, b: SearchUser) => a.email.localeCompare(b.email),
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: SearchUser) => {return `${data.firstname} ${data.lastname}`},
        },
        {
            title: 'ชื่อ - นามสกุล',
            // dataIndex: 'process_date',
            key: 'name',
            // sorter: (a: any, b: any) => a.age - b.age,
            sorter: (a: SearchUser, b: SearchUser) => `${a.firstname} ${a.lastname}`.localeCompare(`${b.firstname} ${b.lastname}`),
            render: (data: SearchUser) => {return `${data.firstname} ${data.lastname}`},
        },
        {
            title: 'วันที่เข้าร่วม',
            // dataIndex: 'process_date',
            key: 'entry_date',
            sorter: (a: SearchUser, b: SearchUser) => a.entry_date.localeCompare(b.entry_date),
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: SearchUser) => {return DateTimeHelper.convertDBDate2Show(data.entry_date)},
        },
        {
            title: 'คณะ',
            dataIndex: 'faculty_name',
            key: 'faculty_name',
            sorter: (a: SearchUser, b: SearchUser) => a.faculty_name.localeCompare(b.faculty_name),
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: 'สาขา',
            dataIndex: 'major_name',
            key: 'branch_name',
            sorter: (a: SearchUser, b: SearchUser) => a.major_name.localeCompare(b.major_name),
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: 'บทบาทหลัก',
            dataIndex: 'main_role_name',
            key: 'main_role_name',
            sorter: (a: SearchUser, b: SearchUser) => a.main_role_name.localeCompare(b.main_role_name),
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        
        {
          title: 'Action',
          key: 'action',
          render: (text: string, record: any) => (
            <Space size="middle">
                {/* <Link to={`/management/user/form/${record.id}`}><EnvironmentTwoTone /></Link> */}
                <Link to={`/management/user/form/${record.id}`}><EditTwoTone /></Link>
                <Popconfirm 
                    title="ต้องการลบข้อมูลผู้ใช้หรือไม่"
                    onCancel={() => {}}
                    onConfirm={() => {removeUser(record.id)}}
                    icon={<QuestionCircleFilled />}
                >
                    <DeleteTwoTone />
                </Popconfirm>
            </Space>
          ),
        },
    ]
    useEffect(() => {
        dispatch(setPageName({th: "รายการผู้ใช้งานในระบบ", en: "User List"}));
        dispatch(setBack2Page(null));
        loadRoleCombo();
        loadComboData();
    }, [])

    useEffect(() => {
        setCurrentUser(authen);
    }, [authen])

    const loadRoleCombo = async () => {
        let resData = await getRoleList();
        if(resData.status){
            setRoleList(resData.data);
        }
    }

    const loadComboData = async () => {
        let facultyList = await getFaculty();
        // let facultyBranchList = await getFacultyBranch();
        let departmentList = await getDepartment();
        let majorList = await getMajor();
        setFacultyComboList(facultyList);
        setOriFacultyBranchComboList(departmentList);
        setFilterFacultyBranchComboList([...departmentList]);
        setOriMajorComboList(majorList);
        setFilterMajorComboList([...majorList]);
    }
    const onFacultyChange = (value: string) => {
        console.log("onFacultyChange: ", value);
        setSelectedFaculty(value);
        setSelectedFacultyBranch("ALL");
        setSelectedMajor("ALL");
        if(value == "ALL"){
            setFilterFacultyBranchComboList([...oriFacultyBranchComboList]);
            setFilterMajorComboList([...oriMajorComboList]);
            return;
        }
        const facultyId: number = Number(value);
        let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
            return branch.faculty_id == facultyId || branch.id == -1;
        });
        setFilterFacultyBranchComboList(filterBranch);

        let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
            return branch.faculty_id == facultyId;
        });
        setFilterMajorComboList(filterMajor);
    }

    const onFacultyBranchChange = (value: string) => {
        console.log("onFacultyBranchChange: ", value);
        setSelectedFacultyBranch(value);
        setSelectedMajor("ALL");
        const facultyBranchId: number = Number(value);
        let currentFaculty = -1;
        if(selectedFaculty != "ALL"){
            currentFaculty = Number(selectedFaculty);
        }
        let facultyBranchItem: FacultyBranch | undefined = oriFacultyBranchComboList.find((value) => {return value.id == facultyBranchId});
        if(facultyBranchItem){
            const facultyBranchItemValue = facultyBranchItem;
            if(facultyBranchItemValue.faculty_id > 0){
                setSelectedFaculty("" + facultyBranchItemValue.faculty_id);
            }
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == facultyBranchItemValue.faculty_id || currentFaculty == -1|| branch.id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == facultyBranchItemValue.id && (currentFaculty == -1 || currentFaculty == branch.faculty_id);
            });
            setFilterMajorComboList(filterMajor);
        }
        // setFilterFacultyBranchComboList(filterBranch);
    }

    const onMajorChange = (value: string) => {
        console.log("onMajorChange: ", value);
        setSelectedMajor(value);
        const majorId: number = Number(value);
        let majorItem: FacultyBranch | undefined = oriMajorComboList.find((value) => {return value.id == majorId});
        if(majorItem){
            const majorItemValue = majorItem;
            setSelectedFaculty("" + majorItemValue.faculty_id);
            setSelectedFacultyBranch("" + majorItemValue.department_id);
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == majorItemValue.faculty_id || branch.id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == majorItemValue.department_id && branch.faculty_id == majorItemValue.faculty_id;
            });
            setFilterMajorComboList(filterMajor);
        }
    }
    const removeUser = (userId: number) => {
        console.log("Delete user: ", userId);
        deleteUser(userId).then(() => {
            loadUserList();
        });
    }

    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const handleCancel = () => {setPreviewVisible(false)};
    const refNameChange = (e: any) => {
        setRefName(e.target.value);
    }
    const openAddModal = () => {
        setPreviewVisible(true);
    }
    const loadUserList = async () => {
        let firstname = null;
        let lastname = null;
        let facultyId: number | null = null;
        let branchId: number | null = null;
        let majorId: number | null = null;
        let roleId: number | null = null;
        if(searchName && searchName.trim() != ""){
            let splitSearchName = searchName.split(" ");
            if(splitSearchName && splitSearchName.length > 0){
                firstname = splitSearchName[0].trim();
                if(splitSearchName.length > 1){
                    lastname = splitSearchName[1].trim();
                }
            }
        }
        if(selectedFaculty && selectedFaculty.trim() !== "ALL"){
            facultyId = Number(selectedFaculty);
        }
        if(selectedFacultyBranch && selectedFacultyBranch.trim() !== "ALL"){
            branchId = Number(selectedFacultyBranch);
        }
        if(selectedMajor && selectedMajor.trim() !== "ALL"){
            majorId = Number(selectedMajor);
        }
        if(selectedRole && selectedRole.trim() !== "ALL"){
            roleId = Number(selectedRole);
        }
        let searchCriteria: ISearchCriteria = {
            firstname: firstname,
            lastname: lastname,
            faculty_id: facultyId,
            branch_id: branchId,
            major_id: majorId,
            main_branch_id: null,
            role_id: roleId
        }
        console.log("search: ", searchCriteria);
        let resData = await getUserByParam(searchCriteria);
        if(resData.status){
            let userList: SearchUser[] = resData.data;
            setDataList(userList)
        }
    }
    const onSearch = async () => {
        await loadUserList();
    }
    const submitData = async (value: {email: string, firstname: string, lastname: string}) => {
        if(currentUser != null && currentUser.sessionUser != null){
            let emailName = value.email.split("@")[0];
            let generatePassword = `voss@cu-${emailName}`
            let newMemberData: INewMember = {
                email: value.email,
                firstname: value.firstname,
                lastname: value.lastname,
    
                password: generatePassword,
                confirm_password: generatePassword,
    
                branch_id: "-1",
                faculty_id: "-1",
                major_id: "-2",
                student_code: "",
                phone_no: "",
                operatorId: currentUser.sessionUser.currentUser.id,
            }
            addNewMember(newMemberData)
        }
    }
    const addNewMember = async (newMemberData: INewMember) => {
        console.log('addNewMember:', newMemberData);
        let resData: ApiResponse = await addNewUser(newMemberData);
        console.log("addNewMember response: ", resData);
        if(resData.status){
            notification.success({message: "เพิ่มผู้ใช้สำเร็จ", description: resData.message})
            navigate(`/management/user/form/${resData.data.userProfile.login_id}`)
            // navigate("/frontend/login");
        }else{
            notification.error({message: "เพิ่มผู้ใช้ไม่สำเร็จ", description: resData.message})
        }
        
        // navigate('/management/user/form/1')
    }

    // const getTransactionList = async () => {
    //     let searchCriteria = {
    //         fromPrecessDate: "20220501",
    //         toProcessDate: "20220531",
    //     }
    //     let res = await axios.post(`${ApiConfig.backendUrl}/getTransactionList`, searchCriteria,{
    //         headers: {
    //           'Access-Control-Allow-Origin': '*',
    //           'Access-Control-Allow-Headers': '*',
    //           'Access-Control-Allow-Credentials': 'true'
    //         }
    //       });
    //     if(res.status == 200){
    //         let resData = res.data;
    //         console.log("dataList: ", resData);
    //         return resData.data;
    //     }else if(res.status == 500){
    //         return data;
    //     }
    // }


    // const renderFacultyBranchOption = () => {
    //     let optionList = [];
    //     for(let i = 0; i < filterFacultyBranchComboList.length; i++){
    //         let branch = filterFacultyBranchComboList[i];
    //         optionList.push(<Option value={`${branch.id}`}>{branch.branch_name}</Option>);
    //     }
    //     return optionList;
    // }
    const renderRoleList = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกบทบาท</Option>);
        for(let i = 0; i < roleList.length; i++){
            let role = roleList[i];
            optionList.push(<Option value={`${role.id}`}>{role.role_name}</Option>);
        }
        return optionList;
    }
    
    const renderFacultyOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกคณะ</Option>);
        for(let i = 0; i < facultyComboList.length; i++){
            let fac = facultyComboList[i];
            optionList.push(<Option key={fac.id} value={`${fac.id}`}>{fac.faculty_name}</Option>);
        }
        return optionList;
    }

    const renderFacultyBranchOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกภาควิชา</Option>);
        for(let i = 0; i < filterFacultyBranchComboList.length; i++){
            let branch = filterFacultyBranchComboList[i];
            optionList.push(<Option key={branch.id} value={`${branch.id}`}>{branch.branch_name}</Option>);
        }
        return optionList;
    }
    const renderMajorOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกสาขา</Option>);
        for(let i = 0; i < filterMajorComboList.length; i++){
            let maj = filterMajorComboList[i];
            optionList.push(<Option key={maj.id} value={`${maj.id}`}>{maj.branch_name}</Option>);
        }
        return optionList;
    }
    return (
        <div className={styles['main-container']}>
            <div className={styles['search-container']}>
                <div className={styles['search-component']}>
                    <Input type={"text"} placeholder="ชื่อ - สกุล" value={searchName} onChange={(e:any) => {setSearchName(e.target.value)}}/>
                </div>
                <div className={styles['search-component']}>
                    <div className={styles['input-line']}>
                        <Select
                            showSearch
                            placeholder="เลือกคณะ"
                            optionFilterProp="children"
                            onChange={onFacultyChange}
                            // onSearch={onSearch}
                            value={selectedFaculty}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            className={styles['select-full']}
                        >
                            {renderFacultyOption()}
                        </Select>
                    </div>
                </div>
                <div className={styles['search-component']}>
                    <div className={styles['input-line']}>
                        <Select
                            showSearch
                            placeholder="เลือกภาควิชา"
                            optionFilterProp="children"
                            className={styles['select-half']}
                            style={{marginRight: 4}}
                            value={selectedFacultyBranch}
                            onChange={onFacultyBranchChange}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {renderFacultyBranchOption()}
                        </Select>
                        <Select
                            showSearch
                            placeholder="เลือกสาขาวิชา"
                            optionFilterProp="children"
                            onChange={onMajorChange}
                            // onSearch={onSearch}
                            value={selectedMajor}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            className={styles['select-half']}
                        >
                            {renderMajorOption()}
                        </Select>
                    </div>
                </div>
                {/* <div className={styles['search-component']}>
                    <div className={styles['input-line']}>
                        <Select
                            showSearch
                            placeholder="เลือกสาขาวิชา"
                            optionFilterProp="children"
                            // onChange={onChange}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            className={styles['select-full']}
                        >
                            {renderFacultyBranchOption()}
                        </Select>
                    </div>
                </div> */}
                <div className={styles['search-component']}>
                    <div className={styles['input-line']}>
                        <Select
                            showSearch
                            placeholder="เลือกบทบาท"
                            optionFilterProp="children"
                            onChange={(value) => {setSelectedRole(value)}}
                            // onSearch={onSearch}
                            value={selectedRole}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            className={styles['select-full']}
                        >
                            {renderRoleList()}
                        </Select>
                    </div>
                </div>
                {/* <div className={styles['search-component']}>
                    <span style={{marginRight: 5}}>วันที่อัปโหลด: </span>
                    <RangePicker
                        defaultValue={[moment(), moment()]}
                        format={dateFormat}
                    />
                </div> */}
                {/* <div className={styles['search-component']}>
                    <Input type={"text"} placeholder={"ชื่ออ้างอิง"} />
                </div> */}
                <div className={styles['search-component-submit']}>
                    <Button type="primary" style={{marginRight: 5}} onClick={onSearch}>ค้นหา</Button>
                    <Button type="default">Reset</Button>
                </div>
            </div>
            <div className={styles['list-container']}>
                <div className={styles['button-container']}>
                    <Button type={'primary'} onClick={() => {openAddModal()}}>เพิ่มผู้ใช้ใหม่</Button>
                </div>
                <Table columns={columns} dataSource={dataList} onChange={onChange}/>
            </div>
            <Modal
                visible={previewVisible}
                title={"เพิ่มผู้ใช้ใหม่"}
                onCancel={() => {handleCancel()}}
                onOk={() => {form.submit()}}
                okText={"เพิ่มผู้ใช้ใหม่"}
            >
                <Form
                    form={form}
                    className={styles['modal-main-container']}
                    onFinish={submitData}
                >
                    <Form.Item 
                        name={'email'} 
                        className={styles['input-container']}
                        rules={[{ required: true, message: 'กรุณากรอกอีเมล์!' }, {type: "email", message: 'รูปแบบ Email ไม่ถูกต้อง'}]}
                    >
                        <Input type={"text"} placeholder="Email" />
                    </Form.Item>
                    <div className={styles['input-container']}>
                        <Form.Item 
                            name={'firstname'}
                            style={{marginRight: 2, width: '50%'}}
                            rules={[{ required: true, message: 'กรุณากรอกชื่อผู้ใช้!' }]}
                        >
                            <Input type={"text"} placeholder="ชื่อ" />
                        </Form.Item>
                        <Form.Item 
                            name={'lastname'}
                            style={{marginLeft: 2, width: '50%'}}
                            rules={[{ required: true, message: 'กรุณากรอกนามสกุลผู้ใช้!' }]}
                        >
                            <Input type={"text"} placeholder="นามสกุล" />
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default UserList;