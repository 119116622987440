import type { DatePickerProps } from 'antd';
import { Dayjs } from 'dayjs';
export const convertDBDate2Show = (date:string) => {
    if(!date) {
        return ""
    }
    let year = date.substring(0, 4);
    let month = date.substring(4, 6);
    let day = date.substring(6, 8);

    return `${day}/${month}/${year}`;
}

export const convertDBTime2Show = (time:string) => {
    if(!time) {
        return ""
    }
    let hour = time.substring(0, 2);
    let min = time.substring(2, 4);
    let sec = time.substring(4, 6);

    return `${hour}:${min}:${sec}`;
}


export const datePickerFormat: DatePickerProps['format'] = (value) => {
    let date = value.date();
    let month = value.month() + 1;
    let year = value.year() + 543;
    let monthStr = month < 10 ? `0${month}` : `${month}`
    return `${year}-${monthStr}-${date}`;
}

export const datePickerYearFormat: DatePickerProps['format'] = (value) => {
    let year = value.year() + 543;
    return `${year}`;
}