import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../store";
import { setBack2Page, setPageName } from "../../../../store/pagination/paginationSlice";
import { DeleteTwoTone, EditTwoTone, EnvironmentTwoTone, InboxOutlined, PlusSquareTwoTone } from "@ant-design/icons";
import { Button, DatePicker, Input, message, Modal, Select, Space, Table } from "antd";
import styles from './ActivityLog.module.css';
import moment, {Moment} from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import type { UploadFile } from 'antd/es/upload/interface';
import axios from "axios";
import ApiConfig from "../../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { getActivityLog, getActivityLogSingleText } from "../../../../services/ActivityLogService";
import { IActivityLog } from "../../../../models/Activity";
import { convertDBDate2Show, convertDBTime2Show } from "../../../../helper/DateTimeHelper";
import { DocumentModel, Faculty, FacultyBranch, MasterModuleRef } from "../../../../models/MasterModel";
import { getDepartment, getDocumentList, getFaculty, getMajor, getModuleList } from "../../../../services/MasterService";
import ActivityLogSearchSection from "./SearchSection/ActivityLogSearchSection";
import Search, { SearchProps } from "antd/lib/input/Search";

const ActivityLog = () => {
    const dispatch = useAppDispatch();
    const { RangePicker } = DatePicker;
    const { Option } = Select;
    const dateFormat = 'DD/MM/YYYY';
    let navigate = useNavigate();

    const [searchFromDate, setSearchFromDate] = useState<Moment | null>(null);
    const [searchToDate, setSearchToDate] = useState<Moment | null>(null);
    const [searchRefName, setSearchRefName] = useState();
    const [dataList, setDataList] = useState<IActivityLog[]>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [searchName, setSearchName] = useState<string | undefined>();

    const [selectedDocument, setSelectedDocument] = useState<string>("ALL");
    const [documentComboList, setDocumentComboList] = useState<DocumentModel[]>([]);
    
    const [schoolYear, setSchoolYear] = useState<string>("");

    const [facultyComboList, setFacultyComboList] = useState<Faculty[]>([]);
    const [oriFacultyBranchComboList, setOriFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [filterFacultyBranchComboList, setFilterFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [oriMajorComboList, setOriMajorComboList] = useState<FacultyBranch[]>([]);
    const [filterMajorComboList, setFilterMajorComboList] = useState<FacultyBranch[]>([]);
    const [oriModuleComboList, setOriModuleComboList] = useState<MasterModuleRef[]>([]);
    // const [filterModuleComboList, setFilterModuleComboList] = useState<MasterModuleRef[]>([]);
    const [selectedMajor, setSelectedMajor] = useState<string>("ALL");
    const [selectedFaculty, setSelectedFaculty] = useState<string>("ALL");
    const [selectedFacultyBranch, setSelectedFacultyBranch] = useState<string>("ALL");
    const [selectedStatus, setSelectedStatus] = useState<string>("ALL");
    const [selectedModule, setSelectedModule] = useState<string>("ALL");
    
    const columns = [
        {
            title: 'ชื่อผู้ใช้',
            // dataIndex: 'process_date',
            key: 'name',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: IActivityLog) => {return (data.firstname == null ? "" : data.firstname) + " " + (data.lastname == null ? "" : data.lastname)},
        },
        // {
        //     title: 'บทบาท',
        //     dataIndex: 'process_date',
        //     key: 'process_date',
        //     // sorter: (a: any, b: any) => a.age - b.age,
        //     // render: (data: any) => {data.age},
        // },
        {
          title: 'ประเภท Log',
          dataIndex: 'log_type',
          key: 'log_type',
        },
        {
            title: 'วัน เวลาที่บันทึก',
            key: 'log_date_time',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: IActivityLog) => {return convertDBDate2Show(data.log_date) + " " + convertDBTime2Show(data.log_time)},
        },
        {
            title: 'ข้อความ',
            dataIndex: 'message',
            key: 'message',
            // sorter: (a: ProcessTransaction, b: ProcessTransaction) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
    ]
    useEffect(() => {
        // loadComboData();
        dispatch(setPageName({th: "Activity Log", en: "Activity Log"}));
        dispatch(setBack2Page(null));
    }, [])

    
    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        // console.log('params', pagination, filters, sorter, extra);
    }
    const handleCancel = () => {setPreviewVisible(false)};
    const openAddModal = () => {
        setPreviewVisible(true);
    }
    // const onReset = () => {
    //     setSearchName('');
    //     setSchoolYear('');
    //     setSelectedFaculty('ALL');
    //     setSelectedFacultyBranch('ALL');
    //     setSelectedMajor('ALL');
    //     setSelectedStatus('ALL');
    //     setSelectedDocument('ALL');
    //     setSelectedModule('ALL');
    //     loadActivityLog();
    // }
    const loadActivityLog = async (searchCriteria: any) => {    
        let resData = await getActivityLog(searchCriteria);
        if(resData.status){
            setDataList(resData.data);
        }
    }
    const onSingleTextSearch = async (value: any) => {
        console.log("onSingleTextSearch", value)
        let resData = await getActivityLogSingleText(value);
        if(resData.status){
            setDataList(resData.data);
        }
    };
    return (
        <div className={styles['main-container']}>
            {/* <ActivityLogSearchSection 
                loadActivityLog={loadActivityLog}
            /> */}
            <div className={styles['list-container']}>
                <div className={styles['button-container']}> 
                    <Search 
                        style={{width: 400}}
                        placeholder="คำค้นหา" 
                        onSearch={onSingleTextSearch} 
                        enterButton 
                    />
                </div>

                <Table columns={columns} dataSource={dataList} onChange={onChange}/>
            </div>
        </div>
    )
}

export default ActivityLog;