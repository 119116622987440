import { useEffect, useState  } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { setPageName, setBack2Page } from "../../../../../store/pagination/paginationSlice";
import { Button, DatePicker, Form, Input, message, Modal, notification, Select, Spin, Table, Upload } from "antd";
import { CloudUploadOutlined, DownloadOutlined, InboxOutlined, MailOutlined, PlusOutlined, RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import axios from "axios";
import styles from './UserForm.module.css';
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import type { UploadFile } from 'antd/es/upload/interface';
import ApiConfig from "../../../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { ApiResponse, Faculty, FacultyBranch } from "../../../../../models/MasterModel";
import { getDepartment, getFaculty, getFacultyBranch, getMajor } from "../../../../../services/MasterService";
import { getRoleList, getUserById, updateUserDataByAdmin } from "../../../../../services/UserService";
import Role from "../../../../../models/Role";
import { SessionUserState } from "../../../../../store/authen/authenSlice";
import { sendNewPassword2Email } from "../../../../../services/AuthenService";
interface IUserData{
    student_code?: string;
    email: string;
    firstname: string;
    lastname: string;
    firstname_en: string;
    lastname_en: string;
    faculty_id: string | undefined;
    branch_id: string | undefined;
    major_id: string | undefined;
    phone_no: string;
    main_role: string | undefined;
    sub_roles: string[] | undefined;
}
interface IUpdateUserData{
    id: number
    student_code?: string;
    firstname: string;
    lastname: string;
    firstname_en: string;
    lastname_en: string;
    faculty_id: number;
    branch_id: number;
    major_id: number;
    phone_no: string;
    main_role: number;
    sub_roles: number[];
    admin_id: number;
}
const UserForm = () => {
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const dispatch = useAppDispatch();
    const { Option } = Select;
    const { id } = useParams<string>();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [navigatePage, setNavigatePage] = useState<string>();
    const [facultyComboList, setFacultyComboList] = useState<Faculty[]>([]);
    const [oriFacultyBranchComboList, setOriFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [filterFacultyBranchComboList, setFilterFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [oriMajorComboList, setOriMajorComboList] = useState<FacultyBranch[]>([]);
    const [filterMajorComboList, setFilterMajorComboList] = useState<FacultyBranch[]>([]);
    const [selectedMajor, setSelectedMajor] = useState<string>("ALL");
    const [roleList, setRoleList] = useState<Role[]>([]);
    const [disableData, setDisableData] = useState<boolean>(true);
    const [isLoding, setIsLoading] = useState<boolean>(true);
    const [isShowStudentCode, setIsShowStudentCode] = useState<boolean>(false);
    // const [loadedImageList, setLoadedImageList] = useState<TransactionMainImage[]>([]);

    useEffect(() => {
        dispatch(setPageName({th: "โปรไฟล์ผู้ใช้", en: "User Profile"}));
        dispatch(setBack2Page("/management/user"));
        loadComboData();
        loadRoleCombo();
        loadUserData();
        setDisableData(false);
        setIsLoading(false);
        // setFileList(mockFileList)
    }, [])

    useEffect(() => {
      if(navigatePage != null && navigatePage.trim() != ""){
        navigate(navigatePage, {replace: true});
      }
    },[navigatePage]);

    const loadUserData = async () => {
        let resData = await getUserById(Number(id));
        if(resData.status){
            let userData: IUserData = resData.data;
            let subRoleModel = resData.data.sub_roles;
            userData.faculty_id = userData.faculty_id == undefined ? undefined : "" + userData.faculty_id
            userData.branch_id = userData.branch_id == undefined ? undefined : "" + userData.branch_id
            userData.major_id = userData.major_id == undefined ? undefined : "" + userData.major_id
            userData.main_role = userData.main_role == undefined ? undefined : "" + userData.main_role
            // userData.sub_roles = [];
            let subRoles = [];
            if(subRoleModel && subRoleModel.length > 0){
                for(let i = 0; i < subRoleModel.length; i++){
                    let subRole = subRoleModel[i];
                    subRoles.push("" + subRole.role_id);
                }
            }
            setIsShowStudentCode(userData.main_role == "-3");
            userData.sub_roles = subRoles;
            form.setFieldsValue(userData);
        }
    }

    const loadRoleCombo = async () => {
        let resData = await getRoleList();
        if(resData.status){
            setRoleList(resData.data);
        }
    }
    const loadComboData = async () => {
        let facultyList = await getFaculty();
        // let facultyBranchList = await getFacultyBranch();
        let departmentList = await getDepartment();
        let majorList = await getMajor();
        setFacultyComboList(facultyList);
        setOriFacultyBranchComboList(departmentList);
        setFilterFacultyBranchComboList([...departmentList]);
        setOriMajorComboList(majorList);
        setFilterMajorComboList([...majorList]);
    }

    const onFinish = async (values: IUserData) => {
        console.log('Success:', values);
        let admin_id = 0;
        let student_code = values.student_code;
        let branch_id = Number(values.branch_id);
        let faculty_id = Number(values.faculty_id);
        let major_id = Number(values.major_id);
        let firstname = values.firstname;
        let lastname = values.lastname;
        let firstname_en = values.firstname;
        let lastname_en = values.lastname;
        let phone_no = values.phone_no;
        let main_role = Number(values.main_role);
        let subRoles: number[] = [];
        if(authen && authen.sessionUser){
            admin_id = authen.sessionUser.currentUser.id;
        }
        if(values.sub_roles && values.sub_roles.length > 0){
            let subRoleFields = values.sub_roles;
            for(let i = 0; i < subRoleFields.length; i++){
                let subRoleField = subRoleFields[i];
                subRoles.push(Number(subRoleField));
            }
        }
        let updateUserData: IUpdateUserData = {
            id: Number(id),
            student_code: student_code,
            admin_id: admin_id,
            branch_id: branch_id,
            faculty_id: faculty_id,
            major_id: major_id,
            firstname: firstname,
            lastname: lastname,
            firstname_en: firstname_en,
            lastname_en: lastname_en,
            phone_no: phone_no,
            main_role: main_role,
            sub_roles: subRoles
        }
        // console.log('updateUserData:', updateUserData);
        // console.log('updateUserDataStr:', JSON.stringify(updateUserData));
        let resData: ApiResponse = await updateUserDataByAdmin(updateUserData);
        if(resData.status){
            notification.success({message: "แก้ไขข้อมูลสำเร็จ", description: resData.message})
            setNavigatePage("/management/user");
        }else{
            notification.error({message: "แก้ไขข้อมูลไม่สำเร็จ", description: resData.message})
        }
    };
  
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const onFacultyChange = (value: string) => {
        console.log("onFacultyChange: ", value);
        const facultyId: number = Number(value);
        let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
            return branch.faculty_id == facultyId;
        });
        form.setFieldsValue({branch_id: "" + filterBranch[0].id});
        setFilterFacultyBranchComboList(filterBranch);
    }

    const onFacultyBranchChange = (value: string) => {
        console.log("onFacultyChange: ", value);
        const facultyBranchId: number = Number(value);
        let facultyBranchItem: FacultyBranch | undefined = oriFacultyBranchComboList.find((value) => {return value.id == facultyBranchId});
        if(facultyBranchItem){
            const facultyBranchItemValue = facultyBranchItem;
            form.setFieldsValue({faculty_id: "" + facultyBranchItem.faculty_id});
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == facultyBranchItemValue.faculty_id;
            });
            setFilterFacultyBranchComboList(filterBranch);
        }
        console.log("onFacultyBranchChange field value: ", form.getFieldsValue());
        // setFilterFacultyBranchComboList(filterBranch);
    }

    const onMajorChange = (value: string) => {
        console.log("onMajorChange: ", value);
        setSelectedMajor(value);
        const majorId: number = Number(value);
        let majorItem: FacultyBranch | undefined = oriMajorComboList.find((value) => {return value.id == majorId});
        if(majorItem){
            const majorItemValue = majorItem;
            // setSelectedFaculty("" + majorItemValue.faculty_id);
            // setSelectedFacultyBranch("" + majorItemValue.department_id);
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == majorItemValue.faculty_id || branch.id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == majorItemValue.department_id && branch.faculty_id == majorItemValue.faculty_id;
            });
            setFilterMajorComboList(filterMajor);
        }
    }
    const renderFacultyOption = () => {
        let optionList = [];
        for(let i = 0; i < facultyComboList.length; i++){
            let fac = facultyComboList[i];
            optionList.push(<Option value={`${fac.id}`}>{fac.faculty_name}</Option>);
        }
        return optionList;
    }
    const renderMajorOption = () => {
        let optionList = [];
        // optionList.push(<Option value={`-1`}>ไม่สังกัด</Option>);
        for(let i = 0; i < filterMajorComboList.length; i++){
            let maj = filterMajorComboList[i];
            optionList.push(<Option value={`${maj.id}`}>{maj.branch_name}</Option>);
        }
        return optionList;
    }

    const renderFacultyBranchOption = () => {
        let optionList = [];
        // optionList.push(<Option value={`-1`}>ไม่สังกัด</Option>);
        for(let i = 0; i < filterFacultyBranchComboList.length; i++){
            let branch = filterFacultyBranchComboList[i];
            optionList.push(<Option value={`${branch.id}`}>{branch.branch_name}</Option>);
        }
        return optionList;
    }
    const renderRoleList = () => {
        let optionList = [];
        for(let i = 0; i < roleList.length; i++){
            let role = roleList[i];
            optionList.push(<Option value={`${role.id}`}>{role.role_name}</Option>);
        }
        return optionList;
    }
    const formItemStyle = {marginBottom: 10};
    
    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const sendNewPassword = async () => {
        let resData = await sendNewPassword2Email(Number(id));
        if(resData.status){
            notification.success({message: "ส่งรหัสผ่านใหม่สำเร็จ"})
        }
    }
    const renderBtn = () => {
        if(!disableData){
            return (
                <div className={styles['input-container']}>
                    <Button style={{marginRight: 5}} type={"primary"} icon={<SaveOutlined />} htmlType="submit">บันทึกข้อมูล</Button>
                    <Button style={{marginRight: 5}} type={"default"} icon={<MailOutlined />} onClick={() => {
                        setIsLoading(true);
                        sendNewPassword().then(() => {
                            setIsLoading(false);
                        })
                    }}>ส่งรหัสผ่านอีเมล์</Button>
                </div>
            )
        }
    }
    return (
        <div className={styles['main-container']}>
            <div tabIndex={-1} className={`ant-modal-wrap ${styles['loading']}`} style={{display: isLoding ? 'flex' : 'none'}}>
                <Spin size="large" />
            </div>
            <div className={styles['data-container']}>
                {/* <div className={styles['input-container-submit']} style={{justifyContent: 'flex-end', paddingRight: 10}}>
                    <Button style={{marginRight: 5}} type={"primary"} icon={<SaveOutlined />}>บันทึกข้อมูล</Button>
                </div> */}
                    
                    <Form
                        form={form}
                        name="register"
                        // labelCol={{ span: 8 }}
                        // wrapperCol={{ span: 16 }}
                        style={{width: '80%'}}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        {
                            isShowStudentCode && 
                            <Form.Item
                                name="student_code"
                                rules={[{ required: true, message: 'กรุณากรอกรหัสนักศึกษา!' }]}
                                style={formItemStyle}
                            >
                                <Input placeholder="รหัสนักศึกษา" type={"text"} disabled={disableData} />
                            </Form.Item> 
                        }
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'กรุณากรอกอีเมล์!' }, {type: "email", message: 'รูปแบบ Email ไม่ถูกต้อง'}]}
                            style={formItemStyle}
                        >
                            <Input placeholder="Email*" type={"text"} disabled/>
                        </Form.Item>
                        <div className={styles['input-container']}>
                            <Form.Item
                                name="firstname"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อ!' }]}
                                // className={styles['half']} 
                                style={{...formItemStyle, marginRight: 4, width: '100%'}}
                            >
                                <Input placeholder="ชื่อ*" type={"text"} disabled={disableData} />
                            </Form.Item>
                            <Form.Item
                                name="lastname"
                                rules={[{ required: true, message: 'กรุณากรอกนามสกุล!' }]}
                                style={{...formItemStyle, width: '100%'}}
                            >
                                <Input placeholder="นามสกุล*" type={"text"} disabled={disableData}/>
                            </Form.Item>
                        </div>
                        <div className={styles['input-container']}>
                            <Form.Item
                                name="firstname_en"
                                rules={[{ required: true, message: 'กรุณากรอกชื่อ!' }]}
                                // className={styles['half']} 
                                style={{...formItemStyle, marginRight: 4, width: '100%'}}
                            >
                                <Input placeholder="firstname*" type={"text"} disabled={disableData} />
                            </Form.Item>
                            <Form.Item
                                name="lastname_en"
                                rules={[{ required: true, message: 'กรุณากรอกนามสกุล!' }]}
                                style={{...formItemStyle, width: '100%'}}
                            >
                                <Input placeholder="lastname*" type={"text"} disabled={disableData}/>
                            </Form.Item>
                        </div>
                        <Form.Item
                            name="faculty_id"
                            rules={[{ required: true, message: 'กรุณาเลือกคณะที่สังกัด!' }]}
                            style={{...formItemStyle}}
                        >
                            <Select
                                showSearch
                                placeholder="เลือกคณะ*"
                                optionFilterProp="children"
                                onChange={onFacultyChange}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                                className={styles['select-half']}
                                style={{marginRight: 4}}
                                disabled={disableData}
                            >
                                {renderFacultyOption()}
                            </Select>
                        </Form.Item>
                        <div className={styles['input-container']} style={{marginBottom: 0}}>
                            
                            <Form.Item
                                name="branch_id"
                                rules={[{ required: true, message: 'กรุณาเลือกภาควิชาที่สังกัด!' }]}
                                style={{...formItemStyle, marginRight: 4, width: '50%'}}
                            >
                                <Select
                                    showSearch
                                    placeholder="เลือกภาควิชา*"
                                    optionFilterProp="children"
                                    className={styles['select-half']}
                                    onChange={onFacultyBranchChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                    disabled={disableData}
                                >
                                    {renderFacultyBranchOption()}
                                </Select>
                            </Form.Item>
                            
                            <Form.Item
                                name="major_id"
                                rules={[{ required: true, message: 'กรุณาเลือกสาขาวิชาที่สังกัด!' }]}
                                style={{...formItemStyle, width: '50%'}}
                            >
                                <Select
                                    showSearch
                                    placeholder="เลือกสาขาวิชา"
                                    optionFilterProp="children"
                                    onChange={onMajorChange}
                                    // onSearch={onSearch}
                                    value={selectedMajor}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                    className={styles['select-half']}
                                >
                                    {renderMajorOption()}
                                </Select>
                            </Form.Item>
                        </div>
                        
                        <Form.Item
                            name="phone_no"
                            rules={[{ required: true, message: 'กรุณากรอกเบอร์โทรศัพท์' }]}
                            className={styles['half']}
                            style={{...formItemStyle}}
                        >
                            <Input placeholder="เบอร์โทรศัพท์" type={"text"} value={confirmPassword} disabled={disableData}/>
                        </Form.Item>
                        <Form.Item
                            name="main_role"
                            rules={[{ required: true, message: 'กรุณาเลือกบทบาทหลัก!' }]}
                            style={{...formItemStyle, width: '100%'}}
                        >
                            <Select
                                // mode="multiple"
                                showSearch
                                placeholder="เลือกบทบาทหลัก"
                                optionFilterProp="children"
                                className={styles['select-full']}
                                onChange={(val) => {setIsShowStudentCode(val == "-3")}}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                                disabled={disableData}
                            >
                                {renderRoleList()}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="sub_roles"
                            // rules={[{ required: true, message: 'กรุณาเลือกสาขาวิชาที่สังกัด!' }]}
                            style={{...formItemStyle, width: '100%'}}
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="เลือกบทบาทเสริม"
                                optionFilterProp="children"
                                className={styles['select-full']}
                                // onChange={onChange}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                                disabled={disableData}
                            >
                                {renderRoleList()}
                            </Select>
                        </Form.Item>
                        {renderBtn()}
                    </Form>
            </div>
        </div>
    )
}

export default UserForm;