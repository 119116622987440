import frame001	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-001.jpg';
import frame002	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-002.jpg';
import frame003	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-003.jpg';
import frame004	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-004.jpg';
import frame005	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-005.jpg';
import frame006	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-006.jpg';
import frame007	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-007.jpg';
import frame008	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-008.jpg';
import frame009	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-009.jpg';
import frame010	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-010.jpg';
import frame011	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-011.jpg';
import frame012	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-012.jpg';
import frame013	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-013.jpg';
import frame014	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-014.jpg';
import frame015	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-015.jpg';
import frame016	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-016.jpg';
import frame017	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-017.jpg';
import frame018	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-018.jpg';
import frame019	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-019.jpg';
import frame020	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-020.jpg';
import frame021	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-021.jpg';
import frame022	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-022.jpg';
import frame023	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-023.jpg';
import frame024	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-024.jpg';
import frame025	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-025.jpg';
import frame026	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-026.jpg';
import frame027	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-027.jpg';
import frame028	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-028.jpg';
import frame029	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-029.jpg';
import frame030	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-030.jpg';
import frame031	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-031.jpg';
import frame032	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-032.jpg';
import frame033	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-033.jpg';
import frame034	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-034.jpg';
import frame035	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-035.jpg';
import frame036	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-036.jpg';
import frame037	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-037.jpg';
import frame038	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-038.jpg';
import frame039	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-039.jpg';
import frame040	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-040.jpg';
import frame041	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-041.jpg';
import frame042	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-042.jpg';
import frame043	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-043.jpg';
import frame044	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-044.jpg';
import frame045	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-045.jpg';
import frame046	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-046.jpg';
import frame047	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-047.jpg';
import frame048	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-048.jpg';
import frame049	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-049.jpg';
import frame050	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-050.jpg';
import frame051	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-051.jpg';
import frame052	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-052.jpg';
import frame053	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-053.jpg';
import frame054	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-054.jpg';
import frame055	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-055.jpg';
import frame056	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-056.jpg';
import frame057	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-057.jpg';
import frame058	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-058.jpg';
import frame059	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-059.jpg';
import frame060	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-060.jpg';
import frame061	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-061.jpg';
import frame062	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-062.jpg';
import frame063	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-063.jpg';
import frame064	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-064.jpg';
import frame065	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-065.jpg';
import frame066	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-066.jpg';
import frame067	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-067.jpg';
import frame068	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-068.jpg';
import frame069	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-069.jpg';
import frame070	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-070.jpg';
import frame071	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-071.jpg';
import frame072	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-072.jpg';
import frame073	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-073.jpg';
import frame074	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-074.jpg';
import frame075	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-075.jpg';
import frame076	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-076.jpg';
import frame077	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-077.jpg';
import frame078	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-078.jpg';
import frame079	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-079.jpg';
import frame080	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-080.jpg';
import frame081	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-081.jpg';
import frame082	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-082.jpg';
import frame083	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-083.jpg';
import frame084	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-084.jpg';
import frame085	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-085.jpg';
import frame086	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-086.jpg';
import frame087	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-087.jpg';
import frame088	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-088.jpg';
import frame089	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-089.jpg';
import frame090	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-090.jpg';
import frame091	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-091.jpg';
import frame092	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-092.jpg';
import frame093	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-093.jpg';
import frame094	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-094.jpg';
import frame095	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-095.jpg';
import frame096	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-096.jpg';
import frame097	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-097.jpg';
import frame098	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-098.jpg';
import frame099	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-099.jpg';
import frame100	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-100.jpg';
import frame101	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-101.jpg';
import frame102	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-102.jpg';
import frame103	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-103.jpg';
import frame104	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-104.jpg';
import frame105	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-105.jpg';
import frame106	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-106.jpg';
import frame107	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-107.jpg';
import frame108	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-108.jpg';
import frame109	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-109.jpg';
import frame110	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-110.jpg';
import frame111	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-111.jpg';
import frame112	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-112.jpg';
import frame113	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-113.jpg';
import frame114	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-114.jpg';
import frame115	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-115.jpg';
import frame116	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-116.jpg';
import frame117	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-117.jpg';
import frame118	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-118.jpg';
import frame119	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-119.jpg';
import frame120	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-120.jpg';
import frame121	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-121.jpg';
import frame122	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-122.jpg';
import frame123	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-123.jpg';
import frame124	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-124.jpg';
import frame125	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-125.jpg';
import frame126	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-126.jpg';
import frame127	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-127.jpg';
import frame128	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-128.jpg';
import frame129	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-129.jpg';
import frame130	from '../../../assets/panotour/Video/video_frames_python/ezgif-frame-130.jpg';

export const videoFrames = [
    frame001, frame002, frame003, frame004, frame005, 
    frame006, frame007, frame008, frame009, frame010,
    frame011, frame012, frame013, frame014, frame015, 
    frame016, frame017, frame018, frame019, frame020,
    frame021, frame022, frame023, frame024, frame025,
    frame026, frame027, frame028, frame029, frame030,
    frame031, frame032, frame033, frame034, frame035, 
    frame036, frame037, frame038, frame039, frame040,
    frame041, frame042, frame043, frame044, frame045, 
    frame046, frame047, frame048, frame049, frame050,
    frame051, frame052, frame053, frame054, frame055, 
    frame056, frame057, frame058, frame059, frame060,
    frame061, frame062, frame063, frame064, frame065, 
    frame066, frame067, frame068, frame069, frame070,
    frame071, frame072, frame073, frame074, frame075, 
    frame076, frame077, frame078, frame079, frame080,
    frame081, frame082, frame083, frame084, frame085, 
    frame086, frame087, frame088, frame089, frame090,
    frame091, frame092, frame093, frame094, frame095, 
    frame096, frame097, frame098, frame099, frame100,
    frame101, frame102, frame103, frame104, frame105, 
    frame106, frame107, frame108, frame109, frame110,
    frame111, frame112, frame113, frame114, frame115, 
    frame116, frame117, frame118, frame119, frame120,
    frame121, frame122, frame123, frame124, frame125, 
    frame126, frame127, frame128, frame129, frame130
]