import { useEffect, useState } from "react";
import styles from "../RequestDocumentUploadForm.module.css";
import { Table } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { convertDBDate2Show, convertDBTime2Show } from "../../../../../helper/DateTimeHelper";
import { RequestDocumentData, RequestDocumentStatusLog } from "../../../../../models/RequestDocumentModel";
import { readedRequestDocument } from "../../../../../services/RequestDocumentService";
import { isShowRequestDocumentChangeFlg, labelLang } from "../../../../../helper/HTMLHelper";
import { LanguageState } from "../../../../../store/language/languageSlice";
import { useAppSelector } from "../../../../../store";

interface HistoryTabProps{
    requestDocument: RequestDocumentData | undefined;
    userId: number;
    logList: RequestDocumentStatusLog[];
}

const HistoryTab = (props: HistoryTabProps) => {
    const [logList, setLogList] = useState<RequestDocumentStatusLog[]>(props.logList);
    const [userId, setUserId] = useState<number>(props.userId);
    const [requestDocument, setRequestDocument] = useState<RequestDocumentData | undefined>(props.requestDocument);

    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])
    
    useEffect(() => {
        setLogList(props.logList);
        updateReadFlg();
    }, [props.logList])
    useEffect(() => {
        setUserId(props.userId)
    }, [props.userId])
    useEffect(() => {
        setRequestDocument(props.requestDocument)
    }, [props.requestDocument])
    const columns = [
        {
            title: labelLang(`ผู้ทำรายการ`, `Operator`, currentLang),
            key: 'name',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: RequestDocumentStatusLog) => {return `${data.process_user_firstname} ${data.process_user_lastname}`},
        },
        {
            title: labelLang('บทบาท', `Role`, currentLang),
            dataIndex: 'role_name',
            key: 'role_name',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang('ข้อความแนบ', `Message`, currentLang),
            dataIndex: 'attach_message',
            key: 'attach_message',
            // sorter: (a: ProcessTransaction, b: ProcessTransaction) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang('วันที่ดำเนินการ', `Log Date`, currentLang),
            // dataIndex: 'process_date',
            key: 'log_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: RequestDocumentStatusLog) => {return `${convertDBDate2Show(data.log_date)} ${convertDBTime2Show(data.log_time)} `},
        },
        {
            title: labelLang('สถานะเก่า', `Previous Status`, currentLang),
            dataIndex: 'old_status',
            key: 'old_status',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang('สถานะปัจจุบัน', `Current Status`, currentLang),
            dataIndex: 'current_status',
            key: 'current_status',
            // sorter: (a: ProcessTransaction, b: ProcessTransaction) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
    ]
    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const updateReadFlg = () => {
        if(requestDocument && isShowRequestDocumentChangeFlg(requestDocument, userId)){
            readedRequestDocument(requestDocument.id)
        }
    }
    return (
        <div className={styles['list-container']}>
            <Table columns={columns} dataSource={logList} onChange={onChange}/>
        </div>
    )
}

export default HistoryTab;