import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../store";
import { setBack2Page, setPageName } from "../../../../../store/pagination/paginationSlice";
import { DeleteTwoTone, EditTwoTone, EnvironmentTwoTone, InboxOutlined, PlusSquareTwoTone, QuestionCircleFilled, QuestionCircleOutlined, WarningFilled } from "@ant-design/icons";
import { Button, DatePicker, Input, message, Modal, notification, Popconfirm, Select, Space, Table } from "antd";
import styles from './RoleList.module.css';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import type { UploadFile } from 'antd/es/upload/interface';
import axios from "axios";
import ApiConfig from "../../../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { deleteRole, deleteUser, getPermission, getRoleList, saveNewRole } from "../../../../../services/UserService";
import Role from "../../../../../models/Role";
import { ApiResponse } from "../../../../../models/MasterModel";
import { Permission } from "../../../../../models/Login";

const RoleList = () => {
    const dispatch = useAppDispatch();
    const { RangePicker } = DatePicker;
    const dateFormat = 'DD/MM/YYYY';
    let navigate = useNavigate();
    const {Option} = Select;

    const [searchFromDate, setSearchFromDate] = useState();
    const [searchRefName, setSearchRefName] = useState();
    const [dataList, setDataList] = useState<Role[]>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>('');
    const [previewTitle, setPreviewTitle] = useState<string>('');
    const [photoDate, setPhotoDate] = useState<any>(moment());
    const [refName, setRefName] = useState<string>("");
    const [userId, setUserId] = useState<number>(1);
    const [searchRoleName, setSearchRoleName] = useState<string | undefined>();
    const [selectedPermission, setSelectedPermission] = useState<string>("ALL");
    const [permissionComboList, setPermissionComboList] = useState<Permission[]>();

    const [newRoleName, setNewRoleName] = useState<string>("");
    
    const columns = [
        {
            title: 'ชื่อบทบาท',
            dataIndex: 'role_name',
            key: 'role_name',
            sorter: (a: any, b: any) => a.role_name.localeCompare(b.role_name),
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: 'คำอธิบาย',
            dataIndex: 'role_description',
            key: 'role_description',
            sorter: (a: any, b: any) => a.role_description.localeCompare(b.role_description),
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: 'จำนวนผู้ใช้',
            dataIndex: 'count_user',
            key: 'count_user',
            sorter: (a: any, b: any) => a.count_user - b.count_user,
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
          title: 'Action',
          key: 'action',
          render: (text: string, record: Role) => {
            let deleteBtn = null;
            if(record.id > 0){
                if(record.count_user && record.count_user > 0){
                    deleteBtn = (
                        <Popconfirm 
                            title="มีผู้ใช้ในบทบาทนี้อยู่ ไม่สามารถลบได้"
                            onConfirm={() => {}}
                            okText={"ปิด"}
                            showCancel={false}
                            icon={<WarningFilled style={{ color: 'red' }} />}
                        >
                            <DeleteTwoTone />
                        </Popconfirm>
                    );
                }else if(record.count_user == null || record.count_user == 0){
                    deleteBtn = (
                        <Popconfirm 
                            title="ต้องการลบบทบาทหรือไม่"
                            onCancel={() => {}}
                            onConfirm={() => {removeRole(record.id)}}
                            okText={"ลบ"}
                            icon={<QuestionCircleFilled/>}
                        >
                            <DeleteTwoTone />
                        </Popconfirm>
                    );
                }
            }
            return (
                <Space size="middle">
                    <Link to={`/management/role/form/${record.id}`}><EditTwoTone /></Link>
                    {deleteBtn}
                </Space>
            )
          },
        },
    ]
    useEffect(() => {
        dispatch(setPageName({th: "รายการบทบาทผู้ใช้", en: "Role List"}));
        dispatch(setBack2Page(null));
        loadPermissionCombo();
    }, [])

    const loadRole = async () => {
        let resData = await getRoleList();
        if(resData.status){
            setDataList(resData.data);
        }
    }
    const loadPermissionCombo = async () => {
        let resData: ApiResponse = await getPermission();
        if(resData.status){
            setPermissionComboList(resData.data);
        }
    }
    
    const renderPermission = () => {
        let optionList = [];
        if(permissionComboList){
            optionList.push(<Option value={`ALL`}>ทุกสิทธิ์</Option>);
            for(let i = 0; i < permissionComboList.length; i++){
                let perm = permissionComboList[i];
                optionList.push(<Option value={`${perm.id}`}><div title={perm.permission_description}>{perm.permission_name}</div></Option>);
            }
            return optionList;
        }
    }
    const onSearch = () => {
        loadRole();
    }
    const onPermissionChange = (value: string) => {
        setSelectedPermission(value);
    }
    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        console.log('params', pagination, filters, sorter, extra);
    }
    const handleCancel = () => {setPreviewVisible(false)};
    const refNameChange = (e: any) => {
        setRefName(e.target.value);
    }
    const openAddModal = () => {
        setPreviewVisible(true);
    }
    const saveRole = async () => {
        let resData = await saveNewRole({
            role_name: newRoleName,
            role_description: ""
        });
        if(resData.status){
            notification.success({message: "บันทึกข้อมูลสำเร็จ", description: resData.message})
            navigate(`/management/role/form/${resData.data}`);
        }else{
            notification.error({message: "บันทึกข้อมูลไม่สำเร็จ", description: resData.message})
        }
    }
    const removeRole = (roleId: number) => {
        console.log("Delete role: ", roleId);
        deleteRole(roleId).then(() => {
            loadRole();
        });
    }
    return (
        <div className={styles['main-container']}>
            <div className={styles['search-container']}>
                <div className={styles['search-component']}>
                    <Input type={"text"} placeholder="ชื่อบทบาท" />
                </div>
                <div className={styles['search-component']}>
                    <div className={styles['input-line']}>
                        <Select
                            showSearch
                            placeholder="เลือกสิทธิ์ภายในบทบาท"
                            optionFilterProp="children"
                            onChange={onPermissionChange}
                            value={selectedPermission}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            className={styles['select-full']}
                        >
                            {renderPermission()}
                        </Select>
                    </div>
                </div>
                {/* <div className={styles['search-component']}>
                    <span style={{marginRight: 5}}>วันที่อัปโหลด: </span>
                    <RangePicker
                        defaultValue={[moment(), moment()]}
                        format={dateFormat}
                    />
                </div> */}
                {/* <div className={styles['search-component']}>
                    <Input type={"text"} placeholder={"ชื่ออ้างอิง"} />
                </div> */}
                <div className={styles['search-component-submit']}>
                    <Button type="primary" style={{marginRight: 5}} onClick={onSearch}>ค้นหา</Button>
                    <Button type="default">Reset</Button>
                </div>
            </div>
            <div className={styles['list-container']}>
                <div className={styles['button-container']}>
                    <Button type={'primary'} onClick={() => {openAddModal()}}>เพิ่มบทบาทใหม่</Button>
                </div>
                <Table columns={columns} dataSource={dataList} onChange={onChange}/>
            </div>
            <Modal
                visible={previewVisible}
                title={"เพิ่มบทบาทใหม่"}
                onCancel={() => {handleCancel()}}
                onOk={() => {saveRole()}}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']}>
                        <Input type={"text"} placeholder="ชื่อบทบาท" value={newRoleName} onChange={(e) => {setNewRoleName(e.target.value)}} />
                        {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default RoleList;