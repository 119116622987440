import { useEffect, useState } from "react";
import ActivateSetting from "../ActivateSetting";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setBack2Page, setPageName } from "../../../../store/pagination/paginationSlice";
import { SessionUserState } from "../../../../store/authen/authenSlice";
import { Spin } from "antd";

const ScholarshipActivateSetting = () => {
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const dispatch = useAppDispatch();
    const [currentAuthen, setCurrentAuthen] = useState<SessionUserState>(authen);
    useEffect(() => {
        dispatch(setPageName({th: "ตั้งค่าสำหรับโปรแกรมเอกสารทุน 90 ปี", en: "Scholarship Setting"}));
        dispatch(setBack2Page(null));
        // loadUserPermission();
        // search();
    }, [])

    useEffect(() => {
        setCurrentAuthen(authen);
    }, [authen])

    const renderComp = () => {
        if(currentAuthen && currentAuthen.sessionUser){
            return (
                <ActivateSetting 
                    moduleCode="03"
                    userId={currentAuthen.sessionUser.currentUser.id}
                />
            )
        }else{
            return (
                <Spin />
            )
        }
    }
    return renderComp();
}

export default ScholarshipActivateSetting;