import axios from "axios";
import ApiConfig from "../constants/ApiConfig";
import { ApiResponse } from "../models/MasterModel";

export const getActivityLog = async (searchCriteria: any) => {
    let url = `${ApiConfig.backendUrl}/activityController/getActivityLog.php`;
    let res = await axios.post(url, searchCriteria, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true'
      }
    });
    // console.log("response status: ", res.status);
    if(res.status === 200){
      // console.log("getActivityLog: ", res.data);
      let resData: ApiResponse = res.data;
      // let currentUser: SessionUser = createSessionUser(user, res.data.loginData, res.data.personData);
  
      return resData;
      // return currentUser;
    }else{
      // console.log("error code: ", res.status)
      let resData: ApiResponse = {status: false, message: res.statusText, data: null};
      return resData;
    }
}
export const getActivityLogSingleText = async (searchString: string) => {
  let url = `${ApiConfig.backendUrl}/activityController/getActivityLogSingleText.php`;
  let res = await axios.post(url, {searchText: searchString}, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
  });
  // console.log("response status: ", res.status);
  if(res.status === 200){
    // console.log("getActivityLog: ", res.data);
    let resData: ApiResponse = res.data;
    // let currentUser: SessionUser = createSessionUser(user, res.data.loginData, res.data.personData);

    return resData;
    // return currentUser;
  }else{
    // console.log("error code: ", res.status)
    let resData: ApiResponse = {status: false, message: res.statusText, data: null};
    return resData;
  }
}