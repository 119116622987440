import * as PANOLENS from "panolens";
import * as THREE from "three";
import React, {Component, useEffect, useRef, useState} from 'react'
import styles from './panolen.module.css'
import scene1 from '../../../assets/panotour/8000_r2.jpg'
import scene2 from '../../../assets/panotour/R1_8000.jpg'
// import scene1 from '../../../assets/panotour/room1.jpg'
// import scene2 from '../../../assets/panotour/room2.jpg'
import chulaLogo from '../../../assets/panotour/Mascot-f.png'
import thesisIcon from '../../../assets/images/thesis-book-icon-black.png'
import scholarshipIcon from '../../../assets/images/scholarship-icon-black.png'

import { Button, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import ApiConfig from "../../../constants/ApiConfig";
import PANO_ICONS from "./PanoIcons";

const Pano = (props) => {
  const containerRef = useRef(null);
  const { isNoVid } = props;
  const [viewer, setViewer] = useState(null);
  const [infoBox, setInfoBox] = useState(null);
  const [loadingOpacity, setLoadingOpacity] = useState(1);
  let navigate = useNavigate();
  // const viewer = new PANOLENS.Viewer({
  //   container: containerRef.current
  // });
  useEffect(() => {
    if(!viewer && !containerRef.current.hasViewer){
    
      console.log("containerRef: ", containerRef)
      const newViewer = new PANOLENS.Viewer({
        container: containerRef.current
      });
      containerRef.current.hasViewer = true;
      const panorama1 = new PANOLENS.ImagePanorama(scene1);
      const panorama2 = new PANOLENS.ImagePanorama(scene2);

      const thesitIcon = new PANOLENS.Infospot(200, PANO_ICONS.ArrowDown, true);
      thesitIcon.addHoverText("ยื่นส่งวิทยานิพนธ์")
      const scholarIcon = new PANOLENS.Infospot(200, PANO_ICONS.ArrowDown, true);
      scholarIcon.addHoverText("ยื่นเอกสารทุน")
      const requestDocIcon = new PANOLENS.Infospot(200, PANO_ICONS.ArrowDown, true);
      requestDocIcon.addHoverText("ยื่นแบบคำร้อง")

      thesitIcon.position.set(2700, -400, 1940);
      scholarIcon.position.set(2300, -350, 3560);
      requestDocIcon.position.set(3000, -410, -430);

      thesitIcon.addEventListener( 'click', function(){
        navigate("/management/thesis")
      } );
      scholarIcon.addEventListener( 'click', function(){
        navigate("/management/scholarship")
      } );
      requestDocIcon.addEventListener( 'click', function(){
        navigate("/management/requestdocument")
      } );
      panorama2.add( thesitIcon );
      panorama2.add( scholarIcon );
      panorama2.add( requestDocIcon );
      
      panorama1.addEventListener('enter-fade-start', () => {
        newViewer.tweenControlCenter(new THREE.Vector3( 2500, -100, 2000), 3000);
        newViewer.OrbitControls.minAzimuthAngle = (Math.PI / 2);
        newViewer.OrbitControls.maxAzimuthAngle = Math.PI / 1.3;
        newViewer.OrbitControls.minPolarAngle = Math.PI / 3;
        newViewer.OrbitControls.maxPolarAngle = Math.PI * 2 / 3;
        // setInfoBox(null);
      });
      panorama2.addEventListener('enter-fade-start', () => {
        newViewer.tweenControlCenter(new THREE.Vector3( 2500, -100, 1300), 3000 );
        newViewer.OrbitControls.minAzimuthAngle = - Infinity;
        newViewer.OrbitControls.maxAzimuthAngle = Infinity;
        setInfoBox(renderInfoBox());
      });
      panorama1.link( panorama2, new THREE.Vector3( 2500, -650, 2800), 300, PANO_ICONS.ArrowUp);
      // panorama2.link( panorama1, new THREE.Vector3( -1700, -1200, -1500), 300, PANOLENS.DataImage.Arrow);
      // panorama2.link( panorama1, new THREE.Vector3( -1700, -1200, -3000), 300, PANOLENS.DataImage.Arrow);
      panorama2.link( panorama1, new THREE.Vector3( -1700, -1200, -2300), 300, PANO_ICONS.ArrowUp);
      // const panorama = new PANOLENS.VideoPanorama( 'https://pchen66.github.io/Panolens/examples/asset/textures/video/ClashofClans.mp4', { autoplay: true } );
      // newViewer.add(panorama);
      console.log("isNoVid: ", isNoVid, props);
      if(isNoVid){
        newViewer.add(panorama2);
        newViewer.add(panorama1);
      }else{
        newViewer.add(panorama1);
        newViewer.add(panorama2);
      }
      console.log("maxangle ", Math.PI / 3);
      createMenu(newViewer);
      // newViewer.OrbitControls.minFov = 50;
      // newViewer.OrbitControls.maxFov = 160;
      setViewer(newViewer);
    }
    let nowInterval = null;
    let nowOpacity = 1;
    setTimeout(() => {
      nowInterval = setInterval(() => {
        let newOpacity = nowOpacity - 0.05;
        setLoadingOpacity(newOpacity);
        nowOpacity = newOpacity;
        // console.log("nowOpacity: ", nowOpacity);
        if(nowOpacity <= 0){
          clearInterval(nowInterval);
          nowInterval = null;
        }
      }, 20);
    }, 1200)
    return () => {
      setInfoBox(null);
      if(nowInterval){
        clearInterval(nowInterval);
      }
    }
  }, [containerRef])
  const createMenu = (newViewer) => {
    let commonStyle = {
      backgroundColor: '#FCBDEF',
      borderRadius: '5px',
      width: '40px',
      height: '40px',
      margin: '2px',
      // marginBottom: '5px',
      float: 'left'
    }
    let controlItemThesis = {
      style: {
          backgroundImage: `url(${ApiConfig.publicUrl}/assets/icons/thesis-book-icon-black.png)`,
          ...commonStyle
      },
      
      onTap: function(){
          // imageClick(IMAGE_URL_1, 'วัดพระศรีอิริยาบถ');
          // slideClick();
          navigate("/management/thesis")
      },
      title: "ยื่นส่งวิทยานิพนธ์"
    };
    let controlItemScholarship = {
      style: {
          backgroundImage: `url(${ApiConfig.publicUrl}/assets/icons/scholarship-icon-black.png)`,
          ...commonStyle
      },
      
      onTap: function(){
          // imageClick(IMAGE_URL_1, 'วัดพระศรีอิริยาบถ');
          // slideClick();
          navigate("/management/scholarship")
      },
      title: "ยื่นเอกสารทุน"
    };
    let controlItemRequestDoc = {
      style: {
          backgroundImage: `url(${ApiConfig.publicUrl}/assets/icons/application.png)`,
          ...commonStyle
      },
      
      onTap: function(){
          // imageClick(IMAGE_URL_1, 'วัดพระศรีอิริยาบถ');
          // slideClick();
          navigate("/management/requestdocument")
      },
      title: "ยื่นแบบคำร้อง"
    };
    let controlItemContactUs = {
      style: {
        backgroundImage: `url(${ApiConfig.publicUrl}/assets/icons/contact.png)`,
        ...commonStyle
      },
      onTap: function(){
        window.open("https://www.grad.chula.ac.th/en/about.php?id=15", "_blank");
      },
      title: "ติดต่อเรา"
    }
    let controlItemAboutUs = {
      style: {
        backgroundImage: `url(${ApiConfig.publicUrl}/assets/icons/info.png)`,
        ...commonStyle
      },
      onTap: function(){
        window.open("https://www.grad.chula.ac.th", "_blank")
      },
      title: "เกี่ยวกับเรา"
    }
    let controlItemList = [controlItemThesis, controlItemScholarship, controlItemRequestDoc, controlItemContactUs, controlItemAboutUs];
    for(var i = 0; i < controlItemList.length; i++){
        var controlItem = newViewer.appendControlItem(controlItemList[i]);
        controlItem.addEventListener('click', controlItemList[i].onTap, false );
        controlItem.setAttribute("title", controlItemList[i].title);
    }
  }
  const renderInfoBox = () => {
    return (<></>);
  }
  const renderInfoBoxBak = () => {
    return (
      <div className={styles['info-box']}>
        <div className={styles['info-box-wrapper']}>
          {/* <div>ระบบบริหารจัดการข้อมูล</div> */}
          <div className={styles['left-text-panel']}>
            <div className={styles['left-text-panel-wrapper']}>
              <div className={styles['graduate-text-panel']}>Graduate School</div>
              <div className={styles['university-text-panel']}>Chulalongkorn University</div>
            </div>
          </div>
          <img className={styles['logo-image']} src={chulaLogo}></img>
          <div className={styles['info-container']}>
            <div className={styles['name-text-container']}>
              <div className={styles['main-name-text']}>
                Visual One Stop Service
              </div>
              <div className={styles['sub-name-text']}>
                บัณฑิตวิทยาลัย จุฬาลงกรณ์มหาวิทยาลัย
              </div>
            </div>
            <div className={styles['info-container-left']}>
              <div className={styles['info-button']} onClick={() => {navigate("/management/thesis")}}>
                {/* <img src={thesisIcon} style={{height: 40, marginRight: 5}}></img> */}
                ส่งวิทยานิพนธ์
                {/* <img className={styles['logo-image']} style={{zIndex: -1}} src={chulaLogo}></img> */}
              </div>
              <div className={styles['info-button']} onClick={() => {navigate("/management/scholarship")}}>
                {/* <img src={scholarshipIcon} style={{height: 40, marginLeft: 5}}></img> */}
                ยื่นเอกสารทุน
                {/* <img className={styles['logo-image']} src={chulaLogo}></img> */}
              </div>
              <div className={styles['info-button']} onClick={() => {window.open("https://www.grad.chula.ac.th/en/about.php?id=15", "_blank")}}>
                {/* <img src={scholarshipIcon} style={{height: 40, marginLeft: 5}}></img> */}
                ติดต่อเรา
                {/* <img className={styles['logo-image']} src={chulaLogo}></img> */}
              </div>
              <div className={styles['info-button']} onClick={() => {window.open("https://www.grad.chula.ac.th", "_blank")}}>
                {/* <img src={scholarshipIcon} style={{height: 40, marginLeft: 5}}></img> */}
                เกี่ยวกับระบบ
                {/* <img className={styles['logo-image']} src={chulaLogo}></img> */}
              </div>
              {/* <Button type={'primary'} onClick={() => {navigate("/management/thesis")}}>
                <img src={thesisIcon} style={{height: 25, marginRight: 5}}></img>
                บันทึกส่งเอกสารวิทยานิพนธ์
              </Button> */}
            </div>
          </div>
        </div>
          {/* <Link to={"/frontend/login"}>go to Login</Link> */}
      </div>
    );
  }
  return (
    <div ref={containerRef} id="panolenContainer" className={styles['pano-container']} style={{zIndex: 1}}>
      <img src={chulaLogo} style={{position: "absolute", width: 150}}/>
      <div className={styles['loading']} style={{opacity: loadingOpacity, display: loadingOpacity <= 0 ? 'none' : 'flex'}}></div>
      {/* <div style={{position: 'absolute', left: '50%', height: '100%'}}> */}
        {infoBox}
      {/* </div> */}
    </div>
  );
};

export default Pano;
