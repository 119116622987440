// import { initializeApp } from 'firebase/app';
import FirebaseConfig from '../constants/FirebaseConfig';
// import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, User } from 'firebase/auth';
import { SessionUser, setAuthenUser } from '../store/authen/authenSlice';
import Role from '../models/Role';
import ApiConfig from '../constants/ApiConfig';
import axios from 'axios';
import { ApiResponse } from '../models/MasterModel';
import Login, { Permission, Personal } from '../models/Login';
import { useAppDispatch } from '../store';
import { checkLoginSessionOvertime, isLoginSessionOvertime, saveCurrentLoginSessionTime } from './SessionService';

// const app = initializeApp(FirebaseConfig);

// $loginData = new stdClass();
// $loginData->id = $userData['id'];
// $loginData->email = $userData['email'];
// $loginData->main_role = $userData['main_role'];
// $loginData->main_role_name = $userData['role_name'];

// $personalData = new stdClass();
// $personalData->login_id = $userData['id'];
// $personalData->student_code = $userData['student_code'];
// $personalData->firstname = $userData['firstname'];
// $personalData->lastname = $userData['lastname'];
// $personalData->phone_no = $userData['phone_no'];
interface LoginSuccessData {
  loginData: Login;
  personalData: Personal;
}

const createSessionUser = (responseUser: any, personData: any) => {
  let currentUser: SessionUser = {
    currentUser: responseUser,
    personData: personData
  }
  return currentUser;
}

const createRoleUser = (responseUser: any) => {
  let roleUser: Role = {
    id: responseUser.role_id,
    role_name: responseUser.role_name,
    role_description: '',
  }
  return roleUser;
}

export const login = async(user: any, dispatch: any) => {
    let loginUrl = `${ApiConfig.backendUrl}/userController/login.php`;
    let res = await axios.post(loginUrl, user, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true'
      }
    });
    // console.log("After Post ");
    // console.log("response status: ", res.status);
    if(res.status === 200){
      // console.log("response data: ", res.data);
      let resData: ApiResponse = res.data;
      // let currentUser: SessionUser = createSessionUser(res.data.loginData, res.data.personData);
      if(resData.status){
        let loginData: LoginSuccessData = resData.data;
        resData.message = `ยินดีต้อนรับ คุณ${loginData.personalData.firstname} ${loginData.personalData.lastname}`;
        let sessionUser: SessionUser = {
          currentUser: loginData.loginData,
          personData: loginData.personalData
        }
        dispatch(setAuthenUser(sessionUser));
        saveCurrentLoginSessionTime(sessionUser.currentUser.id, sessionUser.currentUser.email);
        localStorage.setItem("voss-web-user", JSON.stringify(sessionUser));
        return resData;
      }else{
        return resData;
      }
    }else{
      // console.log("error code: ", res.status)
      let resData: ApiResponse = {status: false, message: res.statusText, data: null};
      return resData;
    }
}

export const authenFromLocalStorage = (dispatch: any) => {
  let userFromStorage = localStorage.getItem("voss-web-user");
  if(userFromStorage){
    let sessionUser: SessionUser = JSON.parse(userFromStorage);
    // console.log("authenFromLocalStorage: ", sessionUser);
    let isOver = checkLoginSessionOvertime(sessionUser.currentUser.id, sessionUser.currentUser.email);
    if(isOver){
      logout(null, dispatch);
    }else{
      authen(sessionUser.currentUser, dispatch);
    }
  }else{
    logout(null, dispatch);
  }
}

export const authen = async (currentUser: Login, dispatch: any) => {
  let loginUrl = `${ApiConfig.backendUrl}/userController/authen.php`;
  let res = await axios.post(loginUrl, currentUser, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
  });
  // console.log("After Post ");
  // console.log("response status: ", res.status);
  if(res.status === 200){
    // console.log("response data: ", res.data);
    let resData: ApiResponse = res.data;
    // let currentUser: SessionUser = createSessionUser(res.data.loginData, res.data.personData);
    if(resData.status){
      let loginData: LoginSuccessData = resData.data;
      resData.message = `ยืนยันสิทธิ์ของ คุณ${loginData.personalData.firstname} ${loginData.personalData.lastname}`;
      let sessionUser: SessionUser = {
        currentUser: loginData.loginData,
        personData: loginData.personalData
      }
      dispatch(setAuthenUser(sessionUser));
      saveCurrentLoginSessionTime(sessionUser.currentUser.id, sessionUser.currentUser.email);
      localStorage.setItem("voss-web-user", JSON.stringify(sessionUser));
      return resData;
    }else{
      return resData;
    }
  }else{
    // console.log("error code: ", res.status)
    let resData: ApiResponse = {status: false, message: res.statusText, data: null};
    return resData;
  }
}

export const authenPermission = async (permissionNames: string[]) => {
  let url = `${ApiConfig.backendUrl}/userController/authenPermission.php`;
  let res = await axios.post(url, permissionNames, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
  });
  // console.log("After Post ");
  // console.log("response status: ", res.status);
  if(res.status === 200){
    // console.log("response data: ", res.data);
    let resData: ApiResponse = res.data;
    // let currentUser: SessionUser = createSessionUser(res.data.loginData, res.data.personData);
    if(resData.status){
      let permissionList: Permission = resData.data;
      return resData;
    }else{
      return resData;
    }
  }else{
    // console.log("error code: ", res.status)
    let resData: ApiResponse = {status: false, message: res.statusText, data: null};
    return resData;
  }
}

export const logout = async(userId: any, dispatch: any) => {
  localStorage.removeItem("voss-web-user");
  dispatch(setAuthenUser(null));
  if(userId){
    let url = `${ApiConfig.backendUrl}/userController/logout.php`;
    let res = await axios.post(url, {userId: userId}, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Credentials': 'true'
      }
    });
    if(res.status === 200){
      // console.log("response data: ", res.data);
      let resData: ApiResponse = res.data;
      // let currentUser: SessionUser = createSessionUser(res.data.loginData, res.data.personData);
      if(resData.status){
      }
    }else{
      // console.log("error code: ", res.status)
      let resData: ApiResponse = {status: false, message: res.statusText, data: null};
      // return resData;
    }
  }
  // localStorage.removeItem("voss-web-user");
  // dispatch(setAuthenUser(null));
}

export const register = async (registerData: any) => {
  let url = `${ApiConfig.backendUrl}/userController/register.php`;
  let res = await axios.post(url, registerData, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
  });
  // console.log("response status: ", res.status);
  if(res.status === 200){
    // console.log("response data: ", res.data);
    let resData: ApiResponse = res.data;
    // let currentUser: SessionUser = createSessionUser(user, res.data.loginData, res.data.personData);

    return resData;
    // return currentUser;
  }else{
    // console.log("error code: ", res.status)
    let resData: ApiResponse = {status: false, message: res.statusText, data: null};
    return resData;
  }
}

export const addNewUser = async (newUserData: any) => {
  let url = `${ApiConfig.backendUrl}/userController/addNewUser.php`;
  let res = await axios.post(url, newUserData, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
  });
  // console.log("response status: ", res.status);
  if(res.status === 200){
    // console.log("response data: ", res.data);
    let resData: ApiResponse = res.data;
    // let currentUser: SessionUser = createSessionUser(user, res.data.loginData, res.data.personData);

    return resData;
    // return currentUser;
  }else{
    // console.log("error code: ", res.status)
    let resData: ApiResponse = {status: false, message: res.statusText, data: null};
    return resData;
  }
}

export const getAuthPermission = async (userId: number, permissionNameList: string[]) => {
  let url = `${ApiConfig.backendUrl}/userController/getAuthPermission.php`;
  let res = await axios.post(url, {userId, permissionNameList}, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
  });
  // console.log("response status: ", res.status);
  if(res.status === 200){
    // console.log("response data: ", res.data);
    let resData: ApiResponse = res.data;
    // let currentUser: SessionUser = createSessionUser(user, res.data.loginData, res.data.personData);

    return resData;
    // return currentUser;
  }else{
    // console.log("error code: ", res.status)
    let resData: ApiResponse = {status: false, message: res.statusText, data: null};
    return resData;
  }
  
}
export const sendNewPassword2Email = async (userId: number) => {
  let url = `${ApiConfig.backendUrl}/userController/sendPassword2Email.php`;
  let res = await axios.post(url, {id: userId}, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Credentials': 'true'
    }
  });
  // console.log("response status: ", res.status);
  if(res.status === 200){
    // console.log("response data: ", res.data);
    let resData: ApiResponse = res.data;
    // let currentUser: SessionUser = createSessionUser(user, res.data.loginData, res.data.personData);

    return resData;
    // return currentUser;
  }else{
    // console.log("error code: ", res.status)
    let resData: ApiResponse = {status: false, message: res.statusText, data: null};
    return resData;
  }
}

export const changePassword = async (userId: number, email: string, oldPassword: string, newPassword: string, confirmPassword: string) => {
  let url = `${ApiConfig.backendUrl}/userController/changePassword.php`;
  let res = await axios.post(url, {
      "password": newPassword, 
      "confirm_password": confirmPassword,
      "user_id": userId, 
      "email": email, 
      "old_password": oldPassword, 
    }, 
    // {
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Headers': '*',
    //     'Access-Control-Allow-Credentials': 'true'
    //   }
    // }
  );
  // console.log("response status: ", res.status);
  if(res.status === 200){
    // console.log("response data: ", res.data);
    let resData: ApiResponse = res.data;
    // let currentUser: SessionUser = createSessionUser(user, res.data.loginData, res.data.personData);

    return resData;
    // return currentUser;
  }else{
    // console.log("error code: ", res.status)
    let resData: ApiResponse = {status: false, message: res.statusText, data: null};
    return resData;
  }
}