import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counter/counterSlice';
import paginationReducer from './pagination/paginationSlice';
import authenReducer from './authen/authenSlice';
import languageSlice from './language/languageSlice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    pagination: paginationReducer,
    authen: authenReducer,
    language: languageSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
