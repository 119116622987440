import axios from "axios";
import ApiConfig from "../constants/ApiConfig"
import { ApiResponse } from "../models/MasterModel";

export const getUserById = async (userId: number) => {
    let url = `${ApiConfig.backendUrl}/userController/getUserById.php`
    let res = await axios.post(url, {userId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const getUserByParam = async (params: any) => {
    let url = `${ApiConfig.backendUrl}/userController/getUserByParam.php`
    let res = await axios.post(url, params, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const getRoleList = async () => {
    let url = `${ApiConfig.backendUrl}/roleController/getRoleByParam.php`
    let res = await axios.get(url, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const getRoleById = async (roleId:any) => {
    let url = `${ApiConfig.backendUrl}/roleController/getRoleById.php`
    let res = await axios.post(url, {roleId: roleId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const getUserInRole = async (roleId:any) => {
    let url = `${ApiConfig.backendUrl}/roleController/getUserInRole.php`
    let res = await axios.post(url, {roleId: roleId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const getUserNotInRole = async (roleId:any) => {
    let url = `${ApiConfig.backendUrl}/roleController/getUserNotInRole.php`
    let res = await axios.post(url, {roleId: roleId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const getPermission = async () => {
    let url = `${ApiConfig.backendUrl}/roleController/getPermission.php`
    let res = await axios.get(url, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const updateUserDataByAdmin = async (userData:any) => {
    let url = `${ApiConfig.backendUrl}/userController/updateUserByAdmin.php`
    let res = await axios.post(url, userData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const deleteUser = async (userId: number) => {
    let url = `${ApiConfig.backendUrl}/userController/deleteUser.php`
    let res = await axios.post(url, {userId: userId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const deleteUserFromRole = async (roleId: any, userId: number) => {
    let url = `${ApiConfig.backendUrl}/roleController/deleteUserFromRole.php`
    let res = await axios.post(url, {loginId: userId, roleId: roleId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const deleteRole = async (roleId: number) => {
    let url = `${ApiConfig.backendUrl}/roleController/deleteRole.php`
    let res = await axios.post(url, {roleId: roleId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const saveNewRole = async (userData:any) => {
    let url = `${ApiConfig.backendUrl}/roleController/saveRole.php`
    let res = await axios.post(url, userData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const saveNewUser = async (userData:any) => {
    let url = `${ApiConfig.backendUrl}/roleController/saveRole.php`
    let res = await axios.post(url, userData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const editRole = async (userData:any) => {
    let url = `${ApiConfig.backendUrl}/roleController/editRole.php`
    let res = await axios.post(url, userData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const updateUserProfile = async (userData:any) => {
    let url = `${ApiConfig.backendUrl}/userController/updateProfile.php`
    let res = await axios.post(url, userData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}