import { ArrowLeftOutlined, LeftSquareTwoTone } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { LanguageState } from "../../../store/language/languageSlice";

const PageHeader = () => {
    const lang: LanguageState = useAppSelector(state => state.language);
    const pagination = useAppSelector(state => state.pagination);
    const [pageName, setPageName] = useState("Backend");
    const [back2Page, setBack2Page] = useState<string | null>(null);

    useEffect(() => {
        if(lang && lang.currentLang && lang.currentLang.lang === "en"){
            setPageName(pagination.pageName.en);
        }else{
            setPageName(pagination.pageName.th);
        }
    }, [pagination.pageName, lang.currentLang]);
    useEffect(() => {
        setBack2Page(pagination.back2Page);
    }, [pagination.back2Page]);
    const renderBackButton = () => {
        if(back2Page){
            return (<Link to={back2Page}><ArrowLeftOutlined style={{fontSize: 24, marginRight: 5}} /></Link>);
        }
    }
    return (
        <div className={"page-breadcrumb"}>
            <div className={"row align-items-center"}>
                <div className={"col-5"} style={{width: '100%'}}>
                    <h4 className={"page-title"}>{renderBackButton()}{pageName}</h4>
                    <div className={"d-flex align-items-center"}>
                        {/* <nav aria-label="breadcrumb">
                            <ol className={"breadcrumb"}>
                                <li className={"breadcrumb-item"}><a href="#">Home</a></li>
                                <li className={"breadcrumb-item active"} aria-current="page">Library</li>
                            </ol>
                        </nav> */}
                    </div>
                </div>
                <div className={"col-7"}>
                    <div className={"text-end upgrade-btn"}>
                        {/* <a href="https://www.wrappixel.com/templates/xtremeadmin/" className={"btn btn-danger text-white"}
                            target="_blank">Upgrade to Pro</a> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageHeader;