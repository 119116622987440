import { FileTextFilled, FileTextOutlined, HistoryOutlined, HomeOutlined, LogoutOutlined, ProfileOutlined, UserOutlined } from "@ant-design/icons";
import { Person, Toggles, JournalBookmark, FileEarmark, FileBarGraph, ClockHistory, Gear, Journals, Inbox, Calendar4Range } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import ApiConfig from "../../../constants/ApiConfig";
import { logout } from "../../../services/AuthenService";
import { useAppDispatch, useAppSelector } from "../../../store";
import { SessionUserState } from "../../../store/authen/authenSlice";
import { useEffect, useState } from 'react';
import PermissionHelper, { PermissionHelperResult } from "../../../helper/PermissionHelper";
import { PermissionName, RolePermissionNameList, UserPermissionNameList } from "../../../models/Login";
import CustomLabel from "../../../components/CustomLabel";

const SideNav = () => {
    const iconStyle = {fontSize: 20, marginLeft: 10, marginRight: 5};
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const dispatch = useAppDispatch();
    const [navigatePage, setNavigatePage] = useState<string>();
    const navigate = useNavigate();
    const permissionHelper = new PermissionHelper();
    const relavantPermissionList = [...UserPermissionNameList, ...RolePermissionNameList, PermissionName.ACTIVITY_LOG_VIEW, PermissionName.REPORT_VIEW, PermissionName.THESIS_SETTING, PermissionName.SCHOLAR90_SETTING];
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [showRoleMenu, setShowRoleMenu] = useState(false);
    const [showActivityLogMenu, setShowActivityLogMenu] = useState(false);
    const [showReportMenu, setShowReportMenu] = useState(false);
    const [showThesisSetting, setShowThesisSetting] = useState(false);
    const [showScholarSetting, setShowScholarSetting] = useState(false);

    useEffect(() => {
        loadUserPermission();
    }, [])

    useEffect(() => {
        if(authen && authen.sessionUser){
            loadUserPermission();
        }
    }, [authen])
    useEffect(() => {
      if(navigatePage != null && navigatePage.trim() != ""){
        navigate(navigatePage, {replace: true});
      }
    },[navigatePage]);

    const loadUserPermission = async () => {
        if(authen && authen.sessionUser){
            await permissionHelper.loadUserPermission(relavantPermissionList, authen);
            console.log("current perm: ", permissionHelper.currentPermission);
            let someUserPermissionResult: PermissionHelperResult = permissionHelper.hasSomePermission(UserPermissionNameList)
            let someRolePermissionResult: PermissionHelperResult = permissionHelper.hasSomePermission(RolePermissionNameList)
            setShowUserMenu(someUserPermissionResult.resultFlg);
            setShowRoleMenu(someRolePermissionResult.resultFlg);
            setShowActivityLogMenu(permissionHelper.hasPermission(PermissionName.ACTIVITY_LOG_VIEW));
            setShowReportMenu(permissionHelper.hasPermission(PermissionName.REPORT_VIEW));
            setShowThesisSetting(permissionHelper.hasPermission(PermissionName.THESIS_SETTING));
            setShowScholarSetting(permissionHelper.hasPermission(PermissionName.SCHOLAR90_SETTING));
        }
    }

    const renderPermissionMenu = () => {
        let menuList: any = [];
        if(showUserMenu){
            menuList.push(
                <li className={"sidebar-item"}> 
                    <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                        to="/management/user" aria-expanded="false">
                            <Person size={iconStyle.fontSize} style={iconStyle}/>
                            {/* <span className={"hide-menu"}>จัดการข้อมูลผู้ใช้</span> */}
                            <CustomLabel 
                                en="User Privileges"
                                th="จัดการข้อมูลผู้ใช้"
                                className={"hide-menu"}
                            />
                    </Link>
                </li>
            );
        }
        if(showRoleMenu){
            menuList.push(
                <li className={"sidebar-item"}> 
                    <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                        to="/management/role" aria-expanded="false">
                            <Gear size={iconStyle.fontSize} style={iconStyle}/>
                            {/* <span className={"hide-menu"}>จัดการข้อมูลบทบาท</span> */}
                            <CustomLabel 
                                en="User Role"
                                th="จัดการข้อมูลบทบาท"
                                className={"hide-menu"}
                            />
                    </Link>
                </li>
            );
        }
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/thesis" aria-expanded="false">
                        <Journals style={iconStyle}/>
                        {/* <span className={"hide-menu"}>วิทยานิพนธ์</span> */}
                        <CustomLabel 
                            en="Thesis Submission"
                            th="วิทยานิพนธ์"
                            className={"hide-menu"}
                        />
                </Link>
            </li>
        );
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/requestdocument" aria-expanded="false">
                        <Inbox style={iconStyle}/>
                        {/* <span className={"hide-menu"}>เอกสารคำร้อง</span> */}
                        <CustomLabel 
                            en="Request Form Document"
                            th="เอกสารคำร้อง"
                            className={"hide-menu"}
                        />
                </Link>
            </li>
        );
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/scholarship" aria-expanded="false">
                        <FileEarmark size={iconStyle.fontSize} style={iconStyle}/>
                        {/* <span className={"hide-menu"}>ทุน 90 ปี</span> */}
                        <CustomLabel 
                            en="Scholarship Document"
                            th="เอกสารทุน"
                            className={"hide-menu"}
                        />
                </Link>
            </li>
        );
        if(showReportMenu){
            menuList.push(
                <li className={"sidebar-item"}> 
                    <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                        to="/management/report" aria-expanded="false">
                            <FileBarGraph size={iconStyle.fontSize} style={iconStyle}/>
                            {/* <span className={"hide-menu"}>ออกรายงาน</span> */}
                            <CustomLabel 
                                en="Report"
                                th="ออกรายงาน"
                                className={"hide-menu"}
                            />
                    </Link>
                </li>
            );
        }
        if(showActivityLogMenu){
            menuList.push(
                <li className={"sidebar-item"}> 
                    <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                        to="/management/activitylog" aria-expanded="false">
                            <ClockHistory size={iconStyle.fontSize} style={iconStyle}/>
                            <span className={"hide-menu"}>Activity Log</span>
                    </Link>
                </li>
            )
        }
        if(showThesisSetting){
            menuList.push(
                <li className={"sidebar-item"}> 
                    <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                        to="/management/setting/thesis" aria-expanded="false">
                            <Calendar4Range size={iconStyle.fontSize} style={iconStyle}/>
                            {/* <span className={"hide-menu"}>ตั้งค่าวิทยานิพนธ์</span> */}
                            <CustomLabel 
                                en="Thesis Setting"
                                th="ตั้งค่าวิทยานิพนธ์"
                                className={"hide-menu"}
                            />
                    </Link>
                </li>
            )
        }
        if(showScholarSetting){
            menuList.push(
                <li className={"sidebar-item"}> 
                    <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                        to="/management/setting/scholar" aria-expanded="false">
                            <Calendar4Range size={iconStyle.fontSize} style={iconStyle}/>
                            {/* <span className={"hide-menu"}>ตั้งค่าทุน 90 ปี</span> */}
                            <CustomLabel 
                                en="Scholarship Setting"
                                th="การตั้งค่าทุน"
                                className={"hide-menu"}
                            />
                    </Link>
                </li>
            )
        }
        return menuList;
    }
    const renderAdminMenu = () => {
        let menuList: any = [];
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/user" aria-expanded="false">
                        <Person size={iconStyle.fontSize} style={iconStyle}/>
                        <span className={"hide-menu"}>จัดการข้อมูลผู้ใช้</span>
                </Link>
            </li>
        );
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/role" aria-expanded="false">
                        <Gear size={iconStyle.fontSize} style={iconStyle}/>
                        <span className={"hide-menu"}>จัดการข้อมูลบทบาท</span>
                </Link>
            </li>
        );
        // menuList.push(
        //     <li className={"sidebar-item"}> 
        //         <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
        //             to="/management/permission" aria-expanded="false">
        //                 <i className={"mdi mdi-view-dashboard"}></i>
        //                 <span className={"hide-menu"}>จัดการข้อมูลสิทธิ์</span>
        //         </Link>
        //     </li>
        // )
        return menuList;
    }

    const renderStudentMenu = () => {
        let menuList: any = [];
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/thesis" aria-expanded="false">
                        <Person style={iconStyle}/>
                        <span className={"hide-menu"}>วิทยานิพนธ์</span>
                </Link>
            </li>
        );
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/requestdocument" aria-expanded="false">
                        <Person style={iconStyle}/>
                        <span className={"hide-menu"}>เอกสารคำร้อง</span>
                </Link>
            </li>
        );
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/scholarship" aria-expanded="false">
                        <UserOutlined style={iconStyle}/>
                        <span className={"hide-menu"}>สมัครทุน 90 ปี</span>
                </Link>
            </li>
        );
        return menuList;
    }

    const renderStaffMenu = () => {
        let menuList: any = [];
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/thesis" aria-expanded="false">
                        <JournalBookmark size={iconStyle.fontSize} style={iconStyle}/>
                        <span className={"hide-menu"}>วิทยานิพนธ์</span>
                </Link>
            </li>
        );
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/requestdocument" aria-expanded="false">
                        <Person style={iconStyle}/>
                        <span className={"hide-menu"}>เอกสารคำร้อง</span>
                </Link>
            </li>
        );
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/scholarship" aria-expanded="false">
                        <FileEarmark size={iconStyle.fontSize} style={iconStyle}/>
                        <span className={"hide-menu"}>ทุน 90 ปี</span>
                </Link>
            </li>
        );
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/report" aria-expanded="false">
                        <FileBarGraph size={iconStyle.fontSize} style={iconStyle}/>
                        <span className={"hide-menu"}>ออกรายงาน</span>
                </Link>
            </li>
        );
        menuList.push(
            <li className={"sidebar-item"}> 
                <Link className={"sidebar-link waves-effect waves-dark sidebar-link"}
                    to="/management/activitylog" aria-expanded="false">
                        <ClockHistory size={iconStyle.fontSize} style={iconStyle}/>
                        <span className={"hide-menu"}>Activity Log</span>
                </Link>
            </li>
        )
        return menuList;
    }
    const logoutHandler = () => {
        if(authen && authen.sessionUser){
            logout(authen.sessionUser.currentUser.id, dispatch);
            setNavigatePage("/home/manage")
        }
    }
    const renderMenu = () => {
        if(authen && authen.sessionUser){
            // let adminMenu = renderAdminMenu();
            // let studentMenu = renderStudentMenu();
            // let staffMenu = renderStaffMenu();
            // let result = [...adminMenu, ...staffMenu];
            // if(authen.sessionUser.currentUser.main_role == -3){
            //     result = studentMenu;
            // }
            // else if(authen.sessionUser.currentUser.main_role == -4){
            //     result = studentMenu;
            // }
            let result = renderPermissionMenu();
            return result;
        }
    }
    return (
        <nav className={"sidebar-nav"}>
            <ul id="sidebarnav">
                <li>
                    <div className={"user-profile d-flex no-block dropdown m-t-20"}>
                        <div className={"user-pic"}><img src={`${ApiConfig.publicUrl}/assets/icons/profile_image.png`} alt="users"
                                className={"rounded-circle"} width="40" /></div>
                        <div className={"user-content hide-menu m-l-10"}>
                            <a href="#" className={""} id="Userdd" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <h5 className={"m-b-0 user-name font-medium"}>
                                    {authen.sessionUser != null ? `${authen.sessionUser.personData.firstname} ${authen.sessionUser.personData.lastname}` : ''}
                                    
                                    <i className={"fa fa-angle-down"} style={{marginLeft: 10}}></i>
                                </h5>
                                <span className={"op-5 user-email"}>({authen.sessionUser != null ? authen.sessionUser.currentUser.main_role_name : 'ผู้ดูแลระบบ'})</span>
                            </a>
                            <div className={"dropdown-menu dropdown-menu-end"} aria-labelledby="Userdd">
                                <Link className={"dropdown-item"} to="/">
                                    <HomeOutlined />
                                    Homepage
                                </Link>
                                <Link className={"dropdown-item"} to="/management/profile">
                                    <ProfileOutlined />
                                    My Profile
                                </Link>
                                <a className={"dropdown-item"} onClick={logoutHandler}>
                                    <LogoutOutlined /> 
                                    Logout
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
                {renderMenu()}
                {/* {renderAdminMenu()}
                {renderStaffMenu()} */}
            </ul>
        </nav>
    );
}

export default SideNav;