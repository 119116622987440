import { Avatar, Button, Form, Input, Layout, notification, Result } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
import { useEffect, useState } from 'react';
import styles from './Login.module.css';
import { useAppDispatch, useAppSelector } from '../../store';
import { SessionUser, SessionUserState, setAuthenUser } from '../../store/authen/authenSlice';
import { useNavigate } from 'react-router-dom';
import * as authenService from '../../services/AuthenService';
import { FrownOutlined, GoogleCircleFilled, LockOutlined, MailOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons';
import { ApiResponse } from '../../models/MasterModel';
import Logo from '../../assets/Graduate SchoolLogo.png';
interface ILogin {
    email: string;
    password: string;
}
const Login = () => {
    const [form] = Form.useForm();
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [navigatePage, setNavigatePage] = useState<string>();

    useEffect(() => {
      if(navigatePage != null && navigatePage.trim() != ""){
        navigate(navigatePage, {replace: true});
      }
    },[navigatePage]);

    useEffect(() => {
        if(authen && authen.sessionUser && authen.sessionUser.currentUser){
            setNavigatePage("/management")
        }
    }, [authen])

    const loginHandler = async() => {
        // console.log("username: ", username);
        // console.log("password: ", password);
        // let user = authenService.firebaseAuthentication(username, password);
        
        // let currentUser = await authenService.authentication(user);
        // if(!currentUser){
        //     openNotification("Login Fail", "เข้าสู่ระบบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง", false);
        //     return;
        // }
        // dispatch(setAuthenUser(currentUser));
        // openNotification("Login Success", `ยินดีต้อนรับ ${currentUser.personData.firstname} ${currentUser.personData.lastname}`, true);
        // setNavigatePage("/app");
    }
    const signUpHandler = async() => {
        // console.log("username: ", username);
        // console.log("password: ", password);
        // let user = authenService.firebaseAuthentication(username, password);
        
        // let currentUser = await authenService.authentication(user);
        // dispatch(setAuthenUser(currentUser));
        setNavigatePage("/frontend/signup");
    }

    const onFinish = async (values: ILogin) => {
        console.log('Success:', values);
        let resData: ApiResponse = await authenService.login(values, dispatch);
        if(resData.status){
            notification.success({message: "เข้าสู่ระบบสำเร็จ", description: resData.message})
            // setNavigatePage("/backend");
        }else{
            notification.error({message: "เข้าสู่ระบบไม่สำเร็จ", description: resData.message})
        }
    };
  
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    
    const openNotification = (title: string, description: string, status: boolean) => {
        let icon = status ? <SmileOutlined style={{ color: '#108ee9' }} /> : <FrownOutlined style={{ color: '#F02707' }} />;
        notification.open({
            message: title,
            description: description,
            icon: icon,
        });
    };

    const formItemStyle = {marginBottom: 10};
    
    const renderLogin = () => {
        return (
            <div>
                {/* <Header>Header</Header> */}
                <div className={styles['login-container']}>
                    <div className={styles['component-box']}>
                        <div className={styles['user-icon-container']}>
                            {/* <Avatar
                                size={{ xs: 96, sm: 96, md: 128, lg: 128, xl: 128, xxl: 132 }}
                                // icon={<UserOutlined />}
                                style={{ backgroundColor: '#b43b6b' }}
                            >
                                <span style={{fontSize: 66, fontWeight: 'bolder'}}>V</span>
                            </Avatar> */}
                            <img src={Logo} height={96}></img>
                            <div>
                                <span style={{fontSize: 36, color: '#b43b6b'}}>Virtual One Stop Service</span>
                            </div>
                        </div>
                        <Form
                            form={form}
                            name="login"
                            // labelCol={{ span: 8 }}
                            // wrapperCol={{ span: 16 }}
                            style={{ width: '80%' }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'กรุณากรอกอีเมล์!' }]}
                                style={formItemStyle}
                            >
                                <Input 
                                    placeholder="Chula Email" 
                                    type={"text"} 
                                    prefix={<MailOutlined />}
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน!' }]}
                                style={formItemStyle}
                            >
                                <Input 
                                    placeholder="Personal ID or Passport ID" 
                                    type={"password"} 
                                    prefix={<LockOutlined />}
                                />
                            </Form.Item>
                            <div className={styles['button-container']}>
                                <Form.Item
                                    name="submitBtn"
                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                    style={{...formItemStyle, width: '100%'}}
                                >
                                    <Button 
                                        type={"primary"} 
                                        style={{marginRight: 5, width: '100%'}} 
                                        htmlType="submit"
                                        className={styles['login-btn']}
                                    >Login</Button>
                                </Form.Item>
                                {/* <span style={{color: 'red'}}>หมายเหตุ* ระบบนี้อยู่ในช่วงใช้งานโดยกลุ่มนิสิตสหสาขาวิชา บัณฑิตวิทยาลัย ระหว่างวันนี้ 17 ตุลาคม 2567 - 29 พฤศจิกายน 2567</span> */}
                                {/* <Button type={"default"} style={{marginLeft: 5}} icon={<GoogleCircleFilled />} onClick={googleSignUp}>
                                    Sign In with Google
                                </Button> */}
                                {/* <Button type={"link"} onClick={signUpHandler}>Sign up</Button> */}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
    const renderOldLogin = () => {
        return (
            <div>
                {/* <Header>Header</Header> */}
                <div className={styles['login-container']}>
                    <div className={styles['component-box']}>
                        <div className={styles['title-bar']}><span>เข้าสู่ระบบ</span></div>
                        <Form
                            form={form}
                            name="login"
                            // labelCol={{ span: 8 }}
                            // wrapperCol={{ span: 16 }}
                            style={{width: '80%'}}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'กรุณากรอกอีเมล์!' }]}
                                style={formItemStyle}
                            >
                                <Input placeholder="Email" type={"text"} value={username} onChange={e => setUsername(e.target.value)}/>
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน!' }]}
                                style={formItemStyle}
                            >
                                <Input placeholder="Passowrd" type={"password"} value={password} onChange={e => setPassword(e.target.value)} />
                            </Form.Item>
                            <div className={styles['button-container']}>
                                <Form.Item
                                    name="submitBtn"
                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                    // className={styles['half']}
                                    style={{...formItemStyle, }}
                                >
                                    <Button type={"primary"} style={{marginRight: 5}} htmlType="submit">Login</Button>
                                </Form.Item>
                                {/* <Button type={"default"} style={{marginLeft: 5}} icon={<GoogleCircleFilled />} onClick={googleSignUp}>
                                    Sign In with Google
                                </Button> */}
                                <Button type={"link"} onClick={signUpHandler}>Sign up</Button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
    return renderLogin();
}

export default Login;