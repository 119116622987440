
import moment, {Moment} from 'moment';
const storageName = "voss-web-interactime";
const loginSessionStorageName = "voss-web-login-session";
const dateFormatStr = "DD-MM-yyyy";
const timeFormatStr = "HH:mm:ss";
export const saveCurrentInteractTime = () => {
    let dateFormat = moment().format(dateFormatStr)
    let timeFormat = moment().format(timeFormatStr)
    localStorage.setItem(storageName, JSON.stringify({date: dateFormat, time: timeFormat}));
}

export const getInteractionTime = () => {
    let interactTimeFromStorage = localStorage.getItem(storageName);
    if(interactTimeFromStorage && interactTimeFromStorage != null && interactTimeFromStorage.trim() != ""){
        let interactDatetime: {date: string, time: string} = JSON.parse(interactTimeFromStorage);
        let datetimeStr = (interactDatetime.date + " " + interactDatetime.time);
        let datetimeFormat = (dateFormatStr + " " + timeFormatStr);
        let interactMoment: Moment = moment(datetimeStr, datetimeFormat);
        return interactMoment;
    }
    return null;
}

const interactionExpireMinute = 30;
export const isOvertime = () => {
    let interactionTime: Moment | null = getInteractionTime();
    if(interactionTime != null){
        let diff = moment().diff(interactionTime, "minutes", true);
        // console.log("diff: ", diff);
        if(diff <= interactionExpireMinute){
            return false
        }
    }
    return true;
}

export const checkOvertime = () => {
    let isOver = isOvertime();
    if(isOver){
        saveCurrentInteractTime();
    }
    return isOver;
}
export const saveCurrentLoginSessionTime = (userId: number, username: string) => {
    // console.log("saveCurrentLoginSessionTime: ", userId, username);
    let dateFormat = moment().format(dateFormatStr)
    let timeFormat = moment().format(timeFormatStr)
    localStorage.setItem(loginSessionStorageName, JSON.stringify({userId: userId, username: username, date: dateFormat, time: timeFormat}));
}

export const getLoginSessionTime = (userId: number, username: string) => {
    // console.log("getLoginSessionTime: ", userId, username);
    let loginSessionTimeFromStorage = localStorage.getItem(loginSessionStorageName);
    // console.log("loginSessionTimeFromStorage: ", loginSessionTimeFromStorage);
    if(loginSessionTimeFromStorage && loginSessionTimeFromStorage != null && loginSessionTimeFromStorage.trim() != ""){
        let loginSessionDatetime: {userId: number, username: string, date: string, time: string} = JSON.parse(loginSessionTimeFromStorage);
        // console.log("loginSessionDatetime: ", loginSessionDatetime);
        // console.log("flag: ", userId == loginSessionDatetime.userId, username.localeCompare(loginSessionDatetime.username) == 0);
        if(userId == loginSessionDatetime.userId && username.localeCompare(loginSessionDatetime.username) == 0){
            let datetimeStr = (loginSessionDatetime.date + " " + loginSessionDatetime.time);
            let datetimeFormat = (dateFormatStr + " " + timeFormatStr);
            // console.log("getLoginSessionTime: ", loginSessionDatetime, datetimeStr, datetimeFormat);
            let interactMoment: Moment = moment(datetimeStr, datetimeFormat);
            return interactMoment;
        }
    }
    return null;
}

const expireLoginSessionMinute = 30;
export const isLoginSessionOvertime = (userId: number, username: string) => {
    // console.log("isLoginSessionOvertime: ", userId, username);
    let interactionTime: Moment | null = getLoginSessionTime(userId, username);
    if(interactionTime != null){
        // console.log("interactionTime: ", interactionTime, interactionTime.format("yyyyMMDD HHmmss"));
        let diff = moment().diff(interactionTime, "minutes", true);
        // console.log("diff: ", diff);
        if(diff <= expireLoginSessionMinute){
            return false
        }
    }
    return true;
}

export const checkLoginSessionOvertime = (userId: number, username: string) => {
    // console.log("checkLoginSessionOvertime: ", userId, username);
    let isOver = isLoginSessionOvertime(userId, username);
    if(!isOver){
        saveCurrentLoginSessionTime(userId, username);
    }
    return isOver;
}