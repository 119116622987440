import axios from "axios";
import ApiConfig from "../constants/ApiConfig";
import { ApiResponse } from "../models/MasterModel";

export const addThesis = async (thesisData: any) => {
    let url = `${ApiConfig.backendUrl}/thesisController/addThesis.php`
    let res = await axios.post(url, thesisData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const editThesis = async (thesisData: any) => {
    let url = `${ApiConfig.backendUrl}/thesisController/editThesis.php`
    let res = await axios.post(url, thesisData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const deleteThesis = async (thesisId: any, userId: any) => {
    let url = `${ApiConfig.backendUrl}/thesisController/deleteThesis.php`
    let res = await axios.post(url, {thesis_id: thesisId, process_user_id: userId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const getThesisByParam = async (params: any) => {
    let url = `${ApiConfig.backendUrl}/thesisController/getThesisByParam.php`
    let res = await axios.post(url, params, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const getThesisById = async (id: any) => {
    let url = `${ApiConfig.backendUrl}/thesisController/getThesisById.php`
    let res = await axios.post(url, {thesisId: id}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const changeThesisStatus = async (data: any) => {
    let url = `${ApiConfig.backendUrl}/thesisController/changeStatus.php`
    let res = await axios.post(url, data, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const checkDuplicateTitle = async (tilte: string) => {
    let url = `${ApiConfig.backendUrl}/thesisController/checkDuplicateTitle.php`
    let res = await axios.post(url, {title: tilte}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}