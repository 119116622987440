import { Button, Form, List, Modal, Popconfirm, Select } from "antd";
import { useEffect, useState } from "react";
import styles from "../RequestDocumentUploadList.module.css";
import { getRequestDocumentList, getRequestDocumentUploadFileList } from "../../../../../services/RequestDocumentService";
import { RequestDocumentType } from "../../../../../models/MasterModel";
import { DownloadOutlined } from "@ant-design/icons";
import { RequestDocumentUploadFile } from "../../../../../models/RequestDocumentModel";
import { useAppSelector } from "../../../../../store";
import { LanguageState } from "../../../../../store/language/languageSlice";
import { labelLang } from "../../../../../helper/HTMLHelper";
import CustomLabel from "../../../../../components/CustomLabel";

interface AddNewRequestDocumentModalProps {
    userId: number;
    previewVisible: boolean;
    onCancel: any;
    onOk: any;
    onDownload: any;
}
interface AddNewRequestDocument{
    selectedRequirementDocument: string;
}
const AddNewRequestDocumentModal = (props: AddNewRequestDocumentModalProps) => {

    const { Option } = Select;
    const [form] = Form.useForm();
    const [previewVisible, setPreviewVisible] = useState<boolean>(props.previewVisible);
    const [userId, setUserId] = useState<number>(props.userId);
    
    const [selectedDocumentType, setSelectedDocumentType] = useState<string>("ALL");
    const [documentTypeComboList, setDocumentTypeComboList] = useState<RequestDocumentType[]>([]);

    const [requestDocumentUploadFileList, setRequestDocumentUploadFileList] = useState<RequestDocumentUploadFile[]>([]);
    const [showFileList, setShowFileList] = useState<boolean>(false);

    const downloadDisabled = selectedDocumentType === 'ALL'
    useEffect(() => {
        setPreviewVisible(props.previewVisible);
        loadComboData();
    }, [props.previewVisible]);

    useEffect(() => {
        setUserId(props.userId);
    }, [props.userId])

    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])

    useEffect(() => {
        if(selectedDocumentType !== 'ALL'){
            loadRequestDocumentUploadFileList(selectedDocumentType);
        }
    }, [selectedDocumentType])

    useEffect(() => {
        if(requestDocumentUploadFileList.length > 0){
            console.log();
            setShowFileList(true);
            setTimeout(() => {
                setShowFileList(false);
            }, 5000)
        }
    }, [requestDocumentUploadFileList])
    
    const handleCancel = () => {
        setShowFileList(false);
        if(props.onCancel){
            props.onCancel();
        }
    }
    const loadComboData = async () => {
        let documentTypeList = await getRequestDocumentList();
        setDocumentTypeComboList(documentTypeList);
    }
    const loadRequestDocumentUploadFileList = async (docTypeId: any) => {
        let resData = await getRequestDocumentUploadFileList(docTypeId);
        if(resData.status) {
            setRequestDocumentUploadFileList(resData.data);
            // console.log(resData.data);
            // setShowTable(false);
        }
    }
    
    const onDocumentTypeChange = (value: string) => {
        // console.log('onDocumentTypeChange: ', value);
        setShowFileList(false);
        setSelectedDocumentType(value);
    }

    const addNewReqDoc = () => {
        form.submit();
    }

    const renderOption = () => {
        let optionList = [];
        // optionList.push(<Option value={`ALL`}>รูปแบบคำร้อง</Option>);
        for(let i = 0; i < documentTypeComboList.length; i++){
            let dt = documentTypeComboList[i];
            let name = labelLang(dt.name, dt.name_en, currentLang);
            optionList.push(<Option value={`${dt.id}`}>{name}</Option>);
        }
        return optionList;
    }
    const onDownLoadClick = () => {
        props.onDownload(selectedDocumentType);
    }

    const onSubmit = (value: AddNewRequestDocument) => {
        if(props.onOk){
            let addValue: any = {
                create_user_id: userId,
                req_doc_type_id: selectedDocumentType
            }
            props.onOk(addValue);
        }
    }

    const renderFileList = () => {
        return (
            <div style={{width: 300}}>
                <List
                    header={<div style={{fontSize: 16, fontWeight: 'bolder'}}><CustomLabel en="Relate Document" th="รายชื่อเอกสารที่ต้องส่ง"/></div>}
                    dataSource={requestDocumentUploadFileList}
                    renderItem={(item: RequestDocumentUploadFile) => (
                        <List.Item>
                            {labelLang(item.filename, item.filename_en == null ? item.filename : item.filename_en, currentLang)}
                        </List.Item>
                    )}
                />
            </div>
        )
    }
    return (
        <Modal
            visible={previewVisible}
            title={labelLang("เพิ่มรายการส่งไฟล์ใหม่", "New Reqeust", currentLang)}
            onCancel={handleCancel}
            onOk={addNewReqDoc}
            okText={labelLang("สร้างคำร้อง", "Create Reqeust", currentLang)}
            cancelText={labelLang("ยกเลิก", "Cancel", currentLang)}
        >
            <div className={styles['modal-main-container']}>
                <Form
                    form={form}
                    onFinish={onSubmit}
                >
                    <Popconfirm
                        visible={showFileList}
                        placement="right"
                        title={renderFileList()}
                        cancelText="Close"
                        okButtonProps={{hidden: true}}
                        icon={null}
                        onCancel={() => {setShowFileList(false)}}
                    >
                        <Form.Item
                            name={"selectedRequirementDocument"}
                            rules={[{ required: true, message: labelLang("กรูณาเลือกประเภทคำร้อง!", "Reqeust Document is require", currentLang) }]}
                            style={{marginBottom: 8}}
                        >
                            <Select
                                showSearch
                                placeholder={labelLang("เลือกประเภทคำร้อง", "Select Reqeust Document", currentLang)}
                                optionFilterProp="children"
                                onChange={onDocumentTypeChange}
                                // value={selectedDocumentType}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                                className={styles['select-full']}
                                onMouseEnter={() => {
                                    setShowFileList(true);
                                }}
                                onMouseLeave={() => {
                                    setTimeout(() => {
                                        setShowFileList(false);
                                    }, 5000)
                                }}
                            >
                                {renderOption()}
                            </Select>
                        </Form.Item>
                    </Popconfirm>
                    <Form.Item
                        name={"downloadBtn"}
                        style={{height: 50, marginBottom: 8}}
                    >
                            <Button 
                                type={"dashed"} style={{float: "right", height: 50}} 
                                onClick={onDownLoadClick} 
                                disabled={downloadDisabled}
                                onMouseOver={() => {
                                    setShowFileList(true);
                                }}
                                onMouseLeave={() => {
                                    setTimeout(() => {
                                        setShowFileList(false);
                                    }, 5000)
                                }}
                            >
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <DownloadOutlined style={{fontSize: 20, marginRight: 0}} />
                                    {labelLang("เอกสารแนบ", "Attach Document", currentLang)}
                                </div>
                            </Button>
                        {/* <Button type={"link"} style={{float: "right", height: 50, marginRight: 5}} onClick={onDownLoadClick} disabled={downloadDisabled}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <DownloadOutlined style={{fontSize: 20, marginRight: 0}} />
                                แบบคำร้อง
                            </div>
                        </Button> */}
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
}

export default AddNewRequestDocumentModal;