import { useEffect, useState  } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setPageName, setBack2Page } from "../../../../store/pagination/paginationSlice";
import { Button, DatePicker, Form, Input, message, Modal, notification, Select, Spin, Switch, Table, Tabs, Upload } from "antd";
import { CheckCircleTwoTone, CloudUploadOutlined, DownloadOutlined, InboxOutlined, PlusOutlined, RollbackOutlined, SaveOutlined, StopOutlined, StopTwoTone } from "@ant-design/icons";
import axios from "axios";
import styles from './ThesisUploadForm.module.css';
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import type { UploadFile } from 'antd/es/upload/interface';
import ApiConfig from "../../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import type { UploadProps } from 'antd';
import { ThesisData, ThesisStatusLog } from "../../../../models/ThesisModel";
import { changeThesisStatus, checkDuplicateTitle, editThesis, getThesisById } from "../../../../services/ThesisService";
import { convertDBDate2Show, convertDBTime2Show } from "../../../../helper/DateTimeHelper";
import { ApiResponse } from "../../../../models/MasterModel";
import { createFileList } from "@testing-library/user-event/dist/types/utils";
// import { authen } from "../../../../services/AuthenService";
import { SessionUserState } from "../../../../store/authen/authenSlice";
import { Permission, PermissionName } from "../../../../models/Login";
import { getAuthPermission } from "../../../../services/AuthenService";
import { RcFile } from "antd/lib/upload";
import { getActivateSettingById } from "../../../../services/ActivateSettingService";
import { IActivateSetting } from "../../../../models/ActivateSetting";
import { LanguageState } from "../../../../store/language/languageSlice";
import { labelLang } from "../../../../helper/HTMLHelper";
// import Path from "path";

interface ThesisDataForm{
    thesis_name: string;
    thesis_abstract: string;
    is_ithesis: boolean;
    is_eng_thesis: boolean;
}
const ThesisUploadForm = () => {
    const dispatch = useAppDispatch();
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const { id } = useParams<string>();
    const { Option } = Select;
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [markerList, setMarkerList] = useState<Array<any>>([]);
    const [photoDate, setPhotoDate] = useState<any>(moment());
    const [refName, setRefName] = useState<string>("");
    const [thesisName, setThesisName] = useState<string>("");
    const [thesisAbstract, setThesisAbstract] = useState<string>("");
    const [dataList, setDataList] = useState<any>([]);
    const [isUploadDisable, setUploadDisable] = useState<boolean>(false);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [downloadFileList, setDownloadFileList] = useState<any[]>([]);
    const [isDraftStatus, setIsDraftStatus] = useState<boolean>(false);
    const [isPendingStatus, setIsPendingStatus] = useState<boolean>(false);
    const [hasChangeStatusPerm, setHasChangeStatusPerm] = useState<boolean>(false);
    const [isIThesis, setIsIThesis] = useState<boolean>(false);
    const [isEngThesis, setIsEngThesis] = useState<boolean>(false);
    
    // const [loadedImageList, setLoadedImageList] = useState<TransactionMainImage[]>([]);
    const [showTable, setShowTable] = useState<boolean>(true);
    // const [windowSize, setWindowSize] = useState(getWindowSize());

    const [thesisData, setThesisData] = useState<ThesisData | undefined>();

    const [deliverDocFileList, setDeliverDocFileList] = useState<UploadFile[]>([]);
    const [approveThesisDocFileList, setApproveThesisDocFileList] = useState<UploadFile[]>([]);
    const [abstractTHDocFileList, setAbstractTHDocFileList] = useState<UploadFile[]>([]);
    const [abstractENDocFileList, setAbstractENDocFileList] = useState<UploadFile[]>([]);
    const [akkaraVisutDocFileList, setAkkaraVisutDocFileList] = useState<UploadFile[]>([]);
    const [turnItInDocFileList, setTurnItInDocFileList] = useState<UploadFile[]>([]);
    const [fullThesisDocFileList, setFullThesisDocFileList] = useState<UploadFile[]>([]);
    const [proceedingDocFileList, setProceedingDocFileList] = useState<UploadFile[]>([]);
    const [removeFileTmp, setRemoveFileTmp] = useState<UploadFile | null>();
    const [confirm2RemoveVisible, setConfirm2RemoveVisible] = useState<boolean>(false);
    const [removeFileName, setRemoveFileName] = useState<string>("");
    const [removedCallback, setRemovedCallback] = useState<any>();
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [currentPermission, setCurrentPermission] = useState<any>(null);
    const [canApprove, setCanApprove] = useState<boolean>(false);
    const [canReject, setCanReject] = useState<boolean>(false);
    const [confirmDialog, setConfirmDialog] = useState<any>(null);

    const [selectedStatus, setSelectedStatus] = useState<string | null>();
    const [attachMessage, setAttachMessage] = useState<string>("");

    const [settingData, setSettingData] = useState<IActivateSetting | null>(null);
    
    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])

    const columns = [
        {
            title: labelLang("ผู้ทำรายการ", "Operator", currentLang),
            key: 'name',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: ThesisStatusLog) => {return `${data.process_user_firstname} ${data.process_user_lastname}`},
        },
        {
            title: labelLang("บทบาท", "Role", currentLang),
            dataIndex: 'role_name',
            key: 'role_name',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang("ข้อความแนบ", "Message", currentLang),
            dataIndex: 'attach_message',
            key: 'attach_message',
            // sorter: (a: ProcessTransaction, b: ProcessTransaction) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang("วันที่ดำเนินการ", "Log Date", currentLang),
            // dataIndex: 'process_date',
            key: 'log_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: ThesisStatusLog) => {return `${convertDBDate2Show(data.log_date)} ${convertDBTime2Show(data.log_time)} `},
        },
        {
            title: labelLang("สถานะเดิม", "Previous Status", currentLang),
            dataIndex: 'old_status',
            key: 'old_status',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang("สถานะปัจจุบัน", "Current Status", currentLang),
            dataIndex: 'current_status',
            key: 'current_status',
            // sorter: (a: ProcessTransaction, b: ProcessTransaction) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        // {
        //   title: 'Action',
        //   key: 'action',
        //   render: (text: string, record: any) => (
        //     <Space size="middle">
        //         <Link to={`/app/map/${record.id}`}><EnvironmentTwoTone /></Link>
        //         <Link to={`/app/upload/${record.id}`}><EditTwoTone /></Link>
        //         <a><DeleteTwoTone /></a>
        //     </Space>
        //   ),
        // },
    ]
    const fileColumns = [
        {
            title: labelLang("ชื่อเอกสาร", "Filename", currentLang),
            dataIndex: 'filename',
            key: 'filename',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang("ชนิดเอกสาร", "Document Type", currentLang),
            dataIndex: 'file_type',
            key: 'file_type',
            // sorter: (a: any, b: any) => a.age - b.age,
            // render: (data: any) => {data.age},
        },
        {
            title: labelLang("วันที่อัปโหลด", "Upload Date", currentLang),
            // dataIndex: 'upload_date',
            key: 'upload_date',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: any) => {return `${convertDBDate2Show(data.upload_date)} ${convertDBTime2Show(data.upload_time)}`},
        },
        {
            title: labelLang("ดาวน์โหลด", "Download", currentLang),
            key: 'download',
            // sorter: (a: any, b: any) => a.age - b.age,
            render: (data: any) => {return (
                <Button icon={<DownloadOutlined />} onClick={() => {adminDownload(data)}}>Download</Button>
            )},
        }
    ]
    useEffect(() => {
        dispatch(setPageName({th: "แบบฟอร์มอัปโหลดเอกสารวิทยานิพนธ์", en: "Thesis Upload"}));
        dispatch(setBack2Page("/management/thesis/list"));
        setDataList([])
        setDownloadFileList([])
        loadThesisData();
        loadSetting();
        // setDownloadFileList(mockFileData);
        // setFileList(mockFileList)
        
        // function handleWindowResize() {
        //     setWindowSize(getWindowSize());
        // }
    
        // window.addEventListener('resize', handleWindowResize);
    
        // return () => {
        //     window.removeEventListener('resize', handleWindowResize);
        // };
    }, [])

    useEffect(() => {
        setIsOwner(false);
        if(thesisData){
            setThesisName(thesisData.thesis_name);
            setThesisAbstract(thesisData.thesis_abstract);
            setDataList(thesisData.status_logs);
            let thesisFormValue: ThesisDataForm = {
                thesis_name: thesisData.thesis_name,
                thesis_abstract: thesisData.thesis_abstract,
                is_ithesis: thesisData.is_ithesis === "YES",
                is_eng_thesis: thesisData.is_eng_thesis === "YES",
            }
            setIsIThesis(thesisFormValue.is_ithesis);
            setIsEngThesis(thesisFormValue.is_eng_thesis);
            form.setFieldsValue(thesisFormValue);
            let fileTableList: any[] = [];
            
            if(thesisData.deliver_doc_path && thesisData.deliver_doc_path.trim() != ""){
                defauleFileList("deliverDoc", "ใบนำส่งวิทยานิพนธ์.pdf", setDeliverDocFileList, thesisData.deliver_doc_path);
                fileTableList.push({
                    filename: "ใบนำส่งวิทยานิพนธ์.pdf", 
                    upload_date: thesisData.deliver_doc_upload_date, 
                    upload_time: thesisData.deliver_doc_upload_time, 
                    url: thesisData.deliver_doc_path,
                    file_type: labelLang("ใบนำส่งวิทยานิพนธ์", "Document for Submission", currentLang),
                });
            }
            if(thesisData.approve_doc_path && thesisData.approve_doc_path.trim() != ""){
                defauleFileList("approveDoc", "หน้าอนุมัติวิทยานิพนธ์.pdf", setApproveThesisDocFileList, thesisData.approve_doc_path);
                fileTableList.push({
                    filename: "หน้าอนุมัติวิทยานิพนธ์.pdf", 
                    upload_date: thesisData.approve_doc_upload_date, 
                    upload_time: thesisData.approve_doc_upload_time, 
                    url: thesisData.approve_doc_path,
                    file_type: labelLang("หน้าอนุมัติวิทยานิพนธ์", "Approved Page", currentLang),
                });
            }
            if(thesisData.abstract_th_doc_path && thesisData.abstract_th_doc_path.trim() != ""){
                defauleFileList("abstractTHDoc", "บทคัดย่อภาษาไทย.pdf", setAbstractTHDocFileList, thesisData.abstract_th_doc_path);
                fileTableList.push({
                    filename: "บทคัดย่อภาษาไทย.pdf", 
                    upload_date: thesisData.abstract_th_doc_upload_date, 
                    upload_time: thesisData.abstract_th_doc_upload_time, 
                    url: thesisData.abstract_th_doc_path,
                    file_type: labelLang("บทคัดย่อภาษาไทย", "Thai Abstract", currentLang),
                });
            }
            if(thesisData.abstract_en_doc_path && thesisData.abstract_en_doc_path.trim() != ""){
                defauleFileList("abstractENDoc", "บทคัดย่อภาษาอังกฤษ.pdf", setAbstractENDocFileList, thesisData.abstract_en_doc_path);
                fileTableList.push({
                    filename: "บทคัดย่อภาษาอังกฤษ.pdf", 
                    upload_date: thesisData.abstract_en_doc_upload_date, 
                    upload_time: thesisData.abstract_en_doc_upload_time, 
                    url: thesisData.abstract_en_doc_path,
                    file_type: labelLang("บทคัดย่อภาษาอังกฤษ", "English Abstract", currentLang),
                });
            }
            if(thesisData.akkaravisut_doc_path && thesisData.akkaravisut_doc_path.trim() != ""){
                defauleFileList("akkaravisutDoc", "ผลการตรวจสอบการลอกเลียนวรรณกรรม (อักขราวิสุทธิ์).pdf", setAkkaraVisutDocFileList, thesisData.akkaravisut_doc_path);
                fileTableList.push({
                    filename: "ผลการตรวจสอบการลอกเลียนวรรณกรรม (อักขราวิสุทธิ์).pdf", 
                    upload_date: thesisData.akkaravisut_doc_upload_date, 
                    upload_time: thesisData.akkaravisut_doc_upload_time, 
                    url: thesisData.akkaravisut_doc_path,
                    file_type: labelLang("ผลการตรวจสอบการลอกเลียนวรรณกรรม (อักขราวิสุทธิ์)", "Plagiarism Check(Akravisut)", currentLang),
                });
            }
            if(thesisData.turnitin_doc_path && thesisData.turnitin_doc_path.trim() != ""){
                defauleFileList("turnItInDoc", "ผลการตรวจสอบการลอกเลียนวรรณกรรม (Turnitin).pdf", setTurnItInDocFileList, thesisData.turnitin_doc_path);
                fileTableList.push({
                    filename: "ผลการตรวจสอบการลอกเลียนวรรณกรรม (Turnitin).pdf", 
                    upload_date: thesisData.turnitin_doc_upload_date, 
                    upload_time: thesisData.turnitin_doc_upload_time, 
                    url: thesisData.turnitin_doc_path,
                    file_type: labelLang("ผลการตรวจสอบการลอกเลียนวรรณกรรม (Turnitin)", "Plagiarism Check(Turnitin)", currentLang),
                });
            }
            if(thesisData.full_thesis_doc_path && thesisData.full_thesis_doc_path.trim() != ""){
                defauleFileList("fullThesisDoc", "วิทยานิพนธ์ฉบับเต็ม.pdf", setFullThesisDocFileList, thesisData.full_thesis_doc_path);
                fileTableList.push({
                    filename: "วิทยานิพนธ์ฉบับเต็ม.pdf", 
                    upload_date: thesisData.full_thesis_doc_upload_date, 
                    upload_time: thesisData.full_thesis_doc_upload_time, 
                    url: thesisData.full_thesis_doc_path,
                    file_type: labelLang("เอกสารวิทยานิพนธ์ฉบับเต็ม", "Full Thesis (Full)", currentLang),
                });
            }
            // if(thesisData.proceeding_doc_path && thesisData.proceeding_doc_path.trim() != ""){
            //     defauleFileList("proceedingDoc", "เอกสารตีพิมพ์.pdf", setProceedingDocFileList, thesisData.proceeding_doc_path);
            // }
            if(thesisData.proceeding_files && thesisData.proceeding_files.length > 0){
                let proceedingFiles = thesisData.proceeding_files;
                let files: any[] = proceedingFiles.map((value) => {
                    let filename = value.file_path.replace(/^.*[\\\/]/, '')
                    return {
                        uid: value.id,
                        name: filename,
                        url: `${ApiConfig.uploadedAssets}${value.file_path}`,
                        status: 'done',
                        shortUrl: `${value.file_path}`
                    }
                })
                let proceedingDownloadList: any[] = proceedingFiles.map((value) => {
                    let filename = value.file_path.replace(/^.*[\\\/]/, '')
                    return {
                        filename: filename, 
                        upload_date: value.upload_date, 
                        upload_time: value.upload_time, 
                        url: value.file_path,
                        file_type: labelLang("เอกสารการตีพิมพ์", "Plublished Paper", currentLang),
                    }
                })
                fileTableList.push(...proceedingDownloadList);
                setProceedingDocFileList(files);
            }
            setIsDraftStatus(thesisData.status == "DRAFT");
            setIsPendingStatus(thesisData.status == "PENDING")
            if(authen && authen.sessionUser){
                setIsOwner(thesisData.upload_user_id == authen.sessionUser.currentUser.id);
            }
            loadUserPermission();
            setDownloadFileList(fileTableList);
            // setFileTableData(fileTableData);
        }
    }, [thesisData])

    useEffect(() => {
        setShowTable(!isOwner || !isDraftStatus);
    }, [isOwner, isDraftStatus])

    useEffect(() => {
        multipleTmp = [];
    }, [proceedingDocFileList])

    const loadSetting = async () => {
        let res = await getActivateSettingById("02");
        if(res.status){
            console.log("loadSetting: ", res.data);
            let resData: IActivateSetting = res.data;
            setSettingData(resData);
        }
    }
    const adminDownload = (data: any) => {
        const callback = () => {
            window.open(`${ApiConfig.backendUrl}/downloadFile.php?path=${data.url}`, "_blank");
            setConfirmDialog(null);
        }
        let newConfirmDialog = (
            <Modal
                visible={true}
                title={"ดาวน์โหลดเอกสาร"}
                onCancel={onRemoveFileCancel}
                onOk={callback}
                okText={"Download"}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                        ต้องการดาวน์โหลดไฟล์ "{data.filename}" หรือไม่?
                    </div>
                </div>
            </Modal>
        )
        setConfirmDialog(newConfirmDialog);
    }

    const defauleFileList = (uid:string, filename: string, setFileList: any, url: string) => {
        let filedata = createDefaultFile(uid, filename, url);
        setFileList([filedata]);
    }
    // useEffect(() => {
    //     if(fileList){
    //         setUploadDisable(fileList.length > 0)
    //     }
    // }, [fileList])
    const loadUserPermission = async () => {
        console.log("loadUserPermission");
        if(authen && authen.sessionUser){
            console.log("loadUserPermission auth");
            let permNameList = [PermissionName.THESIS_APPROVE, PermissionName.THESIS_REJECT];
            let userId = authen.sessionUser.currentUser.id;
            let resData: ApiResponse = await getAuthPermission(userId, permNameList);
            if(resData.status){
                let data: {[key: string]: string} = resData.data;
                console.log("Permission Data: ", data);
                setCurrentPermission(data);
                let hasApprovePerm = data[PermissionName.THESIS_APPROVE] != undefined && data[PermissionName.THESIS_APPROVE] != null;
                let hasRejectPerm = data[PermissionName.THESIS_REJECT] != undefined && data[PermissionName.THESIS_REJECT] != null;
                setCanApprove(hasApprovePerm);
                setHasChangeStatusPerm(hasApprovePerm || hasRejectPerm);
            }
        }

    }
    const uploadButton = (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const loadThesisData = async () => {
        if(id){
            let resData = await getThesisById(id);
            if(resData.status){
                setThesisData(resData.data);
            }
        }

    }
    const inputTextChange = (e: any, setState: any) => {
        setState(e.target.value);
    }
    const createDefaultFile = (uid: string, filename: string, url: string) => {
        return {
          uid: uid,
          name: filename,
          status: 'done',
          url: `${ApiConfig.uploadedAssets}${url}`,
          shortUrl: `${url}`
        }
    }
    let multipleTmp: any[] = [];
    const onRemoveClicked = (e: any, myFileList: any, setMyFileList: any, removeUrl: string, isMultiple: any) => {
        const callback = async () => {
            let fmData = new FormData
            const config = {
              headers: { "content-type": "multipart/form-data" },
            };
            fmData.append("uid", e.uid);
            fmData.append("thesis_id", id ? id : "0");
            try {
                const res = await axios.post(
                    `${ApiConfig.backendUrl}/thesisController/${removeUrl}.php`,
                    fmData,
                    config
                );
                console.log("server res: ", res);
                if(res.status == 200){
                    // let data = res.data.data;
                    if(isMultiple){
                        const index = myFileList.indexOf(e);
                        const newFileList = myFileList.slice();
                        newFileList.splice(index, 1);
                        setMyFileList(newFileList);
                    }else{
                        setMyFileList([]);
                    }
                }
            } catch (err) {
                console.log("Eroor: ", err);
                const error = new Error("Some error");
            }
            setConfirmDialog(null);
        }
        let newConfirmDialog = (
            <Modal
                visible={true}
                title={"ยืนยันการลบเอกสาร"}
                onCancel={onRemoveFileCancel}
                onOk={callback}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                        ยืนยันการลบไฟล์ "{e.name}" หรือไม่?
                    </div>
                </div>
            </Modal>
        )
        setConfirmDialog(newConfirmDialog);
        setRemoveFileName(e.name)
        setRemoveFileTmp(e);
        setConfirm2RemoveVisible(true);
        // setRemovedCallback(callback);
    }
    const createProps = (myFileList: any[], setMyFileList: any, uploadName: string, removeName: string, isMultiple: boolean = false) => {
        const props: UploadProps = {
            // name: 'file',
            fileList: myFileList,
            multiple: isMultiple,
            openFileDialogOnClick: myFileList.length == 0 || isMultiple,
            disabled: !isDraftStatus || !isOwner,
            beforeUpload: async (file: File) => {
                console.log("beforeUpload: ", file);
                let isPdf = file.type == "application/pdf";
                if(!isPdf){
                    notification.error({message: "การอัพโหลดไฟล์ไม่สำเร็จ", description: "การอัพโหลดจำกัดเฉพาะไฟล์ PDF เท่านั้น"})
                }
                return isPdf && (myFileList.length == 0 || isMultiple);
            },
            customRequest: async (options: any) => {
                const { onSuccess, onError, file, onProgress } = options;
                const fmData = new FormData();
                console.log("custom upload: ", file);
                const config = {
                  headers: { "content-type": "multipart/form-data" },
                  onUploadProgress: (event: any) => {
                    const percent = Math.floor((event.loaded / event.total) * 100);
                    // setProgress(percent);
                    if (percent === 100) {
                    //   setTimeout(() => setProgress(0), 1000);
                    }
                    onProgress({ percent: (event.loaded / event.total) * 100 });
                  }
                };
                fmData.append("file", file);
                fmData.append("thesis_id", id ? id : "0");
                try {
                    const res = await axios.post(
                        `${ApiConfig.backendUrl}/thesisController/${uploadName}.php`,
                        fmData,
                        config
                    );
                    console.log("server res: ", res);
                    if(res.status == 200){
                        let data = res.data.data;
                        let newFile = createDefaultFile(uploadName, data.filename, data.uploadFilePath);
                        onSuccess(file);
                        if(isMultiple){
                            multipleTmp.push(newFile);
                            setMyFileList([...myFileList, ...multipleTmp]);
                        }else{
                            setMyFileList([newFile])
                        }
                    }
                } catch (err) {
                    console.log("Eroor: ", err);
                    const error = new Error("Some error");
                    onError({ err });
                }
            },
            onChange(info) {
              const { status } = info.file;
              if (status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (status === 'done') {
                let newFileList = [info.file];
                setMyFileList(newFileList);
                notification.success({message: "อัพโหลดไฟล์สำเร็จ", description: `${info.file.name}.`});
              } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            },
            onRemove(e) {
                console.log("remove: ", e);
                // return false;
                onRemoveClicked(e, myFileList, setMyFileList, removeName, isMultiple);
            },
            onDrop(e) {
              console.log('Dropped files', e.dataTransfer.files);
              if(!(myFileList.length == 0 || isMultiple)){
                notification.error({message: 'อัพโหลดไม่สำเร็จ', description: "มีไฟล์อัพโหลดอยู่แล้ว"});
              }
            },
            onDownload(e) {
                let file: any = e;
                const callback = () => {
                    window.open(`${ApiConfig.backendUrl}/thesisController/downloadThesis.php?path=${file.shortUrl}`, "_blank");
                    setConfirmDialog(null);
                }
                let newConfirmDialog = (
                    <Modal
                        visible={true}
                        title={"ดาวน์โหลดเอกสาร"}
                        onCancel={onRemoveFileCancel}
                        onOk={callback}
                        okText={"Download"}
                    >
                        <div className={styles['modal-main-container']}>
                            <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                                ต้องการดาวน์โหลดไฟล์ "{e.name}" หรือไม่?
                            </div>
                        </div>
                    </Modal>
                )
                setConfirmDialog(newConfirmDialog);
            },
            // disabled: isUploadDisable,
            showUploadList: {
                showDownloadIcon: true,
                showRemoveIcon: true,
            },
            style: {minWidth: 500, marginLeft: 2, marginRight: 2, opacity: ((thesisData?.status != "DRAFT") ? 0.8 : 1), borderColor: myFileList.length > 0 ? "#71DE04" : ""},
        };
        return props;
    }
    
    const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        console.log('params', pagination, filters, sorter, extra);
    }

    const renderFileTable = () => {
        if(showTable){
            return (
            <div className={styles['list-container']}>
                <Table columns={fileColumns} dataSource={downloadFileList} onChange={onChange} pagination={{pageSize: 5}} style={{width: '100%'}}/>
            </div>
            );
        }
    }

    const onFinish = async (values: ThesisDataForm) => {
        console.log('Success:', values);
        let dupThesisRes = await checkDuplicateTitle(values.thesis_name);
        if(dupThesisRes.status){
            let dupThesisList: ThesisData[] = dupThesisRes.data;
            if(dupThesisList.length > 0){
                notification.warning({message: "ตรวจพบชื่อวิทยานิพนธ์ซ้ำ"})
            }else{
                updateThesis(values);
            }
        }
    };

    const updateThesis = async (values: ThesisDataForm) => {
        let updateData = {
            id: id,
            thesis_name: values.thesis_name,
            thesis_abstract: values.thesis_abstract.trim() === "" ? null : values.thesis_abstract.trim(),
            is_ithesis: values.is_ithesis ? "YES" : "NO",
            is_eng_thesis: values.is_eng_thesis ? "YES" : "NO",
        }
        let resData: ApiResponse = await editThesis(updateData);
        if(resData.status){
            if(values.thesis_abstract.trim() !== ""){
                notification.success({message: "แก้ไขข้อมูลวิทยานิพนธ์สำเร็จ", description: resData.message})
            }
            setDataList([]);
            setDownloadFileList([]);
            loadThesisData();
            loadSetting();
        }else{
            notification.error({message: "แก้ไขข้อมูลวิทยานิพนธ์ไม่สำเร็จ", description: resData.message})
        }
    }
  
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const onSaveThesis = () => {
        
    }
    const onRemoveFileOK = () => {
        setConfirm2RemoveVisible(false);
    }
    const onRemoveFileCancel = () => {
        setConfirm2RemoveVisible(false);
        setConfirmDialog(null);
    }
    const renderUploadSection = () => {
        if(!showTable){
            return(
                <div className={styles['upload-container']}>
                    <div className={styles['input-container']}>
                        <div className={styles['input-container']}>
                            <Dragger {...createProps(deliverDocFileList, setDeliverDocFileList, "uploadDeliverDocumentFile", "removeDeliverDocumentFile")}>
                                <p className="ant-upload-text-label">{labelLang("ใบนำส่งวิทยานิพนธ์", "Document for Submission", currentLang)}</p>
                                <p className="ant-upload-drag-icon">
                                    {deliverDocFileList.length > 0 ? <CheckCircleTwoTone twoToneColor={"#71DE04"} /> : <InboxOutlined />}
                                </p>
                                <p className="ant-upload-text">คลิก หรือลากไฟล์มาวางบริเวณนี้เพื่ออัปโหลดไฟล์</p>
                                <p className="ant-upload-hint">
                                    อัพโหลดได้เพียง 1 ไฟล์
                                </p>
                            </Dragger>
                        </div>
                        <div className={styles['input-container']}>
                            <Dragger {...createProps(approveThesisDocFileList, setApproveThesisDocFileList, "uploadApproveDocumentFile", "removeApproveDocumentFile")}>
                                <p className="ant-upload-text-label">{labelLang("หน้าอนุมัติวิทยานิพนธ์", "Approved Page", currentLang)}</p>
                                <p className="ant-upload-text"></p>
                                <p className="ant-upload-drag-icon">
                                    {approveThesisDocFileList.length > 0 ? <CheckCircleTwoTone twoToneColor={"#71DE04"} /> : <InboxOutlined />}
                                </p>
                                <p className="ant-upload-text">คลิก หรือลากไฟล์มาวางบริเวณนี้เพื่ออัปโหลดไฟล์</p>
                                <p className="ant-upload-hint">
                                    อัพโหลดได้เพียง 1 ไฟล์
                                </p>
                            </Dragger>
                        </div>
                    </div>
                    <div className={styles['input-container']}>
                        <div className={styles['input-container']}>
                            <Dragger {...createProps(abstractTHDocFileList, setAbstractTHDocFileList, "uploadAbstractTHDocumentFile", "removeAbstractTHDocumentFile")}>
                                <p className="ant-upload-text-label">{labelLang("บทคัดย่อภาษาไทย", "Thai Abstract", currentLang)}</p>
                                <p className="ant-upload-drag-icon">
                                    {abstractTHDocFileList.length > 0 ? <CheckCircleTwoTone twoToneColor={"#71DE04"} /> : <InboxOutlined />}
                                </p>
                                <p className="ant-upload-text">คลิก หรือลากไฟล์มาวางบริเวณนี้เพื่ออัปโหลดไฟล์</p>
                                <p className="ant-upload-hint">
                                    อัพโหลดได้เพียง 1 ไฟล์
                                </p>
                            </Dragger>
                        </div>
                        <div className={styles['input-container']}>
                            <Dragger {...createProps(abstractENDocFileList, setAbstractENDocFileList, "uploadAbstractENDocumentFile", "removeAbstractENDocumentFile")}>
                                <p className="ant-upload-text-label">{labelLang("บทคัดย่อภาษาอังกฤษ", "English Abstract", currentLang)}</p>
                                <p className="ant-upload-text"></p>
                                <p className="ant-upload-drag-icon">
                                    {abstractENDocFileList.length > 0 ? <CheckCircleTwoTone twoToneColor={"#71DE04"} /> : <InboxOutlined />}
                                </p>
                                <p className="ant-upload-text">คลิก หรือลากไฟล์มาวางบริเวณนี้เพื่ออัปโหลดไฟล์</p>
                                <p className="ant-upload-hint">
                                    อัพโหลดได้เพียง 1 ไฟล์
                                </p>
                            </Dragger>
                        </div>
                    </div>
                    {
                        !isIThesis &&
                        <div className={styles['input-container']}>
                            <div className={styles['input-container']}>
                                <Dragger {...createProps(akkaraVisutDocFileList, setAkkaraVisutDocFileList, "uploadAkkaravisutDocumentFile", "removeAkkaravisutDocumentFile")} disabled={isIThesis}>
                                    <p className="ant-upload-text-label">{labelLang("ผลการตรวจสอบการลอกเลียนวรรณกรรม (อักขราวิสุทธิ์)", "Plagiarism Check(Akravisut)", currentLang)}</p>
                                    <p className="ant-upload-drag-icon">
                                        {
                                            isIThesis ? <StopTwoTone twoToneColor={"red"} /> :
                                            akkaraVisutDocFileList.length > 0 ? <CheckCircleTwoTone twoToneColor={"#71DE04"} /> : <InboxOutlined />
                                        }
                                    </p>
                                    <p className="ant-upload-text">คลิก หรือลากไฟล์มาวางบริเวณนี้เพื่ออัปโหลดไฟล์</p>
                                    <p className="ant-upload-hint">
                                        อัพโหลดได้เพียง 1 ไฟล์
                                    </p>
                                </Dragger>
                            </div>
                            <div className={styles['input-container']}>
                                <Dragger {...createProps(fullThesisDocFileList, setFullThesisDocFileList, "uploadFullThesisDocumentFile", "removeFullThesisDocumentFile")} disabled={isIThesis}>
                                    <p className="ant-upload-text-label">{labelLang("เอกสารวิทยานิพนธ์ฉบับเต็ม", "Full Thesis (Full)", currentLang)}</p>
                                    <p className="ant-upload-drag-icon">
                                        {
                                            isIThesis ? <StopTwoTone twoToneColor={"red"} /> :
                                            fullThesisDocFileList.length > 0 ? <CheckCircleTwoTone twoToneColor={"#71DE04"} /> : <InboxOutlined />
                                        }
                                    </p>
                                    <p className="ant-upload-text">คลิก หรือลากไฟล์มาวางบริเวณนี้เพื่ออัปโหลดไฟล์</p>
                                    <p className="ant-upload-hint">
                                        อัพโหลดได้เพียง 1 ไฟล์
                                    </p>
                                </Dragger>
                            </div>
                        </div>
                    }
                    <div className={styles['input-container']}>
                        <div className={styles['input-container']}>
                            <Dragger {...createProps(proceedingDocFileList, setProceedingDocFileList, "uploadProceedingDocumentFile", "removeProceedingDocumentFile", true)}>
                                <p className="ant-upload-text-label">{labelLang("เอกสารการตีพิมพ์", "Plublished Paper", currentLang)}</p>
                                <p className="ant-upload-text"></p>
                                <p className="ant-upload-drag-icon">
                                    {proceedingDocFileList.length > 0 ? <CheckCircleTwoTone twoToneColor={"#71DE04"} /> : <InboxOutlined />}
                                </p>
                                <p className="ant-upload-text">คลิก หรือลากไฟล์มาวางบริเวณนี้เพื่ออัปโหลดไฟล์</p>
                                <p className="ant-upload-hint">
                                    อัพโหลดได้มากกว่า 1 ไฟล์
                                </p>
                            </Dragger>
                        </div>
                        <div className={styles['input-container']}>
                        {
                            isEngThesis && 
                                <Dragger {...createProps(turnItInDocFileList, setTurnItInDocFileList, "uploadTurnItInDocumentFile", "removeTurnItInDocumentFile")} disabled={!isEngThesis}>
                                    <p className="ant-upload-text-label">{labelLang("ผลการตรวจสอบการลอกเลียนวรรณกรรม (Turnitin)", "Plagiarism Check(Turnitin)", currentLang)}</p>
                                    <p className="ant-upload-text"></p>
                                    <p className="ant-upload-drag-icon">
                                        {
                                            !isEngThesis ? <StopTwoTone twoToneColor={"red"} /> 
                                            : turnItInDocFileList.length > 0 ? <CheckCircleTwoTone twoToneColor={"#71DE04"} /> 
                                            : <InboxOutlined />
                                        }
                                    </p>
                                    <p className="ant-upload-text">คลิก หรือลากไฟล์มาวางบริเวณนี้เพื่ออัปโหลดไฟล์</p>
                                    <p className="ant-upload-hint">
                                        อัพโหลดได้เพียง 1 ไฟล์
                                    </p>
                                </Dragger>
                        }
                        </div>
                    </div>
                    <div className={styles['input-container']}>
                        <div className={styles['input-container']}>
                        </div>
                        <div className={styles['input-container']}>
                        {
                            isEngThesis && 
                                <Dragger {...createProps(turnItInDocFileList, setTurnItInDocFileList, "uploadTurnItInDocumentFile", "removeTurnItInDocumentFile")} disabled={!isEngThesis}>
                                    <p className="ant-upload-text-label">{labelLang("ผลการตรวจสอบการลอกเลียนวรรณกรรม (Turnitin)", "Plagiarism Check(Turnitin)", currentLang)}</p>
                                    <p className="ant-upload-text"></p>
                                    <p className="ant-upload-drag-icon">
                                        {
                                            !isEngThesis ? <StopTwoTone twoToneColor={"red"} /> 
                                            : turnItInDocFileList.length > 0 ? <CheckCircleTwoTone twoToneColor={"#71DE04"} /> 
                                            : <InboxOutlined />
                                        }
                                    </p>
                                    <p className="ant-upload-text">คลิก หรือลากไฟล์มาวางบริเวณนี้เพื่ออัปโหลดไฟล์</p>
                                    <p className="ant-upload-hint">
                                        อัพโหลดได้เพียง 1 ไฟล์
                                    </p>
                                </Dragger>
                        }
                        </div>
                    </div>
                </div>
            )
        }
    }
    const renderSaveBtn = () => {
        return isDraftStatus && isOwner ? (
            <div className={styles['input-container']}>
                <Button style={{marginRight: 5}} type={"primary"} icon={<SaveOutlined />} htmlType="submit">{labelLang("บันทึกข้อมูล", "Save", currentLang)}</Button>
            </div>
        ) : null;
    }
    const sendToApprove = () => {
        const callback = async () => {
            if(authen && authen.sessionUser){
                let approveData = {
                    thesis_id: id,
                    new_status: "PENDING",
                    process_user_id: authen.sessionUser.currentUser.id,
                    attach_message: labelLang("ส่งเอกสารเพื่อตรวจสอบ", "Send to Approve", currentLang)
                }
                let resData = await changeThesisStatus(approveData);
                if(resData.status){
                    notification.success({message: "แก้ไขสถานะเอกสารสำเร็จ"});
                    setDataList([])
                    setDownloadFileList([])
                    loadThesisData();
                }
            }
            setConfirmDialog(null);
        }
        let newConfirmDialog = (
            <Modal
                visible={true}
                title={"ส่งเอกสาร"}
                onCancel={() => {setConfirmDialog(null)}}
                onOk={callback}
                okText={"Send to Approve"}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                        {labelLang("ต้องการส่งเอกสารให้เจ้าหน้าที่ตรวจสอบหรือไม่?", "Are you sure for sent to approve?", currentLang)}
                    </div>
                </div>
            </Modal>
        )
        setConfirmDialog(newConfirmDialog);
    }
    const changeStatus = () => {
        setPreviewVisible(true);
    }
    const renderSendDocBtn = () => {
        if(isDraftStatus && isOwner){
            return (
                <div className={styles['input-container']} style={{justifyContent: 'flex-end', margin: 5}}>
                    <Button type={'primary'} onClick={sendToApprove}>{labelLang("ส่งมอบให้เจ้าหน้าที่", "Sent to Approve", currentLang)}</Button>
                </div>
            )
        }else if(hasChangeStatusPerm && !isDraftStatus){
            return (
                <div className={styles['input-container']} style={{justifyContent: 'flex-end', margin: 5}}>
                    <Button type={'primary'} onClick={changeStatus}>{labelLang("เปลี่ยนสถานะ", "Change Status", currentLang)}</Button>
                </div>
            )
        }
    }
    const renderStatusOptions = () => {
        let statusOptionList: any[] = [];
        if(thesisData && thesisData.status != "DRAFT"){
            statusOptionList.push(<Option value="DRAFT">DRAFT</Option>)
        }
        if(thesisData && thesisData.status != "APPROVE"){
            statusOptionList.push(<Option value="APPROVE">APPROVE</Option>)
        }
        if(thesisData && thesisData.status != "REJECT"){
            statusOptionList.push(<Option value="REJECT">REJECT</Option>)
        }
        return statusOptionList;
    }
    const formItemStyle = {marginBottom: 5, width: '100%'};
    return (
        <div className={styles['main-container']}>
            <div className={styles['data-container']}>
                
                <Form
                    form={form}
                    name="register"
                    // labelCol={{ span: 8 }}
                    // wrapperCol={{ span: 16 }}
                    style={{width: '80%'}}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className={styles['input-container']} style={{justifyContent: 'flex-end'}}>
                        <b>{labelLang("สถานะเอกสาร", "Status", currentLang)}:</b>&nbsp;{thesisData?.status}
                        {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                    </div>
                    {
                        thesisData && thesisData.running_no &&
                        
                        <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                            {labelLang("เลขลำดับ", "Seq No.", currentLang)} {thesisData.running_no}
                        </div>
                    }
                    <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                        {/* <Form.Item
                            name="school_year"
                            rules={[{ required: true, message: 'กรอกปีการศึกษา (พ.ศ.)!' }]}
                            style={formItemStyle}
                        >
                            <Input style={{width: '100%', minWidth: 550, marginRight: 5}} className={styles['input-text']} placeholder={"ปีการศึกษา (พ.ศ.)"} disabled={!isDraftStatus|| !isOwner}></Input>
                        </Form.Item> */}
                        {labelLang("ปีการศึกษา", "Academic Year", currentLang)} {thesisData?.semester}/{thesisData?.school_year}
                    </div>
                    <div className={styles['input-container']}>
                        <Form.Item
                            name="thesis_name"
                            rules={[{ required: true, message: labelLang('กรุณากรอกชื่อวิทยานิพนธ์!', "Thesis Title is require", currentLang) }]}
                            style={formItemStyle}
                        >
                            <Input style={{width: '100%', minWidth: 550, marginRight: 5}} className={styles['input-text']} placeholder={labelLang("ชื่อวิทยานิพนธ์", "Thesis Title", currentLang)} disabled={!isDraftStatus|| !isOwner}></Input>
                        </Form.Item>
                        {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                    </div>
                    <div className={styles['input-container']}>
                        <Form.Item
                            name="thesis_abstract"
                            rules={[{ required: true, message: labelLang('กรุณากรอกข้อมูลบทคัดย่อ!', "Abstract is require", currentLang) }]}
                            style={formItemStyle}
                        >
                            <Input.TextArea style={{width: '100%', minWidth: 550, marginRight: 5}} className={styles['input-text']} placeholder={labelLang("บทคัดย่อ", "Abstract", currentLang)} disabled={!isDraftStatus || !isOwner}></Input.TextArea>
                        </Form.Item>
                    </div>
                    <div className={styles['input-container']}>
                        <Form.Item
                            name="is_ithesis"
                            style={formItemStyle}
                            label={labelLang("อยู่ในระบบ IThesis", "IThesis", currentLang)}
                            valuePropName="checked"
                        >
                            <Switch 
                                disabled={!isDraftStatus || !isOwner}
                                checkedChildren="YES" 
                                unCheckedChildren="NO"
                                onChange={(val: boolean) => {
                                    if(form.getFieldValue("thesis_abstract") === null || form.getFieldValue("thesis_abstract") === ""){
                                        form.setFieldsValue({thesis_abstract: " "})
                                    }
                                    form.submit();
                                }}
                            ></Switch>
                        </Form.Item>
                    </div>
                    <div className={styles['input-container']}>
                        <Form.Item
                            name="is_eng_thesis"
                            style={formItemStyle}
                            label={labelLang("วิทยานิพนธ์ฉบับภาษา", "Thesis Language", currentLang)}
                            valuePropName="checked"
                        >
                            <Switch 
                                disabled={!isDraftStatus || !isOwner}
                                unCheckedChildren="ไทย" 
                                checkedChildren="Eng"
                                onChange={(val: boolean) => {
                                    if(form.getFieldValue("thesis_abstract") === null || form.getFieldValue("thesis_abstract") === ""){
                                        form.setFieldsValue({thesis_abstract: " "})
                                    }
                                    form.submit()
                                }}
                            ></Switch>
                        </Form.Item>
                    </div>
                    {renderSaveBtn()}
                </Form>
            </div>
            <div className={styles['tabs-container']}>
                <Tabs className={styles['tabs-component']} tabBarExtraContent={renderSendDocBtn()} type={"card"}>
                    <Tabs.TabPane tab={labelLang("การอัพโหลด", "Upload", currentLang)} key="tb1">
                        {renderFileTable()}
                        {renderUploadSection()}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={labelLang("บันทึกทำการ", "Process Log", currentLang)} key="tb2">
                        <div className={styles['list-container']}>
                            <Table columns={columns} dataSource={dataList} onChange={onChange}/>
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <Modal
                visible={previewVisible}
                title={labelLang("ยืนยันการเปลี่ยนสถานะเอกสาร", "Confirm Change Status", currentLang)}
                onCancel={() => {setPreviewVisible(false)}}
                onOk={async () => {
                    if(!selectedStatus || selectedStatus.trim() == ""){
                        notification.error({message: labelLang("กรุณาเลือกสถานะที่ต้องการเปลี่ยน", "New Status is require", currentLang)})
                        return;
                    }
                    if(!attachMessage || attachMessage.trim() == ""){
                        notification.error({message: labelLang("กรุณากรอกข้อความแนบ", "Message is require", currentLang)})
                        return;
                    }
                    if(authen && authen.sessionUser){
                        let approveData = {
                            thesis_id: id,
                            new_status: selectedStatus,
                            process_user_id: authen.sessionUser.currentUser.id,
                            attach_message: attachMessage
                        }
                        let resData = await changeThesisStatus(approveData);
                        if(resData.status){
                            notification.success({message: labelLang("ส่งเอกสารสำเร็จ", "Message is require", currentLang), description: labelLang("ส่งเอกสารถึงเจ้าหน้าที่เพื่อตรวจสอบแล้ว กรุณารอการตอบกลับ", "Sent success, Please wait for response", currentLang)});
                            setDataList([])
                            setDownloadFileList([])
                            loadThesisData();
                        }
                    }
                    setPreviewVisible(false)
                }}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']}>
                        <Select
                            showSearch
                            placeholder={labelLang("เลือกสถานะเอกสารที่ต้องการเปลี่ยน", "Confirm Change Status", currentLang)}
                            optionFilterProp="children"
                            onChange={(value) => {setSelectedStatus(value)}}
                            value={selectedStatus}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            className={styles['select-half']}
                            style={{margin: 0, width: "100%"}}
                        >
                            {renderStatusOptions()}
                        </Select>
                        {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                    </div>
                    <div className={styles['input-container']}>
                        <Input.TextArea style={{margin: 0, width: "100%"}} className={styles['input-text']} placeholder={labelLang("ข้อความแนบ", "Message", currentLang)} value={attachMessage} onChange={(e) => {inputTextChange(e, setAttachMessage)}} />
                        {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                    </div>
                </div>
            </Modal>
            {confirmDialog}
        </div>
    )
}

export default ThesisUploadForm;