import axios from "axios";
import ApiConfig from "../constants/ApiConfig";
import { ApiResponse } from "../models/MasterModel";

export const addScholarship90 = async (scholarship90Data: any) => {
    let url = `${ApiConfig.backendUrl}/scholar90Controller/addScholar90.php`
    
    let res = await axios.post(url, scholarship90Data, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const editScholarship90 = async (scholarship90Data: any) => {
    let url = `${ApiConfig.backendUrl}/scholar90Controller/editScholar90.php`
    let res = await axios.post(url, scholarship90Data, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const deleteScholarship90 = async (scholarId: any, processUserId: any) => {
    let url = `${ApiConfig.backendUrl}/scholar90Controller/deleteScholar90.php`
    
    let res = await axios.post(url, {scholar_id: scholarId, process_user_id: processUserId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const getScholarship90ByParam = async (params: any) => {
    let url = `${ApiConfig.backendUrl}/scholar90Controller/getScholar90ByParam.php`
    let res = await axios.post(url, params, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const getScholarship90ById = async (id: any) => {
    let url = `${ApiConfig.backendUrl}/scholar90Controller/getScholar90ById.php`
    let res = await axios.post(url, {scholarId: id}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const changeScholar90Status = async (data: any) => {
    let url = `${ApiConfig.backendUrl}/scholar90Controller/changeScholoar90Status.php`
    let res = await axios.post(url, data, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}