import { useEffect, useState  } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { setPageName, setBack2Page } from "../../../../store/pagination/paginationSlice";
import { Button, DatePicker, Input, message, Modal, notification, Select, Spin, Table, Tabs, Upload } from "antd";
import { CloudUploadOutlined, DownloadOutlined, InboxOutlined, PlusOutlined, RollbackOutlined, SaveOutlined } from "@ant-design/icons";
import axios from "axios";
import styles from './RequestDocumentUploadForm.module.css';
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import type { UploadFile } from 'antd/es/upload/interface';
import ApiConfig from "../../../../constants/ApiConfig";
import Dragger from "antd/lib/upload/Dragger";
import { convertDBDate2Show, convertDBTime2Show } from "../../../../helper/DateTimeHelper";
import { SessionUserState } from "../../../../store/authen/authenSlice";
import { PermissionName } from "../../../../models/Login";
import { ApiResponse } from "../../../../models/MasterModel";
import { getAuthPermission } from "../../../../services/AuthenService";
import { changeScholar90Status } from "../../../../services/ScholarShip90Service";
import UploadTab from "./tabs/UploadTab";
import HistoryTab from "./tabs/HistoryTab";
import ChangeRequestDocumentStatus from "./modal/ChangeRequestDocumentStatusModal";
import ChangeRequestDocumentStatusModal from "./modal/ChangeRequestDocumentStatusModal";
import { RequestDocumentData, RequestDocumentStatusLog, RequestDocumentUploadFile } from "../../../../models/RequestDocumentModel";
import { changeRequestDocumentStatus, downloadTemplateById, getRequestDocumentById, getRequestDocumentUploadFileList } from "../../../../services/RequestDocumentService";
import { LanguageState } from "../../../../store/language/languageSlice";
import { labelLang } from "../../../../helper/HTMLHelper";

const RequestDocumentUploadForm = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams<string>();
    const { Option } = Select;
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const [userId, setUserId] = useState<number>(-1);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [markerList, setMarkerList] = useState<Array<any>>([]);
    const [photoDate, setPhotoDate] = useState<any>(moment());
    const [refName, setRefName] = useState<string>("");
    const [dataList, setDataList] = useState<RequestDocumentStatusLog[]>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [requestDocumentData, setRequestDocumentData] = useState<RequestDocumentData>();
    const [confirmDialog, setConfirmDialog] = useState<any>(null);
    // const [loadedImageList, setLoadedImageList] = useState<TransactionMainImage[]>([]);
    
    const [isOwner, setIsOwner] = useState<boolean>(false);
    const [currentPermission, setCurrentPermission] = useState<any>(null);
    const [canApprove, setCanApprove] = useState<boolean>(false);
    const [canReject, setCanReject] = useState<boolean>(false);
    const [downloadFileList, setDownloadFileList] = useState<any[]>([]);
    const [isDraftStatus, setIsDraftStatus] = useState<boolean>(false);
    const [isUploadDisable, setUploadDisable] = useState<boolean>(false);
    const [hasChangeStatusPerm, setHasChangeStatusPerm] = useState<boolean>();
    // const [loadedImageList, setLoadedImageList] = useState<TransactionMainImage[]>([]);
    const [showTable, setShowTable] = useState<boolean>(true);

    const [requestDocumentUploadFileList, setRequestDocumentUploadFileList] = useState<RequestDocumentUploadFile[]>([]);

    const navigate = useNavigate();
    const [navigatePage, setNavigatePage] = useState<string>();

    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])

    useEffect(() => {
        dispatch(setPageName({th: "แบบฟอร์มอัปโหลดเอกสารคำร้อง", en: "Request Form Document Management"}));
        dispatch(setBack2Page("/management/requestdocument/list"));
        // setDataList(mockData)
        // setFileList(mockFileList)
        loadRequestDocumentData();
    }, [])

    // useEffect(() => {
    //     if(fileList){
    //         setUploadDisable(fileList.length > 0)
    //     }
    // }, [fileList])
    useEffect(() => {
        if(navigatePage != null && navigatePage.trim() != ""){
          navigate(navigatePage, {replace: true});
        }
      },[navigatePage]);

    useEffect(() => {
        if(requestDocumentData){
            if(requestDocumentData.status_logs){
                setDataList(requestDocumentData.status_logs);
            }
            if(requestDocumentData.attach_files){
                let files: any[] = requestDocumentData.attach_files.map((value) => {
                    return {
                        uid: value.id,
                        name: value.ori_file_name,
                        status: 'done',
                        url: `${ApiConfig.uploadedAssets}${value.file_path}`,
                        shortUrl: value.file_path,
                    }
                });
                console.log("setFileList: ", files);
                setFileList(files);
                let attachFileList: any[] = requestDocumentData.attach_files.map((value) => {
                    let filename = value.file_path.replace(/^.*[\\\/]/, '')
                    return {
                        filename: filename, 
                        upload_date: value.upload_date, 
                        upload_time: value.upload_time, 
                        url: value.file_path,
                        file_type: 'เอกสารส่งตีพิมพ์',
                    }
                })
                setDownloadFileList(attachFileList);
            }
            setIsDraftStatus(requestDocumentData.status == "DRAFT");
            if(authen && authen.sessionUser){
                setIsOwner(requestDocumentData.create_user_id == authen.sessionUser.currentUser.id);
                loadUserPermission();
            }
            // setDownloadFileList(fileTableList);
            dispatch(setPageName({th: `แบบฟอร์มอัปโหลดเอกสาร${requestDocumentData.doc_name}`, en: `${requestDocumentData.doc_name_en}`}));
            loadRequestDocumentUploadFileList(requestDocumentData.req_doc_type_id);
        }
    }, [requestDocumentData])

    useEffect(() => {
        setShowTable(!isOwner || !isDraftStatus);
    }, [isOwner, isDraftStatus])

    useEffect(() => {
        if(authen && authen.sessionUser){
            setUserId(authen.sessionUser.currentUser.id);
        }
    }, [authen])

    const loadRequestDocumentUploadFileList = async (docTypeId: any) => {
        let resData = await getRequestDocumentUploadFileList(docTypeId);
        if(resData.status) {
            setRequestDocumentUploadFileList(resData.data);
            // console.log(resData.data);
            // setShowTable(false);
        }
    }

    const loadRequestDocumentData = async () => {
        let resData = await getRequestDocumentById(id);
        if(resData.status){
            setRequestDocumentData(resData.data);
        }
    }
    const loadUserPermission = async () => {
        // console.log("loadUserPermission");
        if(authen && authen.sessionUser){
            // console.log("loadUserPermission auth");
            let permNameList = [PermissionName.REQUIRE_DOC_MANAGE_STATUS];
            let userId = authen.sessionUser.currentUser.id;
            let resData: ApiResponse = await getAuthPermission(userId, permNameList);
            if(resData.status){
                let data: {[key: string]: string} = resData.data;
                // console.log("Permission Data: ", data);
                setCurrentPermission(data);
                let hasApprovePerm = data[PermissionName.REQUIRE_DOC_MANAGE_STATUS] != undefined && data[PermissionName.REQUIRE_DOC_MANAGE_STATUS] != null;
                setHasChangeStatusPerm(hasApprovePerm);
            }
        }

    }
    
    const uploadButton = (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const inputTextChange = (e: any, setState: any) => {
        setState(e.target.value);
    }
    const sendToApprove = () => {
        const callback = async () => {
            if(authen && authen.sessionUser){
                let approveData = {
                    request_document_id: id,
                    new_status: "PENDING",
                    process_user_id: authen.sessionUser.currentUser.id,
                    attach_message: labelLang("ส่งเอกสารเพื่อตรวจสอบ", "Send to Approve", currentLang)
                }
                let resData = await changeRequestDocumentStatus(approveData);
                if(resData.status){
                    notification.success({message: labelLang("แก้ไขสถานะเอกสารสำเร็จ", "Update Status Success", currentLang)});
                    setDataList([])
                    setDownloadFileList([])
                    loadRequestDocumentData();
                }
            }
            setConfirmDialog(null);
        }
        let newConfirmDialog = (
            <Modal
                visible={true}
                title={labelLang("ส่งเอกสาร", "Send to Approve", currentLang)}
                onCancel={() => {setConfirmDialog(null)}}
                onOk={callback}
                okText={"Send to Approve"}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
                        {labelLang("ต้องการส่งเอกสารให้เจ้าหน้าที่ตรวจสอบหรือไม่?", "Are you sure for sent to approve?", currentLang)}
                    </div>
                </div>
            </Modal>
        )
        setConfirmDialog(newConfirmDialog);
    }
    const changeStatus = () => {
        setPreviewVisible(true);
    }
    const adminDownload = (data: any) => {
        console.log("adminDownload: ", data);
        window.open(`${ApiConfig.backendUrl}/requestDocumentController/readAttachFile.php?attachId=${data.id}`, "_blank");
        // const callback = () => {
        //     window.open(`${ApiConfig.backendUrl}/requestDocumentController/readAttachFile.php?attachId=${data.id}`, "_blank");
        //     setConfirmDialog(null);
        // }
        // let newConfirmDialog = (
        //     <Modal
        //         visible={true}
        //         title={"เปิดเอกสาร"}
        //         onCancel={() => {setConfirmDialog(null);}}
        //         onOk={callback}
        //         okText={"Download"}
        //     >
        //         <div className={styles['modal-main-container']}>
        //             <div className={styles['input-container']} style={{justifyContent: 'flex-start'}}>
        //                 ต้องการดาวน์โหลดไฟล์ "{data.filename}" หรือไม่?
        //             </div>
        //         </div>
        //     </Modal>
        // )
        // setConfirmDialog(newConfirmDialog);
    }
    const downloadTemplate = async () => {
        if(requestDocumentData){
            await downloadTemplateById(requestDocumentData.req_doc_type_id);
        }
    }
    const renderSendDocBtn = () => {
        if(isDraftStatus && isOwner){
            return (
                <div className={styles['input-container']} style={{justifyContent: 'flex-end', margin: 5}}>                    
                    <Button type={'primary'} onClick={sendToApprove}>{labelLang("ส่งเพื่อตรวจสอบ", "Send to Approve", currentLang)}</Button>
                    <Button type={'default'} onClick={downloadTemplate} style={{marginLeft: 5}}>{labelLang("ดาวน์โหลดแบบฟอร์ม", "Download Form", currentLang)}</Button>
                </div>
            )
        }else if(hasChangeStatusPerm && !isDraftStatus && requestDocumentData && userId != requestDocumentData.create_user_id){
            return (
                <div className={styles['input-container']} style={{justifyContent: 'flex-end', margin: 5}}>
                    <Button type={'primary'} onClick={changeStatus}>{labelLang("เปลี่ยนสถานะ", "Change Status", currentLang)}</Button>
                </div>
            )
        }
    }

    const renderStatusOptions = () => {
        let statusOptionList: any[] = [];
        if(requestDocumentData && requestDocumentData.status != "DRAFT"){
            statusOptionList.push(<Option value="DRAFT">DRAFT</Option>)
        }
        if(requestDocumentData && requestDocumentData.status != "APPROVE"){
            statusOptionList.push(<Option value="APPROVE">APPROVE</Option>)
        }
        if(requestDocumentData && requestDocumentData.status != "REJECT"){
            statusOptionList.push(<Option value="REJECT">REJECT</Option>)
        }
        return statusOptionList;
    }
    return (
        <div className={styles['main-container']}>
            <div className={styles['tabs-container']}>
                <div className={styles['input-container']} style={{justifyContent: 'flex-end', paddingRight: 0, marginTop: 5}}>
                    <b>สถานะเอกสาร:</b>&nbsp;{requestDocumentData?.status}
                    {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                </div>
                <Tabs className={styles['tabs-component']} tabBarExtraContent={renderSendDocBtn()} type={"card"}>
                    <Tabs.TabPane tab={labelLang("การอัพโหลด", "Upload", currentLang)} key="tb1">
                        <UploadTab 
                            requestDocumentUploadFileList={requestDocumentUploadFileList}
                            showTable={showTable}
                            isDraftStatus={isDraftStatus}
                            isOwner={isOwner}
                            refId={id}
                            requestDocumentData={requestDocumentData}
                            adminDownload={adminDownload}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={labelLang("บันทึกทำการ", "Process Log", currentLang)} key="tb2">
                        <HistoryTab 
                            logList={dataList}
                            requestDocument={requestDocumentData}
                            userId={userId}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <ChangeRequestDocumentStatusModal 
                onCancel={() => {setPreviewVisible(false)}}
                onOkSuccess={() => {
                    setPreviewVisible(false)
                    setNavigatePage("/management/requestdocument/list")
                }}
                previewVisible={previewVisible}
                refId={id}
                requestDocument={requestDocumentData}
                userId={userId}
            />
            {/* <div className={styles['list-container']}>
                <div className={styles['input-container']} style={{justifyContent: 'flex-end', margin: 5}}>
                    {renderSendDocBtn()}
                </div>
                <Table columns={columns} dataSource={dataList} onChange={onChange}/>
            </div> */}
            {/* <Modal
                visible={previewVisible}
                title={"ยืนยันการเปลี่ยนสถานะเอกสาร"}
                onCancel={() => {setPreviewVisible(false)}}
                onOk={async () => {
                    if(!selectedStatus || selectedStatus.trim() == ""){
                        notification.error({message: "กรุณาเลือกสถานะที่ต้องการเปลี่ยน"})
                        return;
                    }
                    if(!attachMessage || attachMessage.trim() == ""){
                        notification.error({message: "กรุณากรอกข้อความแนบ"})
                        return;
                    }
                    if(authen && authen.sessionUser){
                        let approveData = {
                            scholar_id: id,
                            new_status: selectedStatus,
                            process_user_id: authen.sessionUser.currentUser.id,
                            attach_message: attachMessage
                        }
                        let resData = await changeScholar90Status(approveData);
                        if(resData.status){
                            notification.success({message: "ส่งเอกสารสำเร็จ", description: "ส่งเอกสารถึงเจ้าหน้าที่เพื่อตรวจสอบแล้ว กรุณารอการตอบกลับ"});
                            setDataList([])
                            setDownloadFileList([])
                            loadRequestDocumentData();
                        }
                    }
                    setPreviewVisible(false)
                    setNavigatePage("/management/requestdocument/list")
                }}
            >
                <div className={styles['modal-main-container']}>
                    <div className={styles['input-container']}>
                        <Select
                            showSearch
                            placeholder="เลือกสถานะเอกสารที่ต้องการเปลี่ยน"
                            optionFilterProp="children"
                            onChange={(value) => {setSelectedStatus(value)}}
                            value={selectedStatus}
                            filterOption={(input, option) =>
                                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                            }
                            className={styles['select-half']}
                            style={{marginRight: 4, width: "100%"}}
                        >
                            {renderStatusOptions()}
                        </Select>
                    </div>
                    <div className={styles['input-container']}>
                        <Input style={{width: 550, marginRight: 5}} className={styles['input-text']} placeholder={"ข้อความแนบ"} value={attachMessage} onChange={(e) => {inputTextChange(e, setAttachMessage)}}></Input>
                    </div>
                </div>
            </Modal> */}
            {confirmDialog}
            {/* <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAbSvmJTpz2jImwwovY2AN7xDWZE_teSX0" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                    {renderMarker()}
                </GoogleMapReact>
            </div> */}
        </div>
    )
}

export default RequestDocumentUploadForm;