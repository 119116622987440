import { Button, Form, Input, notification, Select } from 'antd';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import { GoogleCircleFilled } from '@ant-design/icons';
import styles from './Profile.module.css'
import { useAppDispatch, useAppSelector } from '../../../store';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiResponse, Faculty, FacultyBranch } from '../../../models/MasterModel';
import { getDepartment, getFaculty, getFacultyBranch, getMajor } from '../../../services/MasterService';
import { changePassword, logout, register } from '../../../services/AuthenService';
import { setBack2Page, setPageName } from '../../../store/pagination/paginationSlice';
import { getUserById, updateUserProfile } from '../../../services/UserService';
import { SessionUserState } from '../../../store/authen/authenSlice';

interface ISignUp{
    student_code: string;
    email: string;
    password: string;
    confirm_password: string;
    firstname: string;
    lastname: string;
    faculty_id: string;
    branch_id: string;
    phone_no: string;
    old_password: string;
    new_password: string;
    confirm_new_password: string;
}
const ChangePassword = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const [currentAuthen, setCurrentAuthen] = useState<SessionUserState>(authen);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [navigatePage, setNavigatePage] = useState<string>();
    const [facultyComboList, setFacultyComboList] = useState<Faculty[]>([]);
    const [oriFacultyBranchComboList, setOriFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [filterFacultyBranchComboList, setFilterFacultyBranchComboList] = useState<FacultyBranch[]>([]);

    const { Option } = Select;
    useEffect(() => {
        // loadComboData();
    }, []);

    useEffect(() => {
        setCurrentAuthen(authen);
    }, [authen])

    useEffect(() => {
      if(navigatePage != null && navigatePage.trim() != ""){
        navigate(navigatePage, {replace: true});
      }
    },[navigatePage]);

    const loadComboData = async () => {
        let facultyList = await getFaculty();
        let facultyBranchList = await getFacultyBranch();
        setFacultyComboList(facultyList);
        setOriFacultyBranchComboList(facultyBranchList);
        setFilterFacultyBranchComboList([...facultyBranchList]);
    }

    const onFinish = async (values: ISignUp) => {
        console.log('Success:', values);
        if(currentAuthen && currentAuthen.sessionUser){
            if(values.confirm_password != values.password){
                alert("การยืนยันรหัสผ่านไม่ตรงกัน");
                return;
            }
            let userId = currentAuthen.sessionUser.currentUser.id;
            let email = currentAuthen.sessionUser.currentUser.email;
            let resData: ApiResponse = await changePassword(userId, email, values.old_password, values.new_password, values.confirm_new_password);
            if(resData.status){
                notification.success({message: "แก้ไขรหัสผ่านสำเร็จ", description: "กรุณาเข้าสู่ระบบใหม่อีกครั้ง"});
                logout(userId, dispatch);
                setNavigatePage("/frontend/login");
            }else{
                notification.error({message: "แก้ไขรหัสผ่านไม่สำเร็จ", description: resData.message})
            }
        }
    };
  
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const onFacultyChange = (value: string) => {
        console.log("onFacultyChange: ", value);
        const facultyId: number = Number(value);
        let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
            return branch.faculty_id == facultyId;
        });
        form.setFieldsValue({branch_id: "" + filterBranch[0].id});
        setFilterFacultyBranchComboList(filterBranch);
    }

    const onFacultyBranchChange = (value: string) => {
        console.log("onFacultyChange: ", value);
        const facultyBranchId: number = Number(value);
        let facultyBranchItem: FacultyBranch | undefined = oriFacultyBranchComboList.find((value) => {return value.id == facultyBranchId});
        if(facultyBranchItem){
            const facultyBranchItemValue = facultyBranchItem;
            form.setFieldsValue({faculty_id: "" + facultyBranchItem.faculty_id});
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == facultyBranchItemValue.faculty_id;
            });
            setFilterFacultyBranchComboList(filterBranch);
        }
        console.log("onFacultyBranchChange field value: ", form.getFieldsValue());
        // setFilterFacultyBranchComboList(filterBranch);
    }
    const renderFacultyOption = () => {
        let optionList = [];
        for(let i = 0; i < facultyComboList.length; i++){
            let fac = facultyComboList[i];
            optionList.push(<Option value={`${fac.id}`}>{fac.faculty_name}</Option>);
        }
        return optionList;
    }

    const renderFacultyBranchOption = () => {
        let optionList = [];
        for(let i = 0; i < filterFacultyBranchComboList.length; i++){
            let branch = filterFacultyBranchComboList[i];
            optionList.push(<Option value={`${branch.id}`}>{branch.branch_name}</Option>);
        }
        return optionList;
    }
    const formItemStyle = {marginBottom: 10};
    return (
        <div>
            <div className={styles['login-container']}>
                <div className={styles['component-box']}>
                    <div className={styles['title-bar']}><span>แก้ไขรหัสผ่าน({currentAuthen.sessionUser?.currentUser.email})</span></div>
                    
                    <Form
                        form={form}
                        name="register"
                        // labelCol={{ span: 8 }}
                        // wrapperCol={{ span: 16 }}
                        style={{width: '80%'}}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="old_password"
                            rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน!' }]}
                            style={formItemStyle}
                        >
                            <Input placeholder="Old Passowrd*" type={"password"} />
                        </Form.Item>
                        <Form.Item
                            name="new_password"
                            rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน!' }]}
                            style={formItemStyle}
                        >
                            <Input placeholder="New Passowrd*" type={"password"} />
                        </Form.Item>
                        <Form.Item
                            name="confirm_new_password"
                            rules={[{ required: true, message: 'กรุณายืนยันรหัสผ่าน!' }]}
                            style={formItemStyle}
                        >
                            <Input placeholder="Confirm New Passowrd*" type={"password"} />
                        </Form.Item>

                        <div className={styles['button-container']}>
                            <Form.Item
                                name="submitBtn"
                                // rules={[{ required: true, message: 'Please input your username!' }]}
                                // className={styles['half']}
                                style={{...formItemStyle, }}
                            >
                                <Button type={"primary"} style={{marginRight: 5}} htmlType={"submit"}>แก้ไขรหัสผ่าน</Button>
                            </Form.Item>
                            {/* <Button type={"default"} style={{marginRight: 5}} onClick={() => {setNavigatePage("/frontend/login")}}>ยกเลิก</Button> */}
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;