import { Button, DatePicker, Form, Input, InputNumber, notification } from "antd";
import styles from './ActivateSetting.module.css';
import { useEffect, useState } from "react";
import { getActivateSettingById, updateActivateSetting } from "../../../services/ActivateSettingService";
import moment, { Moment } from "moment";
import dayjs, { Dayjs } from "dayjs";
import { IActivateSetting } from "../../../models/ActivateSetting";
import { ApiResponse } from "../../../models/MasterModel";
import { datePickerFormat, datePickerYearFormat } from "../../../helper/DateTimeHelper";
interface ActivateSettingProps{
    moduleCode: string;
    userId: number;
}
interface SettingFormValue {
    school_year: Moment | Dayjs;
    semester: number;
    start_date: Moment | Dayjs;
    end_date: Moment | Dayjs | null;
    remark: string | null;
    remark_en: string | null;
}
const ActivateSetting = (props: ActivateSettingProps) => {
    const [form] = Form.useForm();
    const [moduleCode, setModuleCode] = useState(props.moduleCode);
    const [userId, setUserId] = useState(props.userId);
    const [formValue, setFormValue] = useState<SettingFormValue>(
        {
            school_year: dayjs(),
            semester: 1,
            start_date: dayjs(),
            end_date: null,
            remark: null,
            remark_en: null
        }
    );
    // const [activateSetting, setActivateSetting] = useState<IActivateSetting | null>(null);

    useEffect(() => {
        setModuleCode(props.moduleCode);
    }, [props.moduleCode])
    useEffect(() => {
        setUserId(props.userId)
    }, [props.userId])

    useEffect(() => {
        loadSetting(moduleCode);
    }, [moduleCode])

    useEffect(() => {
        form.setFieldsValue(formValue);
    }, [formValue])

    const loadSetting = async (nowModuleCode: string) => {
        let res = await getActivateSettingById(nowModuleCode);
        if(res.status){
            console.log("loadSetting: ", res.data);
            let settingData: IActivateSetting = res.data;
            form.setFieldsValue(convertActivateSetting2FormValue(settingData));
        }
    }
    const dateFormat = "YYYYMMDD";
    const convertActivateSetting2FormValue = (settingData: IActivateSetting) => {
        let nowFormValue: SettingFormValue = {
            school_year: dayjs("" + settingData.school_year, "YYYY"),
            semester: settingData.semester,
            start_date: moment(settingData.start_date, dateFormat),
            end_date: settingData.end_date == null ? null : moment(settingData.end_date, dateFormat),
            remark: settingData.remark,
            remark_en: settingData.remark_en
        }
        return nowFormValue;
    }
    const convertFormValue2ActivateSetting = (nowFormValue: SettingFormValue) => {
        let nowActivateSetting: IActivateSetting = {
            module_code: moduleCode,
            school_year: Number.parseInt(nowFormValue.school_year.format("YYYY")),
            semester: nowFormValue.semester,
            start_date: nowFormValue.start_date.format(dateFormat),
            end_date: nowFormValue.end_date == null ? null : nowFormValue.end_date.format(dateFormat),
            remark: nowFormValue.remark,
            remark_en: nowFormValue.remark_en,
            update_date: "",
            update_time: "",
            update_user_id: userId,
            status: "ACTIVE"
        }
        return nowActivateSetting;
    }
    const onFinish = async (values: SettingFormValue) => {
        let finishActivateSetting = convertFormValue2ActivateSetting(values);
        console.log('Success: ', finishActivateSetting);
        let resData: ApiResponse = await updateActivateSetting(finishActivateSetting);
        if(resData.status){
            notification.success({message: "อัพเดทค่า Setting สำเร็จ", description: resData.message})
            // setNavigatePage("/backend");
        }else{
            notification.error({message: "อัพเดทค่า Setting ไม่สำเร็จ", description: resData.message})
        }
    };
  
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const formItemStyle = {marginBottom: 10};
    return (
        <div className={styles['main-container']}>
            <div className={styles['data-container']}>
                <Form
                    form={form}
                    name="login"
                    // labelCol={{ span: 8 }}
                    // wrapperCol={{ span: 16 }}
                    style={{ width: '80%' }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className={styles['form-row']}>
                        <Form.Item
                            name="school_year"
                            rules={[{ required: true, message: 'กรุณาเลือกปี!' }]}
                            style={{...formItemStyle, marginRight: 2}}
                            className={styles['form-row-item']}
                        >
                            <DatePicker picker="year" placeholder="ปีการศึกษา" style={{width: '100%'}} format={datePickerYearFormat}/>
                        </Form.Item>
                        <Form.Item
                            name="semester"
                            rules={[{ required: true, message: 'กรุณากรอกข้อมูลเทอม!' }]}
                            style={{...formItemStyle, marginLeft: 2}}
                            className={styles['form-row-item']}
                        >
                            <InputNumber min={1} defaultValue={1} placeholder="เทอม" style={{width: '100%'}} />
                        </Form.Item>
                    </div>
                    <div className={styles['form-row']}>
                        <Form.Item
                            name="start_date"
                            rules={[{ required: true, message: 'กรุณาเลือกวันที่เริ่มต้น!' }]}
                            style={{...formItemStyle, marginRight: 2}}
                            className={styles['form-row-item']}
                        >
                            <DatePicker placeholder={"วันที่เริ่มต้นเทอม"} style={{width: '100%'}} format={datePickerFormat}/>
                        </Form.Item>
                        <Form.Item
                            name="end_date"
                            style={{...formItemStyle, marginLeft: 2}}
                            className={styles['form-row-item']}
                        >
                            <DatePicker placeholder={"วันที่สิ้นสุดเทอม"} style={{width: '100%'}} format={datePickerFormat}/>
                        </Form.Item>
                    </div>
                    <Form.Item
                        name="remark"
                        style={formItemStyle}
                    >
                        <Input placeholder={"คำอธิบายเพิ่มเติม"}/>
                    </Form.Item>
                    <Form.Item
                        name="remark_en"
                        style={formItemStyle}
                    >
                        <Input placeholder={"คำอธิบายเพิ่มเติม(ภาษาอังกฤษ)"}/>
                    </Form.Item>
                    <Form.Item
                        // style={{...formItemStyle, width: '100%', display: 'flex', justifyContent: 'flex-end'}}
                        // wrapperCol={{ offset: 22, span: 2 }}
                    >
                        <Button type="primary" htmlType="submit" style={{width: 100}}>
                            บันทึก
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default ActivateSetting;