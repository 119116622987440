import axios from "axios";
import ApiConfig from "../constants/ApiConfig";
import { RequestDocumentType, ApiResponse } from "../models/MasterModel";

const controllerPath = `${ApiConfig.backendUrl}/requestDocumentController`

export const getRequestDocumentList = async () => {
    let result: RequestDocumentType[] = []
    try{
        let url = `${controllerPath}/getRequestDocumentTypeList.php`
        let res = await axios.get(url);
        if(res.status === 200){
            result = res.data.data;
            return result
        }
        return result;
    }catch(ex){
        // console.log("getFaculty: ", ex);
        return result;
    }
}


export const getRequestDocumentListByParam = async (requestDocumentData: any) => {
    let url = `${controllerPath}/getByParam.php`
    
    let res = await axios.post(url, requestDocumentData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const getRequestDocumentById = async (requestDocumentId: any) => {
    let url = `${controllerPath}/getById.php`
    
    let res = await axios.post(url, {requestDocumentId: requestDocumentId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const getRequestDocumentUploadFileList = async (reqDocTypeId: any) => {
    let url = `${controllerPath}/getRequestDocumentUploadFileList.php`
    
    let res = await axios.post(url, {reqDocTypeId: reqDocTypeId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const downloadTemplateById = async (templayId: any) => {
    let url = `${controllerPath}/downloadTemplate.php`
    window.open(url + `?templateId=${templayId}`);
}


export const addRequestDocument = async (requestDocumentData: any) => {
    let url = `${controllerPath}/add.php`
    
    let res = await axios.post(url, requestDocumentData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const deleteRequestDocumentList = async (requestdocumentId: any, processUserId: any) => {
    let url = `${controllerPath}/delete.php`
    
    let res = await axios.post(url, {request_document_id: requestdocumentId, process_user_id: processUserId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}


export const changeRequestDocumentStatus = async (data: any) => {
    let url = `${controllerPath}/changeStatus.php`
    let res = await axios.post(url, data, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

export const readedRequestDocument = async (requestDocumentId: any) => {
    let url = `${controllerPath}/readed.php`
    let res = await axios.post(url, {request_document_id: requestDocumentId}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}

