import { CSSProperties, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { SessionUserState } from "../../../store/authen/authenSlice";
import Header from "./Header";
import PageHeader from "./PageHeader";
import SideNav from "./SideNav";

const FrontendLayout = () => {
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const [preloadStyle, setPreloadStyle] = useState<CSSProperties>();

    useEffect(() => {
        (async () => {
            await authenUser();
        })();
        setTimeout(() => {
            setPreloadStyle({display: 'none'});
        }, 500);
    }, []);

    const authenUser = async() => {
    }
    return (
        <>
            <div style={preloadStyle} className={"preloader"}>
                <div className={"lds-ripple"}>
                    <div className={"lds-pos"}></div>
                    <div className={"lds-pos"}></div>
                </div>
            </div>
            <div id="main-wrapper-frontend" data-layout="vertical" data-sidebartype="full"
                data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
                {/* <Header /> */}
                {/* <aside className={"left-sidebar"} data-sidebarbg="skin6">
                    <div className={"scroll-sidebar"}>
                        <SideNav />
                    </div>
                </aside> */}
                <div className={"page-wrapper"}>
                    {/* <PageHeader /> */}
                    <div className={"container-fluid"} style={{ minHeight: "calc(100vh - 30px)"}}>
                        <Outlet/>
                    </div>
                    <footer style={{backgroundColor: "#eef5f9"}} className={"footer text-center"}>
                        {/* <a href="https://go.camt.cmu.ac.th/index.php/th/" target="_blank"><img src="/assets/logo.jpg" style={{width: 24, height: 24, marginRight: 5}}/></a>
                        College of Arts Media and Technology Chiang Mai University
                        <a href="https://www.cmu.ac.th/" target="_blank"><img src="/assets/cmu_logo.png" style={{width: 24, height: 24, marginLeft: 5}}/></a> */}
                        {/* Footer */}
                    </footer>
                </div>
            </div>
        </>
    );
}

export default FrontendLayout;