import { useEffect, useState } from "react";
import { LanguageState } from "../store/language/languageSlice";
import { useAppSelector } from "../store";

interface IProps {
    th: string;
    en: string;
    className?: any;
    style?: any;
}

const CustomLabel = (props: IProps) => {
    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    const {th, en, className, style} = props;

    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang])
    return (
        <span className={className} style={style}>
            { currentLang === "en" ? en : th}
        </span>
    )
}

export default CustomLabel;