import { Button, Collapse, Drawer, Input, Select } from "antd"
import styles from "../RequestDocumentUploadList.module.css"
import { useEffect, useState } from "react";
import moment from "moment";
import { Faculty, FacultyBranch, RequestDocumentType } from "../../../../../models/MasterModel";
import { getDepartment, getFaculty, getMajor } from "../../../../../services/MasterService";
import { getRequestDocumentList } from "../../../../../services/RequestDocumentService";
import { useAppSelector } from "../../../../../store";
import { SessionUserState } from "../../../../../store/authen/authenSlice";
import { CaretRightOutlined } from "@ant-design/icons";
import { LanguageState } from "../../../../../store/language/languageSlice";
import { labelLang } from "../../../../../helper/HTMLHelper";

export interface RequestDocumentUploadListSearchSectionProps {
    userId: number;
    onSearch: (searchValue: any) => void
    hasSearchPermission: boolean;
    isOpenDrawer: boolean;
    onCloseDrawer: any;
}
const RequestDocumentUploadListSearchSection = (props: RequestDocumentUploadListSearchSectionProps) => {
    
    const { Option } = Select;
    const dateFormat = 'DD/MM/YYYY';

    const [userId, setUserId] = useState<number>(props.userId);
    const [facultyComboList, setFacultyComboList] = useState<Faculty[]>([]);
    const [oriFacultyBranchComboList, setOriFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [filterFacultyBranchComboList, setFilterFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [oriMajorComboList, setOriMajorComboList] = useState<FacultyBranch[]>([]);
    const [filterMajorComboList, setFilterMajorComboList] = useState<FacultyBranch[]>([]);
    const [selectedMajor, setSelectedMajor] = useState<string>("ALL");
    const [uploadName, setUploadName] = useState<string>("");
    const [schoolYear, setSchoolYear] = useState<string>("");
    const [selectedFaculty, setSelectedFaculty] = useState<string>("ALL");
    const [selectedFacultyBranch, setSelectedFacultyBranch] = useState<string>("ALL");
    const [selectedStatus, setSelectedStatus] = useState<string>("ALL");

    const [selectedDocumentType, setSelectedDocumentType] = useState<string>("ALL");
    const [documentTypeComboList, setDocumentTypeComboList] = useState<RequestDocumentType[]>([]);
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const [activeKey, setActiveKey] = useState<string | string[]>("0");

    const [isOpenDrawer, setIsOpentDrawer] = useState(props.isOpenDrawer);
    // const IsStudent = authen.sessionUser != null && authen.sessionUser.currentUser.main_role_name === 'Student';
    const [IsStudent, setIsStudent] = useState<boolean>(false);

    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])

    useEffect(() => {
        loadComboData();
    }, [])
    useEffect(() => {
        setIsStudent(!props.hasSearchPermission);
    }, [props.hasSearchPermission])
    useEffect(() => {
        setUserId(props.userId)
    }, [props.userId])
    useEffect(() => {
        setIsOpentDrawer(props.isOpenDrawer);
    }, [props.isOpenDrawer])

    const loadComboData = async () => {
        let facultyList = await getFaculty();
        // let facultyBranchList = await getFacultyBranch();
        let departmentList = await getDepartment();
        let majorList = await getMajor();
        let documentTypeList = await getRequestDocumentList();
        setFacultyComboList(facultyList);
        setOriFacultyBranchComboList(departmentList);
        setFilterFacultyBranchComboList([...departmentList]);
        setOriMajorComboList(majorList);
        setFilterMajorComboList([...majorList]);
        setDocumentTypeComboList(documentTypeList);
    }
    
    const onDocumentTypeChange = (value: string) => {
        setSelectedDocumentType(value);
    }

    const onFacultyChange = (value: string) => {
        console.log("onFacultyChange: ", value);
        setSelectedFaculty(value);
        setSelectedFacultyBranch("ALL");
        setSelectedMajor("ALL");
        if(value == "ALL"){
            setFilterFacultyBranchComboList([...oriFacultyBranchComboList]);
            setFilterMajorComboList([...oriMajorComboList]);
            return;
        }
        const facultyId: number = Number(value);
        let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
            return branch.faculty_id == facultyId || branch.id == -1;
        });
        setFilterFacultyBranchComboList(filterBranch);

        let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
            return branch.faculty_id == facultyId;
        });
        setFilterMajorComboList(filterMajor);
    }

    const onFacultyBranchChange = (value: string) => {
        console.log("onFacultyBranchChange: ", value);
        setSelectedFacultyBranch(value);
        setSelectedMajor("ALL");
        const facultyBranchId: number = Number(value);
        let currentFaculty = -1;
        if(selectedFaculty != "ALL"){
            currentFaculty = Number(selectedFaculty);
        }
        let facultyBranchItem: FacultyBranch | undefined = oriFacultyBranchComboList.find((value) => {return value.id == facultyBranchId});
        if(facultyBranchItem){
            const facultyBranchItemValue = facultyBranchItem;
            if(facultyBranchItemValue.faculty_id > 0){
                setSelectedFaculty("" + facultyBranchItemValue.faculty_id);
            }
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == facultyBranchItemValue.faculty_id || currentFaculty == -1|| branch.id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == facultyBranchItemValue.id && (currentFaculty == -1 || currentFaculty == branch.faculty_id);
            });
            setFilterMajorComboList(filterMajor);
        }
        // setFilterFacultyBranchComboList(filterBranch);
    }

    const onMajorChange = (value: string) => {
        console.log("onMajorChange: ", value);
        setSelectedMajor(value);
        const majorId: number = Number(value);
        let majorItem: FacultyBranch | undefined = oriMajorComboList.find((value) => {return value.id == majorId});
        if(majorItem){
            const majorItemValue = majorItem;
            setSelectedFaculty("" + majorItemValue.faculty_id);
            setSelectedFacultyBranch("" + majorItemValue.department_id);
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == majorItemValue.faculty_id || branch.id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == majorItemValue.department_id && branch.faculty_id == majorItemValue.faculty_id;
            });
            setFilterMajorComboList(filterMajor);
        }
    }
    
    const renderFacultyOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกคณะ</Option>);
        for(let i = 0; i < facultyComboList.length; i++){
            let fac = facultyComboList[i];
            optionList.push(<Option value={`${fac.id}`}>{fac.faculty_name}</Option>);
        }
        return optionList;
    }

    const renderFacultyBranchOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกภาควิชา</Option>);
        for(let i = 0; i < filterFacultyBranchComboList.length; i++){
            let branch = filterFacultyBranchComboList[i];
            optionList.push(<Option value={`${branch.id}`}>{branch.branch_name}</Option>);
        }
        return optionList;
    }
    const renderMajorOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกสาขา</Option>);
        for(let i = 0; i < filterMajorComboList.length; i++){
            let maj = filterMajorComboList[i];
            optionList.push(<Option value={`${maj.id}`}>{maj.branch_name}</Option>);
        }
        return optionList;
    }

    const renderRequestDocumentTypeOption = () => {
        let optionList = [];
        optionList.push(<Option value={`ALL`}>ทุกประเภทคำร้อง</Option>);
        for(let i = 0; i < documentTypeComboList.length; i++){
            let fac = documentTypeComboList[i];
            optionList.push(<Option value={`${fac.id}`}>{fac.name}</Option>);
        }
        return optionList;
    }

    const onSearch = () => {
        if(props.onSearch){
            let searchValue: any = {
                doc_type_id: selectedDocumentType == "ALL" ? null : selectedDocumentType,
                user_id: userId,
                upload_name: uploadName.trim() == "" ? null : uploadName.trim(),
                school_year: schoolYear.trim() == "" ? null : schoolYear.trim(),
                faculty_id: selectedFaculty == "ALL" ? null : selectedFaculty,
                branch_id: selectedFacultyBranch == "ALL" ? null : selectedFacultyBranch,
                major_id: selectedMajor == "ALL" ? null : selectedMajor,
                status: selectedStatus == "ALL" ? null : selectedStatus
            };
            props.onSearch(searchValue);
        }
        setActiveKey("0");
    }

    const onReset = () => {
        if(props.onSearch){
            setUploadName('');
            setSchoolYear('');
            setSelectedFaculty('ALL');
            setSelectedFacultyBranch('ALL');
            setSelectedStatus('ALL');
            setSelectedDocumentType('ALL');
            let searchValue: any = {
                doc_type_id: null,
                user_id: userId,
                upload_name: null,
                school_year: null,
                faculty_id: null,
                branch_id: null,
                major_id: null,
                status: null
            };
            props.onSearch(searchValue);
        }
    }

    const onCloseDrawer = () => {
        if(props.onCloseDrawer){
            props.onCloseDrawer();
        }
    }

    const collapseChange = (key: string | string[]) => {
        // console.log("collapseChange: ", key);
        setActiveKey(key);
    };

    const headerPanel1 = () => {
        return (
            <div style={{fontSize: "16", fontWeight: "bold"}}>
                {labelLang("ส่วนค้นหา", "Search Module", currentLang)}
            </div>
        )
    }

    return (
        <Collapse 
            activeKey={activeKey} 
            accordion 
            style={{marginBottom: 10, borderRadius: 5}} 
            onChange={collapseChange}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
            <Collapse.Panel header={headerPanel1()} key={1}>
                {/* <div className={styles['search-container']}> */}
                    <div className={styles['search-component']}>
                        <div className={styles['input-line']}>
                            <Select
                                showSearch
                                placeholder={labelLang("เลือกประเภทคำร้อง", "Choose Request", currentLang)}
                                optionFilterProp="children"
                                onChange={onDocumentTypeChange}
                                // onSearch={onSearch}
                                value={selectedDocumentType}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                                className={styles['select-full']}
                            >
                                {renderRequestDocumentTypeOption()}
                            </Select>
                        </div>
                    </div>
                    {            
                    IsStudent === false ?
                        <div className={styles['search-component']}>
                            <div className={styles['input-line']}>
                                <Input type={"text"} placeholder={labelLang("ชื่อผู้อัปโหลด", "Sender", currentLang)} value={uploadName} onChange={(e) => {setUploadName(e.target.value)}} />
                            </div>
                        </div> : null
                    }
                    <div className={styles['search-component']}>
                        <div className={styles['input-line']}>
                            <Input type={"text"} placeholder={labelLang("ปีการศึกษา", "Academic Year", currentLang)} value={schoolYear} onChange={(e) => {setSchoolYear(e.target.value)}} />
                        </div>
                    </div>
                    {
                    IsStudent === false ?
                        <div className={styles['search-component']}>
                            <div className={styles['input-line']}>
                                <Select
                                    showSearch
                                    placeholder="เลือกคณะ"
                                    optionFilterProp="children"
                                    onChange={onFacultyChange}
                                    // onSearch={onSearch}
                                    value={selectedFaculty}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                    className={styles['select-full']}
                                >
                                    {renderFacultyOption()}
                                </Select>
                            </div>
                        </div> : null
                    }
                    {
                    IsStudent === false ?
                        <div className={styles['search-component']}>
                            <div className={styles['input-line']}>
                                <Select
                                    showSearch
                                    placeholder="เลือกภาควิชา"
                                    optionFilterProp="children"
                                    className={styles['select-half']}
                                    value={selectedFacultyBranch}
                                    onChange={onFacultyBranchChange}
                                    style={{marginRight: 4}}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {renderFacultyBranchOption()}
                                </Select>
                                <Select
                                    showSearch
                                    placeholder="เลือกสาขาวิชา"
                                    optionFilterProp="children"
                                    className={styles['select-half']}
                                    value={selectedMajor}
                                    onChange={onMajorChange}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {renderMajorOption()}
                                </Select>
                            </div>
                        </div> : null
                    }
                    {
                    IsStudent === false ?
                        <div className={styles['search-component']}>
                            <div className={styles['input-line']}>
                                <Select
                                    showSearch
                                    placeholder="เลือกสถานะเอกสาร"
                                    optionFilterProp="children"
                                    value={selectedStatus}
                                    onChange={(value) => {setSelectedStatus(value)}}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                    className={styles['select-full']}
                                >
                                    <Option value="ALL">ทุกสถานะ</Option>
                                    <Option value="DRAFT">DRAFT</Option>
                                    <Option value="PENDING">PENDING</Option>
                                    <Option value="APPROVE">APPROVE</Option>
                                    <Option value="REJECT">REJECT</Option>
                                </Select>
                            </div>
                        </div> : null
                    }
                    <div className={styles['search-component-submit']}>
                        <Button type="primary" style={{marginRight: 5}} onClick={onSearch}>{labelLang("ค้นหา", "Search", currentLang)}</Button>
                        <Button type="default" onClick={onReset}>Reset</Button>
                    </div>
                {/* </div> */}
            </Collapse.Panel>
        </Collapse>
            
    )
}

export default RequestDocumentUploadListSearchSection;