import ThreeDModel from "../3dmodel/3DModel";
import Panotour from "../panotour/Panotour";
import React, {Component, useEffect, useRef, useState} from 'react'
import Pano from "../panotour/panolen";
import HomeVideo from "../panotour/HomeVideo";
import styles from "./Homepage.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { checkOvertime, isOvertime, saveCurrentInteractTime } from "../../../services/SessionService";

const Homepage = () => {
    const { id } = useParams<string>();
    const [compState, setCompState] = useState<number>(1);
    const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
    useEffect(() => {
        console.log("id: ", id);
        // if(id){
        //     setCompState(2);
        // }
        // processBeforeRender();
        setIsPageLoaded(true);
    }, [])
    useEffect(() => {
        if(isPageLoaded){
            processBeforeRender();
        }
    }, [isPageLoaded])
    const videoEnded = () => {
        setCompState(2);
    }
    const processBeforeRender = async () => {
        let isOver = isOvertime();
        console.log("isOver: ", isOver);
        if(!isOver){
            setCompState(2);
        }else{
            saveCurrentInteractTime();
        }
    }
    const renderPano = () => {
        return (
            <Pano isNoVid={id !== null && id !== undefined}></Pano>
        )
    }
    const renderVideo = () => {
        return (
            <HomeVideo onEndState={videoEnded}></HomeVideo>
        )
    }
    const renderComp = () => {
        if(compState == 1){
            return renderVideo();
        }else{
            return renderPano();
        }
    }
    return (
        <div className={styles['main-container']}>
            {renderComp()}
        </div>
    )
}

export default Homepage;