import { Button, Form, Input, notification, Select } from 'antd';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import { GoogleCircleFilled } from '@ant-design/icons';
import styles from './Profile.module.css'
import { useAppDispatch, useAppSelector } from '../../../store';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiResponse, Faculty, FacultyBranch } from '../../../models/MasterModel';
import { getDepartment, getFaculty, getFacultyBranch, getMajor } from '../../../services/MasterService';
import { register } from '../../../services/AuthenService';
import { setBack2Page, setPageName } from '../../../store/pagination/paginationSlice';
import { getUserById, updateUserProfile } from '../../../services/UserService';
import { SessionUserState } from '../../../store/authen/authenSlice';
import { LanguageState } from '../../../store/language/languageSlice';
import { labelLang } from '../../../helper/HTMLHelper';
import CustomLabel from '../../../components/CustomLabel';

interface ISignUp{
    login_id: number;
    student_code: string;
    email: string;
    password: string;
    confirm_password: string;
    firstname: string;
    lastname: string;
    firstname_en: string;
    lastname_en: string;
    faculty_id: string;
    branch_id: string;
    major_id: string;
    phone_no: string;

}
interface IUserData{
    id: number;
    student_code: string;
    email: string;
    firstname: string;
    lastname: string;
    firstname_en: string;
    lastname_en: string;
    faculty_id: string | undefined;
    branch_id: string | undefined;
    major_id: string | undefined;
    phone_no: string;
    main_role: string | undefined;
    sub_roles: string[] | undefined;
}
const Profile = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const authen: SessionUserState = useAppSelector(state => state.authen);
    const [form] = Form.useForm();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [navigatePage, setNavigatePage] = useState<string>();
    const [facultyComboList, setFacultyComboList] = useState<Faculty[]>([]);
    const [oriFacultyBranchComboList, setOriFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [filterFacultyBranchComboList, setFilterFacultyBranchComboList] = useState<FacultyBranch[]>([]);
    const [oriMajorComboList, setOriMajorComboList] = useState<FacultyBranch[]>([]);
    const [filterMajorComboList, setFilterMajorComboList] = useState<FacultyBranch[]>([]);
    const [selectedMajor, setSelectedMajor] = useState<string>("ALL");

    const { Option } = Select;
    useEffect(() => {
        dispatch(setPageName({th: "ข้อมูลส่วนตัว", en: "Profile"}));
        dispatch(setBack2Page(null));
        loadComboData();
        loadCurrentData();
        // setNavigatePage("/");
    }, []);

    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])

    useEffect(() => {
      if(navigatePage != null && navigatePage.trim() != ""){
        navigate(navigatePage, {replace: true});
      }
    },[navigatePage]);

    const loadComboData = async () => {
        let facultyList = await getFaculty();
        // let facultyBranchList = await getFacultyBranch();
        let departmentList = await getDepartment();
        let majorList = await getMajor();
        setFacultyComboList(facultyList);
        setOriFacultyBranchComboList(departmentList);
        setFilterFacultyBranchComboList([...departmentList]);
        setOriMajorComboList(majorList);
        setFilterMajorComboList([...majorList]);
    }

    const loadCurrentData = async () => {
        if(authen != null && authen.sessionUser != null){
            let resData = await getUserById(authen.sessionUser.currentUser.id);
            if(resData.status){
                let data: IUserData = resData.data;
                form.setFieldsValue({
                    student_code: data.student_code,
                    email: data.email,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    firstname_en: data.firstname_en,
                    lastname_en: data.lastname_en,
                    faculty_id: data.faculty_id == null ? "0" : "" + data.faculty_id,
                    branch_id: data.branch_id == null ? "0" : "" + data.branch_id,
                    major_id: data.major_id == null ? "0" : "" + data.major_id,
                    phone_no: data.phone_no
                });
            }
            console.log("loadCurrentData: ", resData);
        }
    }

    const onFinish = async (values: ISignUp) => {
        if(authen != null && authen.sessionUser != null){
            console.log('Success:', values);
            values.login_id = authen.sessionUser.currentUser.id;
            let resData: ApiResponse = await updateUserProfile(values);
            if(resData.status){
                notification.success({message: labelLang("แก้ไขข้อมูลสำเร็จ", "Update Success", currentLang), description: resData.message})
                // setNavigatePage("/frontend/login");
            }else{
                notification.error({message: labelLang("สมัครสมาชิกไม่สำเร็จ", "Update Fail", currentLang), description: resData.message})
            }
        }
    };
  
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    const onFacultyChange = (value: string) => {
        console.log("onFacultyChange: ", value);
        const facultyId: number = Number(value);
        let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
            return branch.faculty_id == facultyId || branch.faculty_id == -1;
        });
        form.setFieldsValue({branch_id: "" + filterBranch[0].id});
        setFilterFacultyBranchComboList(filterBranch);
        let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
            return branch.department_id == filterBranch[0].id && (facultyId == -1 || facultyId == branch.faculty_id);
        });
        setFilterMajorComboList(filterMajor);
        if(filterMajor.length > 0){
            form.setFieldsValue({major_id: "" + filterMajor[0].id});
        }
    }

    const onFacultyBranchChange = (value: string) => {
        console.log("onFacultyChange: ", value);
        const facultyBranchId: number = Number(value);
        let facultyBranchItem: FacultyBranch | undefined = oriFacultyBranchComboList.find((value) => {return value.id == facultyBranchId});
        let currentFaculty = -1;
        if(form.getFieldValue("faculty_id") != null){
            currentFaculty = Number(form.getFieldValue("faculty_id"));
        }
        if(facultyBranchItem){
            const facultyBranchItemValue = facultyBranchItem;
            if(facultyBranchItem.faculty_id > 0){
                form.setFieldsValue({faculty_id: "" + facultyBranchItem.faculty_id});
            }
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == facultyBranchItemValue.faculty_id || branch.faculty_id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == facultyBranchItemValue.id && (currentFaculty == -1 || currentFaculty == branch.faculty_id);
            });
            setFilterMajorComboList(filterMajor);
            if(filterMajor.length > 0){
                form.setFieldsValue({major_id: "" + filterMajor[0].id});
            }
        }
        // setFilterFacultyBranchComboList(filterBranch);
    }

    const onMajorChange = (value: string) => {
        // setSelectedMajor(value);
        const majorId: number = Number(value);
        let majorItem: FacultyBranch | undefined = oriMajorComboList.find((value) => {return value.id == majorId});
        if(majorItem){
            const majorItemValue = majorItem;
            form.setFieldsValue({faculty_id: "" + majorItemValue.faculty_id, branch_id: "" + majorItemValue.department_id});
            let filterBranch: FacultyBranch[] = oriFacultyBranchComboList.filter((branch) => {
                return branch.faculty_id == majorItemValue.faculty_id || branch.id == -1;
            });
            setFilterFacultyBranchComboList(filterBranch);

            let filterMajor: FacultyBranch[] = oriMajorComboList.filter((branch) => {
                return branch.department_id == majorItemValue.department_id && branch.faculty_id == majorItemValue.faculty_id;
            });
            setFilterMajorComboList(filterMajor);
        }
    }
    const renderFacultyOption = () => {
        let optionList = [];
        for(let i = 0; i < facultyComboList.length; i++){
            let fac = facultyComboList[i];
            optionList.push(<Option value={`${fac.id}`}>{fac.faculty_name}</Option>);
        }
        return optionList;
    }

    const renderFacultyBranchOption = () => {
        let optionList = [];
        for(let i = 0; i < filterFacultyBranchComboList.length; i++){
            let branch = filterFacultyBranchComboList[i];
            optionList.push(<Option value={`${branch.id}`}>{branch.branch_name}</Option>);
        }
        return optionList;
    }
    const renderMajorOption = () => {
        let optionList = [];
        // optionList.push(<Option value={`ALL`}>ทุกสาขา</Option>);
        for(let i = 0; i < filterMajorComboList.length; i++){
            let maj = filterMajorComboList[i];
            optionList.push(<Option value={`${maj.id}`}>{maj.branch_name}</Option>);
        }
        return optionList;
    }
    const formItemStyle = {marginBottom: 10};
    return (
        <div>
            <div className={styles['login-container']}>
                <div className={styles['component-box']}>
                    <div className={styles['title-bar']}><CustomLabel th={"ข้อมูลส่วนตัว"} en={"Profile"}/></div>
                    
                    <Form
                        form={form}
                        name="register"
                        // labelCol={{ span: 8 }}
                        // wrapperCol={{ span: 16 }}
                        style={{width: '80%'}}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="student_code"
                            rules={[{ required: true, message: labelLang("กรุณากรอกรหัสนักศึกษา!", "Student code is require", currentLang) }]}
                            style={formItemStyle}
                        >
                            <Input placeholder={labelLang("รหัสนักศึกษา*", "Student Code", currentLang)} type={"text"} />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: labelLang("กรุณากรอกอีเมล์!", "Email is require", currentLang) }
                                , {type: "email", message: labelLang("รูปแบบ Email ไม่ถูกต้อง", "Email format is wrong", currentLang)}]}
                            style={formItemStyle}
                        >
                            <Input placeholder="Email*" type={"text"} readOnly={true}/>
                        </Form.Item>
                        {/* <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'กรุณากรอกรหัสผ่าน!' }]}
                            style={formItemStyle}
                        >
                            <Input placeholder="Passowrd*" type={"password"} />
                        </Form.Item>
                        <Form.Item
                            name="confirm_password"
                            rules={[{ required: true, message: 'กรุณายืนยันรหัสผ่าน!' }]}
                            style={formItemStyle}
                        >
                            <Input placeholder="Confirm Passowrd*" type={"password"} />
                        </Form.Item> */}
                        <div className={styles['input-line']}>
                            <Form.Item
                                name="firstname"
                                rules={[{ required: true, message: labelLang("กรุณากรอกชื่อ!", "Firstname is require", currentLang)}]}
                                // className={styles['half']} 
                                style={{...formItemStyle, marginRight: 4, width: '100%'}}
                            >
                                <Input placeholder={labelLang("ชื่อ*", "Firstname(TH)*", currentLang)} type={"text"} />
                            </Form.Item>
                            <Form.Item
                                name="lastname"
                                rules={[{ required: true, message: labelLang("กรุณากรอกนามสกุล!", "Lastname is require", currentLang) }]}
                                style={{...formItemStyle, width: '100%'}}
                            >
                                <Input placeholder={labelLang("นามสกุล*", "Lastname(TH)*", currentLang)} type={"text"} />
                            </Form.Item>
                        </div>
                        <div className={styles['input-line']}>
                            <Form.Item
                                name="firstname_en"
                                rules={[{ required: true, message: labelLang("กรุณากรอกชื่อ!", "Firstname is require", currentLang)}]}
                                // className={styles['half']} 
                                style={{...formItemStyle, marginRight: 4, width: '100%'}}
                            >
                                <Input placeholder={labelLang("ชื่อ*", "Firstname(EN)*", currentLang)} type={"text"} />
                            </Form.Item>
                            <Form.Item
                                name="lastname_en"
                                rules={[{ required: true, message: labelLang("กรุณากรอกนามสกุล!", "Lastname is require", currentLang) }]}
                                style={{...formItemStyle, width: '100%'}}
                            >
                                <Input placeholder={labelLang("นามสกุล*", "Lastname(EN)*", currentLang)} type={"text"} />
                            </Form.Item>
                        </div>
                        <Form.Item
                            name="faculty_id"
                            rules={[{ required: true, message: labelLang("กรุณาเลือกคณะที่สังกัด!", "Faculty is require", currentLang) }]}
                            style={{...formItemStyle, width: '100%'}}
                        >
                            <Select
                                showSearch
                                placeholder={labelLang("เลือกคณะ*", "Select Faculty*", currentLang)}
                                optionFilterProp="children"
                                onChange={onFacultyChange}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                                className={styles['select-half']}
                                style={{marginRight: 4}}
                            >
                                {renderFacultyOption()}
                            </Select>
                        </Form.Item>
                        <div className={styles['input-line']} style={{marginBottom: 0}}>
                            <Form.Item
                                name="branch_id"
                                rules={[{ required: true, message: labelLang("กรุณาเลือกภาควิชาที่สังกัด!", "Major is require", currentLang) }]}
                                style={{...formItemStyle, marginRight: 4, width: '50%'}}
                            >
                                <Select
                                    showSearch
                                    placeholder={labelLang("เลือกภาควิชา*", "Select Major*", currentLang)}
                                    optionFilterProp="children"
                                    className={styles['select-half']}
                                    onChange={onFacultyBranchChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {renderFacultyBranchOption()}
                                </Select>
                            </Form.Item>
                            
                            <Form.Item
                                name="major_id"
                                rules={[{ required: true, message: labelLang("กรุณาเลือกสาขาวิชาที่สังกัด!", "Branch is require", currentLang) }]}
                                style={{...formItemStyle, width: '50%'}}
                            >
                                <Select
                                    showSearch
                                    placeholder={labelLang("เลือกสาขา*", "Select Branch*", currentLang)}
                                    optionFilterProp="children"
                                    className={styles['select-half']}
                                    onChange={onMajorChange}
                                    // onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                    }
                                >
                                    {renderMajorOption()}
                                </Select>
                            </Form.Item>
                        </div>
                        
                        <Form.Item
                            name="phone_no"
                            rules={[{ required: true, message: labelLang('กรุณากรอกเบอร์โทรศัพท์!', "Phone No. is require", currentLang) }]}
                            className={styles['half']}
                            style={{...formItemStyle}}
                        >
                            <Input placeholder={labelLang('เบอร์โทรศัพท์*', "Phone No.", currentLang)} type={"text"} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                        </Form.Item>
                        <div className={styles['button-container']}>
                            <Form.Item
                                name="submitBtn"
                                // rules={[{ required: true, message: 'Please input your username!' }]}
                                // className={styles['half']}
                                style={{...formItemStyle, }}
                            >
                                <Button type={"primary"} style={{marginRight: 5}} htmlType={"submit"}>{labelLang('แก้ไขข้อมูล', "Update Profile", currentLang)}</Button>
                            </Form.Item>
                            <Button type={"default"} style={{marginRight: 5}} onClick={() => {setNavigatePage("/frontend/login")}}>{labelLang('ยกเลิก', "Cancel", currentLang)}</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default Profile;