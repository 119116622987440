import { Input, Modal, Select, notification } from "antd";
import { useEffect, useState } from "react";
import styles from "../RequestDocumentUploadForm.module.css";
import { RequestDocumentData } from "../../../../../models/RequestDocumentModel";
import { changeRequestDocumentStatus } from "../../../../../services/RequestDocumentService";
import { LanguageState } from "../../../../../store/language/languageSlice";
import { useAppSelector } from "../../../../../store";
import { labelLang } from "../../../../../helper/HTMLHelper";

interface ChangeRequestDocumentStatusModalProps {
    previewVisible: boolean;
    userId: number;
    refId: any;
    onCancel: any;
    onOkSuccess: any;
    requestDocument: RequestDocumentData | undefined;
}
const ChangeRequestDocumentStatusModal = (props: ChangeRequestDocumentStatusModalProps) => {
    const [previewVisible, setPreviewVisible] = useState<boolean>();
    const [selectedStatus, setSelectedStatus] = useState<string | null>();
    const [attachMessage, setAttachMessage] = useState<string>("");
    const [userId, setUserId] = useState<number>(props.userId);
    const [refId, setRefId] = useState<any>(props.refId);

    const [requestDocument, setRequestDocument] = useState<RequestDocumentData | undefined>(props.requestDocument);

    const lang: LanguageState = useAppSelector(state => state.language);
    const [currentLang, setCurrentLang] = useState<"th" | "en">(lang.currentLang.lang);
    useEffect(() => {
        setCurrentLang(lang.currentLang.lang);
    }, [lang.currentLang])

    useEffect(() => {
        setPreviewVisible(props.previewVisible);
    }, [props.previewVisible])
    useEffect(() => {
        setUserId(props.userId);
    }, [props.userId])
    useEffect(() => {
        setRefId(props.refId);
    }, [props.refId])
    useEffect(() => {
        setRequestDocument(props.requestDocument);
    }, [props.requestDocument])

    const onCancel = () => {
        if(props.onCancel){
            props.onCancel();
        }
    }
    const onOk = async () => {
        if(!selectedStatus || selectedStatus.trim() == ""){
            notification.error({message: "กรุณาเลือกสถานะที่ต้องการเปลี่ยน"})
            return;
        }
        if(!attachMessage || attachMessage.trim() == ""){
            notification.error({message: "กรุณากรอกข้อความแนบ"})
            return;
        }

        let approveData = {
            request_document_id: refId,
            new_status: selectedStatus,
            process_user_id: userId,
            attach_message: attachMessage
        }
        let resData = await changeRequestDocumentStatus(approveData);
        if(resData.status){
            notification.success({message: "ส่งเอกสารสำเร็จ", description: "ส่งเอกสารถึงเจ้าหน้าที่เพื่อตรวจสอบแล้ว กรุณารอการตอบกลับ"});
            // setDataList([])
            // setDownloadFileList([])
            // loadScholar();
            if(props.onOkSuccess){
                props.onOkSuccess();
            }
        }
        setPreviewVisible(false)
    }

    const renderStatusOptions = () => {
        let statusOptionList: any[] = [];
        if(requestDocument && requestDocument.status != "DRAFT"){
            statusOptionList.push(<Select.Option value="DRAFT">DRAFT</Select.Option>)
        }
        if(requestDocument && requestDocument.status != "APPROVE"){
            statusOptionList.push(<Select.Option value="APPROVE">APPROVE</Select.Option>)
        }
        if(requestDocument && requestDocument.status != "REJECT"){
            statusOptionList.push(<Select.Option value="REJECT">REJECT</Select.Option>)
        }
        return statusOptionList;
    }
    return (
        <Modal
            visible={previewVisible}
            title={labelLang("ยืนยันการเปลี่ยนสถานะเอกสาร", "Confirm to change status", currentLang)}
            onCancel={onCancel}
            onOk={onOk}
        >
            <div className={styles['modal-main-container']}>
                <div className={styles['input-container']}>
                    <Select
                        showSearch
                        placeholder={labelLang("เลือกสถานะเอกสารที่ต้องการเปลี่ยน", "Choose new status", currentLang)}
                        optionFilterProp="children"
                        onChange={(value: any) => {setSelectedStatus(value)}}
                        value={selectedStatus}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                        }
                        className={styles['select-half']}
                        style={{marginRight: 4, width: "100%"}}
                    >
                        {renderStatusOptions()}
                    </Select>
                    {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                </div>
                <div className={styles['input-container']}>
                    <Input style={{width: 550, marginRight: 5}} className={styles['input-text']} placeholder={labelLang("ข้อความแนบ", "Message", currentLang)} value={attachMessage} onChange={(e) => {setAttachMessage(e.target.value)}}></Input>
                    {/* <DatePicker className={styles['input-text']} placeholder={"วันที่ถ่ายภาพ"} value={photoDate} format={"DD/MM/YYYY"}></DatePicker> */}
                </div>
            </div>
        </Modal>
    )
}

export default ChangeRequestDocumentStatusModal;