import React, { Children, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, useRoutes, RouteObject } from 'react-router-dom';

import SignUp from './views/signup/SignUp';
import Login from './views/login/Login';
import { Counter } from './views/counter/Counter';
// import BackentLayout from './views/backend/layout/BackentLayout';
import BackentLayout from './views/backend/layout/BackendLayout';
import Dashboard from './views/backend/dashboard/Dashboard';
import XtremeLayout from './views/backend/layout/XtremeLayout';
import FrontendLayout from './views/frontend/layout/FrontendLayout';
import Homepage from './views/frontend/homepage/Homepage';
import ThesisUploadList from './views/backend/thesis/list/ThesisUploadList';
import ThesisUploadForm from './views/backend/thesis/manage/ThesisUploadForm';
import ScholarshipList from './views/backend/scholarship/list/ScholarshipUploadList';
import ScholarshipUploadForm from './views/backend/scholarship/manage/ScholarshipUploadForm';
import ActivityLog from './views/backend/activitylog/list/ActivityLog';
import CreateReport from './views/backend/report/CreateReport';
import UserList from './views/backend/user/user/list/UserList';
import UserForm from './views/backend/user/user/manage/UserForm';
import RoleList from './views/backend/user/role/list/RoleList';
import RoleForm from './views/backend/user/role/manage/RoleForm';
import PermissionList from './views/backend/user/permission/list/PermissionList';
import PermissionForm from './views/backend/user/permission/manage/PermissionForm';
import { authenFromLocalStorage, logout } from './services/AuthenService';
import { useAppDispatch, useAppSelector } from './store';
import Profile from './views/backend/profile/Profile';
import { SessionUserState } from './store/authen/authenSlice';
import { checkLoginSessionOvertime } from './services/SessionService';
import RequestDocumentUploadList from './views/backend/requestdocument/list/RequestDocumentUploadList';
import RequestDocumentUploadForm from './views/backend/requestdocument/manage/RequestDocumentUploadForm';
import ChangePassword from './views/backend/profile/ChangePassword';
import SchoolYearSettingList from './views/backend/schoolyearsetting/list/SchoolYearSettingList';
import SchoolYearSettingManage from './views/backend/schoolyearsetting/manage/SchoolYearSettingManage';
import ThesisActivateSetting from './views/backend/activatesetting/thesisactivatesetting/ThesisActivateSetting';
import ScholarshipActivateSetting from './views/backend/activatesetting/scholarshipactivatesetting/ScholarshipActivateSetting';
function App() {
  // const loginRoute: RouteObject = {
  //   path: 'login',
  //   element: <Login />
  // }
  
  const authen: SessionUserState = useAppSelector(state => state.authen);
  const [authenState, setAuthenState] = useState<SessionUserState>(authen);
  const [checkLoginInterval, setCheckLoginInterval] = useState<any | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    setIsLoaded(true);
  }, [])
  useEffect(() => {
    if(!isLoaded){
      loadLoginFromStorage();
    }
  }, [isLoaded])
  useEffect(() => {
    setAuthenState(authen);
  }, [authen])
  useEffect(() => {
    if(checkLoginInterval){
      clearInterval(checkLoginInterval);
      setCheckLoginInterval(null)
    }
    if(authenState && authenState.sessionUser){
      // console.log("set interval");
      let newInterval = setInterval(() => {
        checkLogin(authenState);
      }, 10000);
      setCheckLoginInterval(newInterval);
    }
  }, [authenState])
  const dispatch = useAppDispatch();
  const loadLoginFromStorage = () => {
    authenFromLocalStorage(dispatch)
  }
  const checkLogin = (authen4Check: SessionUserState) => {
    if(authen4Check && authen4Check.sessionUser){
      // console.log("checkLogin: ", authen4Check);
      let isOver = checkLoginSessionOvertime(authen4Check.sessionUser.currentUser.id, authen4Check.sessionUser.currentUser.email);
      if(isOver){
        logout(authen4Check.sessionUser.currentUser.id, dispatch);
      }
    }
  }
  const homppageRoute: RouteObject = {
    path: '',
    element: <Homepage />
  }
  const homppageVideoRoute: RouteObject = {
    path: 'home',
    element: <Homepage />,
  }
  const homppageNoVideoRoute: RouteObject = {
    path: 'home/:id',
    element: <Homepage />,
  }
  
  const authened: RouteObject = {
    path: 'management',
    element: <XtremeLayout />,
    children: [
      {path: '', element: <ThesisUploadList />},
      // {path: 'dashboard', element: <Dashboard />},
      {
        path: 'thesis', 
        children: [
          {path: '', element: <ThesisUploadList />},
          {path: 'list', element: <ThesisUploadList />},
          {path: 'form/:id', element: <ThesisUploadForm />},
        ]
      },
      {
        path: 'scholarship', 
        children: [
          {path: '', element: <ScholarshipList />},
          {path: 'list', element: <ScholarshipList />},
          {path: 'form/:id', element: <ScholarshipUploadForm />},
        ]
      },
      {
        path: 'requestdocument', 
        children: [
          {path: '', element: <RequestDocumentUploadList />},
          {path: 'list', element: <RequestDocumentUploadList />},
          {path: 'form/:id', element: <RequestDocumentUploadForm />},
        ]
      },
      {
        path: 'setting', 
        children: [
          {
            path: 'thesis',
            element: <ThesisActivateSetting />,
          },
          {
            path: 'scholar',
            element: <ScholarshipActivateSetting />,
          },
        ]
      },
      {
        path: 'activitylog', 
        children: [
          {path: '', element: <ActivityLog />},
        ]
      },
      {
        path: 'report', 
        children: [
          {path: '', element: <CreateReport />},
        ]
      },
      {
        path: 'user', 
        children: [
          {path: '', element: <UserList />},
          {path: 'form/:id', element: <UserForm />},
        ]
      },
      {
        path: 'role', 
        children: [
          {path: '', element: <RoleList />},
          {path: 'form/:id', element: <RoleForm />},
        ]
      },
      {
        path: 'profile', 
        children: [
          {path: '', element: <Profile />},
          {path: 'changePassword', element: <ChangePassword />},
        ]
      },
      // {
      //   path: 'permission', 
      //   children: [
      //     {path: '', element: <PermissionList />},
      //     {path: 'form/:id', element: <PermissionForm />},
      //   ]
      // },
      
    ]
  }
  
  const frontend: RouteObject = {
    path: 'frontend',
    element: <FrontendLayout />,
    children: [
      {path: '', element: <Login />},
      {path: 'login', element: <Login />},
      {path: 'signup', element: <SignUp />},
      // {path: 'dashboard', element: <Dashboard />},
      // {path: 'upload', element: <UploadImages />},
      // {path: 'upload/:id', element: <UploadImages />},
      // {path: 'transactionList', element: <TransactionList />},
      // {path: 'map/:id', element: <ProcessedMap />},
      
    ]
  }
  
  const routing = useRoutes([homppageRoute, homppageVideoRoute, homppageNoVideoRoute, authened, frontend]);
  // const routing = useRoutes([homppageRoute]);
  return routing;
}

export default App;
