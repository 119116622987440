import axios from "axios";
import ApiConfig from "../constants/ApiConfig";
import { ApiResponse } from "../models/MasterModel";

export const updateActivateSetting = async (data: any) => {
    let url = `${ApiConfig.backendUrl}/activateSettingController/edit.php`
    
    let res = await axios.post(url, data, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}
export const getActivateSettingById = async (moduleCode: string) => {
    let url = `${ApiConfig.backendUrl}/activateSettingController/getById.php`
    
    let res = await axios.post(url, {module_code: moduleCode}, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Credentials': 'true'
        }
    });
    if(res.status === 200){
        // console.log("response data: ", res.data);
        let resData: ApiResponse = res.data;
        return resData;
    }else{
        // console.log("error code: ", res.status)
        let resData: ApiResponse = {status: false, message: res.statusText, data: null};
        return resData;
    }
}